import api from '@/api';
import { T } from '@/assets/locales';
import { MESSAGE_TYPE } from '@/assets/signalr/config';
import { selectSecretToken } from '@/store/auth';
import { sendEventsToGroup, sendOtaGroupMessages } from '@/utils/event-messaging';
import { toastSuccess, toastWarning } from '@/utils/toaster';
import { SchemeFormContext } from '@/web/@layouts/SchemeLayout';
import { SchemeForm } from '@/web/smarter-ai/scheme-management/schemes/@components/SchemeForm';
import { Box, Typography } from '@mui/material';
import { getTime } from 'date-fns';
import { unionBy } from 'lodash';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { SCHEME_PARENT_ITEMS } from '../../assets';
import { formatSettingsBeforeSubmit } from '../../utils/settings';
import { MainContext } from '@/web/@components/PageBreadcrumb/BreadcrumbContext';

export function UpdateScheme() {
  const { t } = useTranslation();
  const secretToken = useSelector(selectSecretToken);

  const { scheme, schemeId, deployedAiContainer, deployedTriggers, deployedFirmwares } =
    useContext(SchemeFormContext);

  const { setBreadcrumbTitle } = useContext(MainContext) || {};

  const [loading, setLoading] = useState(false);

  const deploySchemeAIContainer = async (containers, schemeId) => {
    try {
      let totalItems = 0;
      (containers || []).forEach(async (container) => {
        totalItems++;
        const { id } = container;
        const request = api.ac.v5.aicontainer
          .$aiContainerId(id)
          .scheme.$schemeId(schemeId)
          .$post({
            headers: {
              Authorization: secretToken,
            },
          });
        await request.process();
      });

      if (totalItems > 0) {
        sendEventsToGroup(schemeId, MESSAGE_TYPE.AI_CONTAINER_DEPLOYED);
        //sendOtaGroupMessages(groupId, MESSAGE_TYPE.AI_EVENT_UPDATED);
      }
    } catch (error) {
      toastWarning(`Failed to deploy AI Container.`);
    }
  };

  const deploySchemeFirmware = async (firmwares, schemeId) => {
    try {
      let totalItems = 0;
      (firmwares || []).forEach(async (firmware) => {
        totalItems++;
        const request = api.ac.v5.firmware
          .$firmwareId(firmware?.firmwareId)
          .scheme.$schemeId(schemeId)
          .$post({
            headers: {
              Authorization: secretToken,
            },
          });

        await request.process();
      });

      if (totalItems > 0) {
        sendEventsToGroup(schemeId, MESSAGE_TYPE.FIRMWARE_DEPLOYED);
        sendOtaGroupMessages(schemeId, MESSAGE_TYPE.FIRMWARE_DEPLOYED);
      }
    } catch (error) {
      toastWarning(`Failed to deploy firmware.`);
    }
  };

  const deploySchemeTriggers = async (triggers, schemeId) => {
    try {
      let totalItems = 0;
      (triggers || []).forEach(async (item) => {
        totalItems++;
        const request = api.ac.v5.trigger.composite
          .$triggerId(item?.id)
          .scheme.$schemeId(schemeId)
          .$post({
            headers: {
              Authorization: secretToken,
            },
          });
        await request.process();
      });
      if (totalItems > 0) {
        sendEventsToGroup(schemeId, MESSAGE_TYPE.AI_EVENT_UPDATED);
        //sendOtaGroupMessages(groupId, MESSAGE_TYPE.AI_EVENT_UPDATED)
      }
    } catch (error) {
      toastWarning(`Failed to deploy triggers`);
    }
  };

  const updateSchemeSettings = async (settings, schemeId) => {
    try {
      const groupSettings = {};
      groupSettings.groupId = schemeId;
      groupSettings.properties = Object.keys(settings).map((item) => ({
        key: item,
        value: settings[item],
      }));
      groupSettings.properties.push({ key: 'lastUpdatedTime', value: getTime(new Date()) });

      const postRequest = api.ac.endpoint.group.settings.$post({
        params: {
          secretToken,
          groupId: schemeId,
        },
        data: groupSettings,
      });
      await postRequest.process();

      if (postRequest.response.status === 200) {
        sendEventsToGroup(schemeId, MESSAGE_TYPE.GROUP_SETTINGS_UPDATE);
        sendOtaGroupMessages(schemeId, MESSAGE_TYPE.GROUP_SETTINGS_UPDATE);
      }
    } catch (e) {
      toastWarning('Could not update settings');
    }
  };

  const unDeployTriggers = async (selectedTriggers) => {
    try {
      let totalItems = 0;
      (selectedTriggers || []).forEach(async (item) => {
        totalItems++;
        const { id } = item;
        const request = api.ac.v5.trigger.composite
          .$triggerId(id)
          .scheme.$schemeId(schemeId + '')
          .$delete({
            headers: {
              Authorization: secretToken,
            },
          });
        await request.process();
      });
      if (totalItems > 0) {
        sendEventsToGroup(schemeId, MESSAGE_TYPE.AI_EVENT_UPDATED);
        //sendOtaGroupMessages(groupId, MESSAGE_TYPE.AI_EVENT_UPDATED)
      }
    } catch (error) {
      toastWarning(`Failed to undeploy triggers`);
    }
  };

  const unDeployContainer = async (unDeployableContainer) => {
    if (!unDeployableContainer) return;
    try {
      const request = api.ac.v5.aicontainer
        .$aiContainerId(unDeployableContainer.containerId)
        .scheme.$schemeId(schemeId)
        .$delete({
          headers: {
            Authorization: secretToken,
          },
        });

      await request.process();
      sendEventsToGroup(schemeId, MESSAGE_TYPE.AI_CONTAINER_UNDEPLOY);
    } catch (error) {
      toastWarning('Something went wrong.');
    }
  };

  const deploySchemeFirmwares = async (selectedFirmwares) => {
    try {
      let totalItems = 0;
      (selectedFirmwares || []).forEach(async (item) => {
        totalItems++;
        const request = api.ac.v5.firmware
          .$firmwareId(item?.firmwareId)
          .scheme.$schemeId(schemeId)
          .$delete({
            headers: {
              Authorization: secretToken,
            },
          });

        await request.process();
      });
      if (totalItems > 0) {
        sendEventsToGroup(schemeId, MESSAGE_TYPE.FIRMWARE_DEPLOYED);
        sendOtaGroupMessages(schemeId, MESSAGE_TYPE.FIRMWARE_DEPLOYED);
      }
    } catch (error) {
      toastWarning(`Failed to undeploy firmware`);
    }
  };

  const updateScheme = async (data) => {
    const firmwares = [];
    const aiContainers = [];
    const triggers = [];

    let selectedContainers = [];
    let selectedTriggers = [];
    let selectedFirmwares = [];

    await data.properties.forEach((obj) => {
      const itemKey = obj.item[0].value;
      if (itemKey === SCHEME_PARENT_ITEMS.FIRMWARES) {
        const mappedValue = obj.value;
        selectedFirmwares.push(mappedValue);
        const isDeployed = deployedFirmwares.find((i) => i.firmwareId === mappedValue.firmwareId);
        if (!isDeployed) {
          firmwares.push(mappedValue);
        }
      }
      if (itemKey === SCHEME_PARENT_ITEMS.AI_CONTAINERS) {
        const mappedValue = obj.value;
        selectedContainers.push(mappedValue);
        const isDeployed = deployedAiContainer?.containerId === mappedValue.id;
        if (!isDeployed) {
          aiContainers.push(mappedValue);
        }
      }
      if (itemKey === SCHEME_PARENT_ITEMS.TRIGGERS) {
        const mappedValue = obj.value;
        selectedTriggers.push(mappedValue);
        const isDeployed = deployedTriggers.find((i) => i.id === mappedValue.id);
        if (!isDeployed) {
          triggers.push(mappedValue);
        }
      }
    });

    const settings = await formatSettingsBeforeSubmit(data.properties);

    const unDeployableContainers = !selectedContainers.find(
      (item) => deployedAiContainer?.containerId === item.id
    )
      ? deployedAiContainer
      : null;

    const unDeployableTriggers = deployedTriggers.filter(
      (deployedTrigger) =>
        !selectedTriggers.some((selectedTrigger) => selectedTrigger.id === deployedTrigger.id)
    );

    const unDeployableFirmwares = deployedFirmwares.filter(
      (deployedFirmware) =>
        !selectedFirmwares.some(
          (selectedFirmware) => selectedFirmware.firmwareId === deployedFirmware?.firmwareId
        )
    );

    const { schemeName, schemeDescription, product } = data;

    try {
      setLoading(true);
      const req = api.ac.endpoint.group.update.$put({
        params: {
          secretToken,
          groupNewName: schemeName,
          groupDescription: schemeDescription,
          associatedType: 'PRODUCT',
          associatedTypeId: product?.productId,
          groupId: schemeId,
        },
      });

      await req.process();

      await updateSchemeSettings(settings, schemeId);

      await unDeployContainer(unDeployableContainers);
      await unDeployTriggers(unionBy(unDeployableTriggers, 'id'));
      await deploySchemeFirmwares(unionBy(unDeployableFirmwares, 'firmwareId'));

      await deploySchemeAIContainer(unionBy(aiContainers, 'id'), schemeId);
      await deploySchemeFirmware(unionBy(firmwares, 'firmwareId'), schemeId);
      await deploySchemeTriggers(unionBy(triggers, 'id'), schemeId);
      setBreadcrumbTitle(schemeName, (path) => path.endsWith(schemeId + ''));
      toastSuccess('Success', t(T['scheme.edit.success'], { schemeName }));
    } catch (err) {
      toastWarning('Error', t(T['scheme.retry']));
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box p={2.5} pb={14}>
      <Typography variant="body2" fontSize="1.12rem" fontWeight={'medium'}>
        {t(T['scheme.edit.title'])}
      </Typography>
      <SchemeForm scheme={scheme} onSubmitData={updateScheme} loading={loading} />
    </Box>
  );
}
