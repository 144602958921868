import { T } from '@/assets/locales';
import { formatTriggerType } from '@/utils/triggers';
import { ItemStatus } from '@/web/@components/ItemStatus';
import { TableCheckboxHeader } from '@/web/@components/TableCheckboxHeader';
import { TableCheckboxItem } from '@/web/@components/TableItemCheckbox';
import { TriggerNameColumn } from '../../@components/TriggerNameColumn';
import { Box } from '@mui/material';

/** @type {Array<TableColumn<CompositeTriggerDto>>} */
export const TRIGGER_TABLE_COLUMNS = [
  {
    id: 'id',
    label: '',
    align: 'left',
    isPrimary: true,
    headerFormat: (value, rows) => <TableCheckboxHeader rows={rows} itemKey={'id'} />,
    format: (value, row) => <TableCheckboxItem row={row} disabled={false} />,
  },
  {
    id: 'name',
    label: T['triggers.table.columns.name'],
    align: 'left',
    isPrimary: true,
    format: (value, row) => (
      <Box height="35px" pt="6px">
        <TriggerNameColumn row={row} />
      </Box>
    ),
  },
  {
    id: 'type',
    label: T['triggers.table.columns.type'],
    align: 'left',
    format: (value) => formatTriggerType(value),
  },
  {
    id: 'publicationStatus',
    label: T['triggers.table.columns.publication.status'],
    align: 'left',
    format: (value) => <ItemStatus status={value} />,
  },
];
