import { ItemsListView } from '@/web/@components/ItemsListView';
import { ItemsTableView } from '@/web/@components/ItemsTableView';
import { Box, useMediaQuery, useTheme } from '@mui/material';

/**
 * @template T
 * @typedef {object} ItemsResponsiveViewProps<T>
 * @property {Array<T>} [results]
 * @property {string} [defaultSortKey]
 * @property {Array<TableColumn<T>>} [columns]
 * @property {import('@mui/material').SxProps} [sx]
 */

/**
 * @template T
 * @param {ItemsResponsiveViewProps<T>} props
 */
export function ItemsResponsiveView(props) {
  const { results, columns, defaultSortKey, sx } = props;
  const theme = useTheme();
  const mdUp = useMediaQuery(theme.breakpoints.up('md'));
  return (
    <Box>
      {mdUp ? (
        <ItemsTableView sx={sx} data={results} columns={columns} defaultSortKey={defaultSortKey} />
      ) : (
        <ItemsListView data={results} columns={columns} />
      )}
    </Box>
  );
}
