import {
  AI_ACCELERATOR_ON_ICON,
  ASSIGN_CHILD_ICON,
  BOX_CREATE_ICON,
  BOX_LIST_ICON,
  BOX_MANAGE_ICON,
  DEVICE_BATCH_ICON,
  FIRMWARES_ICON,
  LABEL_ICON,
  ONBOARD_LOGS_ICON,
  PRODUCTS_ICON,
  SCHEME_ICON_ON,
  TRIGGER_ICON,
} from '@/assets/constants/images';
import { T } from '@/assets/locales';
import { MONITORING_ITEMS } from './monitoring';
import { EDGE_TENSOR_REGEX } from '@/utils/user-access';

/** @type {Array<PageCardItem>} */
export const SMARTER_AI_ITEMS = [
  {
    name: T['ai.accelerator.page.card.name'],
    image: AI_ACCELERATOR_ON_ICON,
    color: '#E7EFF4',
    type: 'AI_ACCELERATORS',
    link: '/smarter-ai/ai-accelerator',
    components: [
      {
        name: T['ai.accelerator.page.card.browse.items'],
        image: BOX_LIST_ICON,
        link: '/smarter-ai/ai-accelerator',
      },
      {
        name: T['ai.accelerator.page.card.create.new'],
        image: BOX_CREATE_ICON,
        link: '/smarter-ai/ai-accelerator/create',
      },
    ],
  },
  {
    name: T['firmwares.page.card.name'],
    image: FIRMWARES_ICON,
    color: '#EFF3F7',
    type: 'DEVICE_FIRMWARE',
    link: '/smarter-ai/firmwares',
    components: [
      {
        name: T['firmwares.page.card.browse.items'],
        image: BOX_LIST_ICON,
        link: '/smarter-ai/firmwares',
      },
      {
        name: T['page.card.tenant.deployment'],
        image: ASSIGN_CHILD_ICON,
        link: '/smarter-ai/firmwares/tenant-deployment',
      },
    ],
  },
  {
    name: T['triggers.page.card.name'],
    image: TRIGGER_ICON,
    color: '#F4F7EF',
    type: 'AI_TRIGGER',
    link: '/smarter-ai/triggers',
    accessLevel: 'HIDDEN',
    emailAccess: [EDGE_TENSOR_REGEX],
    teamAccess: ['AI_TEAM', 'FRONT_END_TEAM'],
    components: [
      {
        name: T['triggers.page.card.browseItems'],
        image: BOX_LIST_ICON,
        link: '/smarter-ai/triggers',
      },
      {
        name: T['triggers.page.card.createNew'],
        image: BOX_CREATE_ICON,
        link: '/smarter-ai/triggers/create',
      },
    ],
  },
  {
    name: T['device-batches.page.card.name'],
    image: DEVICE_BATCH_ICON,
    color: '#F7F5EF',
    type: 'DEVICE_BATCH',
    link: '/smarter-ai/device-batches',
    components: [
      {
        name: T['device-batches.page.card.browse.items'],
        image: BOX_LIST_ICON,
        link: '/smarter-ai/device-batches',
      },
      {
        name: T['device-batches.page.card.create.new'],
        image: BOX_CREATE_ICON,
        link: '/smarter-ai/device-batches/create',
      },
    ],
  },
  {
    name: T['products.page.card.name'],
    image: PRODUCTS_ICON,
    color: '#F4F7EF',
    type: 'PRODUCTS',
    link: '/smarter-ai/products',
    components: [
      {
        name: T['products.page.card.manage.hardware.products'],
        image: BOX_MANAGE_ICON,
        link: '/smarter-ai/products',
      },
      {
        name: T['products.page.card.create.product'],
        image: BOX_CREATE_ICON,
        link: '/smarter-ai/products/create',
      },
      // {
      //   title: T['products.page.card.manage-accelerator'],
      //   iconImage: BOX_MANAGE_SETTING_ICON,
      //   link: '',
      // },
    ],
  },
  {
    name: T['labels.page.card.name'],
    image: LABEL_ICON,
    color: '#EDF2F2',
    type: 'AI_LABEL',
    link: '/smarter-ai/labels',
    accessLevel: 'SMARTER_AI_SUPER_ADMIN',
    components: [
      {
        name: T['labels.page.card.browseItems'],
        image: BOX_LIST_ICON,
        link: '/smarter-ai/labels',
      },
      {
        name: T['labels.page.card.createNew'],
        image: BOX_CREATE_ICON,
        link: '/smarter-ai/labels/create',
      },
    ],
  },
  {
    name: T['onboard.page.card.name'],
    image: ONBOARD_LOGS_ICON,
    color: '#EFF3F7',
    countHide: true,
    link: '/smarter-ai/onboard-logs',
    components: [
      {
        name: T['onboard.page.card.browse.items'],
        image: BOX_LIST_ICON,
        link: '/smarter-ai/onboard-logs',
      },
    ],
  },
  {
    name: T['scheme.groups.page.card.name'],
    image: SCHEME_ICON_ON,
    color: '#EFF3F7',
    // type: 'SCHEME',
    countHide: true,
    link: '/smarter-ai/scheme-management/schemes',
    // accessLevel: 'HIDDEN',
    accessLevel: 'SMARTER_AI_ADMIN',
    components: [
      {
        name: T['scheme.groups.page.card.browseItems'],
        image: BOX_LIST_ICON,
        link: '/smarter-ai/scheme-management/schemes',
      },
      {
        name: T['scheme.groups.page.card.createNew'],
        image: BOX_CREATE_ICON,
        link: '/smarter-ai/scheme-management/schemes/create',
      },
      {
        name: T['page.card.tenant.deployment'],
        image: ASSIGN_CHILD_ICON,
        link: '/smarter-ai/scheme-management/tenant-deployment',
      },
    ],
  },
  ...MONITORING_ITEMS,
];
