import { Box, Popover, Typography } from '@mui/material';
import MiniLineChart from '../MiniLineChart';
import { SensitivityMiniRangeBar } from '../SensitivityMiniRangeBar';
import { useState } from 'react';

export function TableSensitivityChart({ category, trigger, isHovered }) {
  const [anchorEl, setAnchorEl] = useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const items = category.includes('Tailgating')
    ? trigger?.variables.sort((a, b) => (a.key > b.key ? 1 : -1))
    : trigger?.variables?.slice(0, 1);

  const defaultItems = category.includes('Tailgating')
    ? trigger?.defaultValues?.variables.sort((a, b) => (a.key > b.key ? 1 : -1))
    : trigger?.defaultValues?.variables?.slice(0, 1);

  const lineItem = items?.at(0);

  const defaultLineItem = defaultItems?.at(0);

  return (
    <Box
      aria-owns={open ? 'mouse-over-popover' : undefined}
      aria-haspopup="true"
      minWidth="100px"
      width="100px"
      margin="auto"
      onMouseEnter={handlePopoverOpen}
      onMouseLeave={handlePopoverClose}
    >
      {category.includes('Tailgating') ? (
        <MiniLineChart
          data={items?.map((vari) => Number(vari?.value)) || []}
          defaultData={defaultItems?.map((vari) => Number(vari?.default)) || []}
          min={0}
          max={2}
        />
      ) : lineItem ? (
        <SensitivityMiniRangeBar
          value={Number(lineItem?.value) || 0}
          defaultValue={Number(defaultLineItem?.default) || 0}
          unit={lineItem?.unit}
          min={lineItem?.minValue}
          max={lineItem?.maxValue}
          isHovered={isHovered}
        />
      ) : (
        <Typography fontSize="0.75rem">N/A</Typography>
      )}

      <Popover
        open={Boolean(open && items?.length)}
        id="mouse-over-popover"
        sx={{
          pointerEvents: 'none',
        }}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Box m={1} display="grid">
          <Typography variant="caption" sx={{ fontWeight: 600 }}>
            Default
          </Typography>
          {category.includes('Tailgating') ? (
            <MiniLineChart
              data={defaultItems?.map((vari) => Number(vari?.default)) || []}
              defaultData={defaultItems?.map((vari) => Number(vari?.default)) || []}
              min={0}
              max={2}
              chartWidth={400}
              chartHeight={60}
              viewHeight={40}
            />
          ) : (
            defaultItems?.map((i, index) => (
              <Typography variant="caption" key={index}>
                {i.key} : {i?.default} {i.unit}
              </Typography>
            ))
          )}
        </Box>
      </Popover>
    </Box>
  );
}
