import { FIRMWARE_TYPES } from '@/assets/ota';
import { FROM_TYPES, GROUP_SETTINGS_CATEGORIES } from '@/assets/schemes/constants';
import {
  VIDEO_RECORDING_DEFAULT_RESOLUTIONS,
  audioBitrate,
  audioCodecs,
  frameRateOptions,
  recordingCodecs,
} from '@/assets/schemes/options';

export const typeMapping = {
  switch: FROM_TYPES.SELECT,
  slider: FROM_TYPES.NUMBER,
  number: FROM_TYPES.NUMBER,
  input: FROM_TYPES.INPUT,
  time: FROM_TYPES.DATE_TIME,
};

/** @typedef {{label: string, value: boolean}} BooleanOption */
export const BOOLEAN_OPTIONS = [
  { label: 'True', value: true },
  { label: 'False', value: false },
];

/** @typedef {{label: string, value: string}} BOOLEAN_ON_OFF */
export const BOOLEAN_ON_OFF = [
  { label: 'On', value: 'ON' },
  { label: 'Off', value: 'OFF' },
];

/** @typedef {{label: string, value: string}} PARKING_MODE_ON_OFF */
export const PARKING_MODE_ON_OFF = [
  { label: 'Hot', value: 'ON' },
  { label: 'Cool', value: 'OFF' },
];

/** @typedef {{ AI_CONTAINERS: string, FIRMWARES: string, SETTINGS: string, TRIGGERS: string, APN: string }} SchemeParentItems */
export const SCHEME_PARENT_ITEMS = {
  AI_CONTAINERS: 'AI_CONTAINERS',
  FIRMWARES: 'FIRMWARES',
  SETTINGS: 'SETTINGS',
  TRIGGERS: 'TRIGGERS',
  APN: 'APN',
};

/** @typedef {{ label: string, value: string }} FirstLevelItem */
export const FIRST_LEVEL_ITEMS = [
  // { label: 'APN', value: 'APN' },
  { label: 'AIContainers', value: SCHEME_PARENT_ITEMS.AI_CONTAINERS },
  { label: 'Firmwares', value: SCHEME_PARENT_ITEMS.FIRMWARES },
  { label: 'Settings', value: SCHEME_PARENT_ITEMS.SETTINGS },
  { label: 'Triggers', value: SCHEME_PARENT_ITEMS.TRIGGERS },
];

/** @typedef {{ label: string, value: string }} SCHEME_FIRMWARE_CATEGORIES */
export const SCHEME_FIRMWARE_CATEGORIES = [
  {
    label: 'CANBus',
    value: FIRMWARE_TYPES.CAN_BUS_FW,
  },
  {
    label: 'Camera',
    value: FIRMWARE_TYPES.APPLICATION_PACKAGE,
  },
  {
    label: 'LTE',
    value: FIRMWARE_TYPES.LTE_MODEM_FW,
  },
  {
    label: 'System',
    value: FIRMWARE_TYPES.SYSTEM_IMAGE,
  },
  {
    label: 'OEM',
    value: FIRMWARE_TYPES.OEM_IMAGE,
  },
  {
    label: 'SmartCable',
    value: FIRMWARE_TYPES.SMART_CABLE_FW,
  },
];

/** @typedef {{ label: string, value: string }} SCHEME_SETTINGS_SUBCATEGORIES */
export const SCHEME_SETTINGS_SUBCATEGORIES = [
  {
    label: 'Recording',
    value: GROUP_SETTINGS_CATEGORIES.RECORDING,
  },
  {
    label: 'Streaming',
    value: GROUP_SETTINGS_CATEGORIES.STREAMING,
  },
  {
    label: 'Onboarding',
    value: GROUP_SETTINGS_CATEGORIES.ONBOARDING,
  },
  {
    label: 'Firmware',
    value: GROUP_SETTINGS_CATEGORIES.REBOOT,
  },
  {
    label: 'Device',
    value: GROUP_SETTINGS_CATEGORIES.DEVICE,
  },
  {
    label: 'Power',
    value: GROUP_SETTINGS_CATEGORIES.POWER,
  },
  {
    label: 'Vision',
    value: GROUP_SETTINGS_CATEGORIES.VISION,
  },
  {
    label: 'Profiling',
    value: GROUP_SETTINGS_CATEGORIES.PROFILING,
  },
  {
    label: 'Sensor',
    value: GROUP_SETTINGS_CATEGORIES.SENSOR,
  },
  {
    label: 'Privacy',
    value: GROUP_SETTINGS_CATEGORIES.PRIVACY,
  },
  {
    label: 'Connectivity',
    value: GROUP_SETTINGS_CATEGORIES.CONNECTIVITY,
  },
  {
    label: 'Driver',
    value: GROUP_SETTINGS_CATEGORIES.DRIVER,
  },
  {
    label: 'Traffic',
    value: GROUP_SETTINGS_CATEGORIES.TRAFFIC,
  },
  {
    label: 'FaceLogin',
    value: GROUP_SETTINGS_CATEGORIES.FACE_LOGIN,
  },
];

/** @type {Array} */
export const SCHEME_PROPERTY_FIELDS = [
  {
    name: 'item',
    type: FROM_TYPES.MULTISELECT,
    mandatory: true,
    options: [],
    multiple: true,
  },
  {
    name: 'value',
    type: FROM_TYPES.SELECT,
    mandatory: true,
    options: [],
  },
];

export const IMAGER_STATE_SETTINGS = { label: 'Enable', key: 'isEnable', type: FROM_TYPES.SWITCH };

/**
 * Configuration for audio pipeline settings.
 * @type {Array<{
 *   label: string,
 *   key: string,
 *   type: string,
 *   options: Array|any,
 *   postText?: string
 * }>}
 */
export const AUDIO_PIPELINE_SETTINGS = [
  {
    label: 'EnableAudioPipeline',
    key: `isEnable`,
    type: FROM_TYPES.SWITCH,
    options: BOOLEAN_OPTIONS,
  },
  {
    label: 'AudioCodec',
    key: `codec`,
    type: FROM_TYPES.SELECT,
    options: audioCodecs,
  },
  {
    label: 'BitRate',
    key: `bitRate`,
    type: FROM_TYPES.SELECT,
    options: audioBitrate,
    postText: 'kbps',
  },
];

/**
 * Typedef for a video pipeline setting.
 * @typedef {{
 *   label: string,
 *   key: string,
 *   type: string,
 *   options: Array|any,
 *   postText?: string,
 *   min?: number,
 *   max?: number,
 *   inputType?: string
 * }} VideoPipelineSetting
 */
export const VIDEO_PIPELINE_SETTINGS = [
  {
    label: 'EnableRecordingPipeline',
    key: `isEnable`,
    type: FROM_TYPES.SWITCH,
    options: BOOLEAN_OPTIONS,
  },
  {
    label: 'OverrideConfigurationFromDeployedAIModels',
    key: `overrideConfigFromAiModel`,
    type: FROM_TYPES.SWITCH,
    options: BOOLEAN_OPTIONS,
  },
  {
    label: 'Resolution',
    key: `resolution`,
    type: FROM_TYPES.SELECT,
    options: VIDEO_RECORDING_DEFAULT_RESOLUTIONS,
  },
  {
    label: 'VideoCodec',
    key: `codec`,
    type: FROM_TYPES.SELECT,
    options: recordingCodecs,
  },
  {
    label: 'AdaptiveFrameRate',
    key: `isAdaptableFrameRate`,
    type: FROM_TYPES.SWITCH,
    options: BOOLEAN_OPTIONS,
  },
  {
    label: 'AdaptiveBitRate',
    key: `isAdaptableBitRate`,
    type: FROM_TYPES.SWITCH,
    options: BOOLEAN_OPTIONS,
  },

  {
    label: 'FrameRate',
    key: `fps`,
    type: FROM_TYPES.SELECT,
    options: frameRateOptions,
    postText: 'fps',
  },
  {
    label: 'MinimumFrameRate',
    key: `fpsMin`,
    type: FROM_TYPES.SELECT,
    options: frameRateOptions,
    postText: 'fps',
  },
  {
    label: 'MaximumFrameRate',
    key: `fpsMax`,
    postText: 'fps',
    type: FROM_TYPES.SELECT,
    options: frameRateOptions,
  },
  {
    label: 'BitRate',
    key: `bitRate`,
    type: FROM_TYPES.INPUT,
    postText: 'kbps',
    min: 100,
    max: 100000,
    inputType: FROM_TYPES.NUMBER,
  },
  {
    label: 'MinimumBitRate',
    key: `bitRateMin`,
    type: FROM_TYPES.INPUT,
    postText: 'kbps',
    min: 100,
    max: 100000,
    inputType: FROM_TYPES.NUMBER,
  },
  {
    label: 'MaximumBitRate',
    key: `bitRateMax`,
    type: FROM_TYPES.INPUT,
    postText: 'kbps',
    min: 100,
    max: 100000,
    inputType: FROM_TYPES.NUMBER,
  },
];
