import { isDev } from '@/config';

// TODO: temporary
export const SMARTER_AI_REGEX = /^.*@anyconnect.com$/i;

// TODO: temporary
export const EDGE_TENSOR_REGEX = /^.*@edgetensor.com$/i;

// TODO: temporary
export const FACE_REGISTRATION_TENANTS = [
  '1', // Smarter AI Development
  '87f0567b-43ab-4d11-a57b-ee150926ed85',
];

// TODO: temporary
export const DRAFT_TRIGGER_DEPLOYMENT_TENANTS = [
  '1', // Smarter AI Development
  '98ed2e00-2390-4786-a49d-6e9d2d72d3e2', //Smarter AI Testbed
  '9d6d8360-d70b-454d-86f8-d93939bb78c7', //Smarter AI Staging (CA)
  'd52a27e7-58e1-4381-8c99-abeb85155d5c', //Smarter AI Staging
];

/**
 * @typedef {keyof USER_LEVELS} UserLevel
 */
export const USER_LEVELS = {
  USER: 0,
  ADMIN: 1,
  SUPPORT: 2,
  SUPER_ADMIN: 2,
  SMARTER_AI_ADMIN: 3,
  SMARTER_AI_SUPER_ADMIN: 4,
  HIDDEN: 99,
};

/**
 * @param {string?} userRole
 * @param {string} email
 * @returns {number}
 */
export function mapUserRoleToUserLevel(userRole, email) {
  if (!userRole) {
    return USER_LEVELS.SUPER_ADMIN;
  }
  switch (userRole.toUpperCase().replace(/[^A-Z0-9]/, '')) {
    case 'USER':
    case 'ENDUSER':
      return USER_LEVELS.USER;
    case 'ADMIN':
    case 'SUPPORT':
      return USER_LEVELS.ADMIN;
    case 'SUPERADMIN':
      return SMARTER_AI_REGEX.test(email) ? USER_LEVELS.SMARTER_AI_ADMIN : USER_LEVELS.SUPER_ADMIN;
    case 'SMARTER_AI_ADMIN':
      return USER_LEVELS.SMARTER_AI_ADMIN;
    case 'SMARTER_AI_SUPER_ADMIN':
      return USER_LEVELS.SMARTER_AI_SUPER_ADMIN;
    default:
      return USER_LEVELS[userRole] || Number(userRole) || USER_LEVELS.SUPER_ADMIN;
  }
}

/**
 * @param {Array<string>} tenantAccess
 * @param {string} tenantId
 * @returns {boolean}
 */
export function filterByTenantAccess(tenantAccess, tenantId) {
  for (const access of tenantAccess || []) {
    if (access === tenantId) return true;
  }
  return false;
}

/**
 * @param {Array<RegExp>} emailAccess
 * @param {string} email
 * @returns {boolean}
 */
export function filterByEmailAccess(emailAccess, email) {
  for (const access of emailAccess || []) {
    if (access instanceof RegExp && access.test(email)) return true;
  }
  return false;
}

/**
 * @param {AccountLoginResponse['teamList']} teamAccess
 * @param {AccountLoginResponse['teamList']} teamList
 * @returns {boolean}
 */
export function filterByTeamAccess(teamAccess, teamList) {
  for (const access of teamAccess || []) {
    if (teamList?.includes(access)) return true;
  }
  return false;
}

/**
 * @param {number | UserLevel} accessLevel
 * @param {number} userLevel
 * @returns {boolean}
 */
export function filterByAccessLevel(accessLevel, userLevel) {
  return (USER_LEVELS[accessLevel] || Number(accessLevel) || 0) <= userLevel;
}

/**
 * @param {{userLevel?: number, userEmail?: string, userRole?: string, tenantId?: string, teamList: AccountLoginResponse['teamList']}} user
 * @returns {(item: {accessLevel?: number | UserLevel, emailAccess?: Array<RegExp>, tenantAccess?: Array<string>, teamAccess?: AccountLoginResponse['teamList']}) => boolean}
 */
export const filterByUserAccess = (user) => (item) => {
  if (!item) return false;
  if (isDev) return true;
  let { userLevel, userRole, userEmail, tenantId, teamList } = user || {};
  if (!userLevel) userLevel = mapUserRoleToUserLevel(userRole, userEmail);
  if (tenantId && filterByTenantAccess(item.tenantAccess, tenantId)) return true;
  if (userEmail && filterByEmailAccess(item.emailAccess, userEmail)) return true;
  if (teamList && filterByTeamAccess(item.teamAccess, teamList)) return true;
  return filterByAccessLevel(item.accessLevel, userLevel);
};
