import { useVisibleRoutes } from '@/hooks/useVisibleRoutes';
import { PageLink } from '@/web/@components/PageLink';
import { NavigateNext as NavigateNextIcon } from '@mui/icons-material';
import { Breadcrumbs, Typography } from '@mui/material';
import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { MainContext } from './BreadcrumbContext';
import { getBreadcrumbs } from './utils';

/**
 * This item requires BreadcrumbContextProvider to be available in the parent scope.
 * @param {import('@mui/material').BreadcrumbsProps} props
 */
export function PageBreadcrumb(props) {
  const { ...otherProps } = props;

  const location = useLocation();
  const routes = useVisibleRoutes();
  const { t } = useTranslation();
  const { breadcrumbs, setBreadcrumbs } = useContext(MainContext) || {};

  useEffect(() => {
    if (!setBreadcrumbs) return;
    setBreadcrumbs(getBreadcrumbs(routes, location.pathname));
  }, [location.pathname, setBreadcrumbs, routes]);

  if (!breadcrumbs) return null;

  return (
    <Breadcrumbs
      separator={<NavigateNextIcon fontSize="small" />}
      {...otherProps}
      sx={{
        '& .MuiBreadcrumbs-separator': {
          margin: 0,
        },
        '& .MuiBreadcrumbs-li, & .MuiTypography-root': {
          fontSize: breadcrumbs.length === 1 ? '1.125rem' : '0.875rem',
          fontWeight: 'medium',
        },
      }}
    >
      {breadcrumbs.map(({ name, pathname, active }) =>
        active ? (
          <Typography key={pathname} variant="subtitle2">
            {t(name)}
          </Typography>
        ) : (
          <PageLink key={pathname} to={pathname} state={{ breadcrumb: true }}>
            {t(name)}
          </PageLink>
        )
      )}
    </Breadcrumbs>
  );
}
