import {
  BOX_LIST_ICON,
  COACHING_ICON_ON,
  REVIEW_ICON_ON,
  SCORING_ICON_ON,
} from '@/assets/constants/images';
import { T } from '@/assets/locales';

/** @type {Array<PageCardItem>} */
export const SAFETY_PAGE_ITEMS = [
  {
    name: T['scoring.page.title'],
    image: SCORING_ICON_ON,
    color: '#F0EDF2',
    countHide: true,
    link: '/safety/scoring',
    accessLevel: 'USER',
    components: [
      {
        name: T['scoring.page.card.browseItems'],
        image: BOX_LIST_ICON,
        link: '/safety/scoring',
      },
    ],
  },
  {
    name: T['review.page.title'],
    image: REVIEW_ICON_ON,
    color: '#EDF2F2',
    countHide: true,
    link: '/safety/reviews',
    accessLevel: 'HIDDEN',
    components: [
      {
        name: T['review.page.card.browseItems'],
        image: BOX_LIST_ICON,
        link: '/safety/reviews',
      },
    ],
  },
  {
    name: T['coaching.page.title'],
    image: COACHING_ICON_ON,
    color: '#EFE7E7',
    countHide: true,
    link: '/safety/coaching',
    components: [
      {
        name: T['coaching.page.card.browseItems'],
        image: BOX_LIST_ICON,
        link: '/safety/coaching',
      },
    ],
  },
];
