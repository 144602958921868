import api from '@/api';
import { CAMERA_COMMON_ICON, CAMERA_COMMON_ICON_OFF } from '@/assets/constants/images';
import { MESSAGE_TYPE } from '@/assets/signalr/config';
import { isProduction } from '@/config';
import { store } from '@/store';
import { selectCurrentTenantName, selectSecretToken, selectTenantId } from '@/store/auth';
import { selectSessionId } from '@/store/hub-connection';
import { formatCameraName, isCameraInParkingMode } from '@/utils/cameras';
import { formatDateTime } from '@/utils/datetime';
import { toastInfo, toastWarning } from '@/utils/toaster';
import { BoxImage } from '@/web/@components/BoxImage';
import { HubConnectionContext } from '@/web/@components/HubConnectionContext';
import LightTooltip from '@/web/@components/LightTooltip';
import { ShareUrlButton } from '@/web/@components/ShareUrlButton';
import { CameraDetailsContext } from '@/web/@layouts/CameraDetailsLayout';
import { CameraISPInformation } from '@/web/cameras/@components/CameraIspInfo';
import { Close as CloseIcon } from '@mui/icons-material';
import { Box, Button, CircularProgress, IconButton, Typography } from '@mui/material';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { EditCameraName } from '../EditCameraName';
import { HealthTagListBox } from '../HealthTagListBox';
import { CameraDetailsToSettingsButton } from './CameraDetailsToSettingsButton';
import { GoDeviceInfo } from './GoDeviceInfo';

export function CameraDetailsTitle() {
  const { cameraId, camera, isOnline, isInParkingMode, updateCamera } =
    useContext(CameraDetailsContext);

  const location = useLocation();
  const hub = useContext(HubConnectionContext);

  /** @type {StateVariable<AbortController>} */
  const [wakingUp, setWakingUp] = useState(null);
  /** @type {StateVariable<string>} */
  const [cameraState, setCameraState] = useState(null);
  /** @type {StateVariable<number>} */
  const [wakingUpStartTime, setWakingUpStartTime] = useState(null);

  const isManageCameraPage = useMemo(
    () => location.pathname.includes('device-groups'),
    [location.pathname]
  );

  useEffect(() => {
    if (!hub) return;
    /** @param {{type: string, text: string}} message */
    const handler = (message) => {
      if (message?.type === MESSAGE_TYPE.PARKING_DEVICE_AWAKE) {
        setCameraState(message.text);
      }
    };
    hub.on('newMessage', handler);
    return () => hub.off('newMessage', handler);
  }, [hub]);

  const sendWakeUpReport = useCallback(
    /** @param {string} [failReason] */
    (failReason) => {
      if (!wakingUpStartTime) return;
      const state = store.getState();
      const tenantId = selectTenantId(state);
      const tenantName = selectCurrentTenantName(state);
      const sessionId = selectSessionId(state);
      const secretToken = selectSecretToken(state);
      api.ac.v5.endpoint.wakeup.statistics
        .$post({
          headers: {
            Authorization: secretToken,
          },
          data: [
            {
              duration: Date.now() - wakingUpStartTime,
              status: !Boolean(failReason),
              failureReason: failReason,
              dashcamEndpointId: cameraId,
              dashcamDeviceKey: camera?.deviceSerialNumber,
              reportingTime: Date.now(),
              requestTime: wakingUpStartTime,
              tenantId,
              tenantName,
              initiatorClientType: 'BROWSER',
              initiatorEndpointId: sessionId + '',
            },
          ],
        })
        .process()
        .catch(console.error);
    },
    [wakingUpStartTime, cameraId, camera?.deviceSerialNumber]
  );

  useEffect(() => {
    if (!wakingUp || !cameraState) return;
    if (isCameraInParkingMode(cameraState)) return;
    updateCamera({ parkingModeStatus: cameraState });
    sendWakeUpReport();
    wakingUp.abort();
  }, [cameraState, sendWakeUpReport, updateCamera, wakingUp]);

  useEffect(() => {
    if (!wakingUp || !hub) return;
    const state = store.getState();
    const sessionId = selectSessionId(state);
    const secretToken = selectSecretToken(state);
    api.ac.v5.endpoint
      .$endpointId(cameraId)
      .awake.$post({
        params: {
          awakeUpTimeInSec: 10 * 60,
          senderId: sessionId,
        },
        headers: {
          Authorization: secretToken,
        },
      })
      .process()
      .catch((err) => {
        wakingUp.abort();
        console.error(err);
        sendWakeUpReport('API_ERROR');
      });
  }, [wakingUp, hub, cameraId, sendWakeUpReport]);

  const handleWakeUp = () => {
    if (wakingUp) return;

    setWakingUpStartTime(Date.now());
    setCameraState(camera.parkingModeStatus);

    const aborter = new AbortController();
    aborter.signal.addEventListener('abort', () => setWakingUp(null));
    setWakingUp(aborter);

    toastInfo('Waking up the device', 'Please wait');

    const tid = setTimeout(() => {
      toastWarning('Timed out waking up the device');
      sendWakeUpReport('TIMEOUT');
      aborter.abort();
    }, 60 * 1000);
    aborter.signal.addEventListener('abort', () => clearTimeout(tid));
  };

  const handleWakeUpAbort = () => {
    sendWakeUpReport('CANCELED');
    wakingUp.abort();
  };

  return (
    <Box display="flex" flexWrap="wrap" justifyContent="space-between" mb={2} ml={'-4px'}>
      <Box display="flex" gap={1}>
        <IconButton
          disableRipple={!isOnline}
          LinkComponent={isOnline ? 'a' : null}
          href={`/cameras/live?id=${cameraId}`}
          target="_self"
          sx={{
            flexDirection: 'column',
            justifyContent: 'flex-start',
            gap: '2px',
            p: '4px',
          }}
        >
          {/* <BoxImage src={camera?.isOnline ? CAMERA_ON_ICON : CAMERA_OFF_ICON} height="25px" />
          {isOnline && <LiveIcon />} */}
          <LightTooltip
            title={`${isInParkingMode ? 'Parked, ' : ''}
              ${camera?.isOnline ? 'Online' : 'Offline'}`}
            arrow
            followCursor
          >
            <Box
              sx={{
                width: '42px',
                height: '42px',
                borderRadius: '50%',
                backgroundColor: isInParkingMode
                  ? camera?.isOnline
                    ? 'rgb(203, 163, 5)'
                    : '#5e6a7f'
                  : camera?.isOnline
                    ? '#537bbc'
                    : '#f9faff',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <BoxImage
                src={
                  camera?.isOnline || isInParkingMode ? CAMERA_COMMON_ICON : CAMERA_COMMON_ICON_OFF
                }
                height="22px"
              />
            </Box>
          </LightTooltip>
        </IconButton>
        <Box flex={1} display="flex" flexDirection="column" justifyContent="center">
          <Box
            display="flex"
            gap="10px"
            alignItems="center"
            sx={{ '&:hover .edit-name-button': { opacity: 1 } }}
          >
            <Typography fontWeight="500">
              {formatCameraName(camera?.label, camera?.deviceSerialNumber)}
            </Typography>

            <EditCameraName
              className="edit-name-button"
              sx={{ opacity: 1, transition: 'opacity 0.3s ease-out', backgroundColor: '#F8FAFF' }}
            />

            <ShareUrlButton
              url={`${window.location.origin}/cameras/${camera.id}?tenantId=${camera.tenantId}`}
              size="large"
              title="Share Camera"
              iconProps={{ size: '140%' }}
              sx={{
                backgroundColor: '#F8FAFF',
                height: '32px',
                width: '32px',
                padding: '10px',
              }}
            />
          </Box>

          <Box display="flex" alignItems="center" gap="10px" mt="-4px">
            <Typography variant="subtitle2">
              {isInParkingMode ? 'Parked, ' : ''}
              {camera?.isOnline ? 'Online' : 'Offline'}
            </Typography>

            {!camera?.isOnline && camera?.lastOnlineTime ? (
              <Typography variant="subtitle2" fontSize={'0.75rem'}>
                Last online {formatDateTime(camera.lastOnlineTime)}
              </Typography>
            ) : null}

            {isInParkingMode && camera?.isOnline ? (
              <>
                {wakingUp ? (
                  <Box display="flex" alignItems="center" gap="5px">
                    <CircularProgress size="16px" />
                    <Typography fontSize="0.825rem" variant="subtitle2">
                      Waking Up
                    </Typography>
                    <IconButton size="small" onClick={handleWakeUpAbort} sx={{ p: 0 }}>
                      <CloseIcon fontSize="small" />
                    </IconButton>
                  </Box>
                ) : (
                  <Button
                    size="small"
                    onClick={handleWakeUp}
                    sx={{
                      color: '#1E59A8',
                      fontSize: '0.825rem',
                      fontWeight: 500,
                      px: '4px',
                      py: 0,
                    }}
                  >
                    Wake Up
                  </Button>
                )}
              </>
            ) : null}
          </Box>
        </Box>
      </Box>

      <Box display="flex" flex={1} gap={1} alignItems="center" justifyContent="flex-end">
        <GoDeviceInfo />
        {isManageCameraPage && <HealthTagListBox />}
        <CameraISPInformation />
        {!isProduction && <CameraDetailsToSettingsButton />}
      </Box>
    </Box>
  );
}
