import api from '@/api';
import { useFutureLoader } from './useFutureLoader';

/**
 * Fetch list of all Ai Accelerators
 */
export function useFetchAIAccelerators() {
  return useFutureLoader(
    async ({ signal, secretToken, tenantId }) => {
      let token = '';
      const limit = 100;
      /** @type {Array<AIAccelerator>} */
      let results = [];
      while (!signal.aborted) {
        const request = api.ac.v1.tenant.aiaccelerator.list.$get({
          params: {
            tenantId,
            secretToken,
            pageSize: limit,
            continuationToken: token,
          },
        });
        await request.process();
        if (!request.result) break;
        /** @type {Array<AIAccelerator>} */
        const result = request.result.aiAccelerators;
        results.push(...result);
        token = request.result.continuationToken;
        if (result.length < limit) break;
        if (!token?.trim() || result.length < limit) break;
      }
      return results;
    },
    [],
    { cache: true }
  );
}
