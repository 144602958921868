import api from '@/api';
import {
  CAMERA_N_OFF_ICON,
  NO_SEARCH_RESULT_IMG,
  TENANT_ICON_OFF,
} from '@/assets/constants/images';
import { selectSecretToken, selectSupportPath, selectTenantId } from '@/store/auth';
import { format12HourTime, licenseTimestampFormat } from '@/utils/datetime';
import { CenterBox } from '@/web/@components/CenterBox';
import { IconLabelItem } from '@/web/@components/IconLabelItem';
import { IconMessageBox } from '@/web/@components/IconMessageBox';
import {
  Box,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { format } from 'date-fns';
import { groupBy, sortBy } from 'lodash';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  formatSecondsToMinutesAndSeconds,
  getCurrentMonthName,
  getMonthValueByName,
} from '../../@utils';
import { VideoMinutesFilterContext } from '../../@context/VideoMinutesFilterContext';
import { useRouteQuery } from '@/hooks/useRouteQuery';

/**
 * @typedef {object} DescendantVideoMinutesItems
 * @property {Array<DescendantVideoMinutesItems>} [children]
 * @property {string} [id]
 * @property {string} [name]
 * @property {string} [type]
 * @property {string} [parentTenantId]
 */

/**
 * @typedef {object} VideoMinutesTenantTableProps
 * @property {() => any} [onSwitch]
 */

/** @param {VideoMinutesTenantTableProps} props */
export function VideoMinutesTenantTable(props) {
  const supportPath = useSelector(selectSupportPath);

  const { query, updateQuery } = useRouteQuery();
  const tenantId = useSelector(selectTenantId);
  const secretToken = useSelector(selectSecretToken);
  const { searchText, month } = useContext(VideoMinutesFilterContext);

  const theme = useTheme();
  const xlAndUp = useMediaQuery(theme.breakpoints.up('xl'));

  /** @type {StateVariable<array>} */
  const [itemList, setItemList] = useState([]);
  /** @type {StateVariable<boolean>} */
  const [tenantsLoading, setTenantsLoading] = useState(true);

  /** @type {StateVariable<Array<TenantVideoMinuteUsages>>} */
  const [tenantsMinutes, setTenantsMinutes] = useState([]);

  const currentMonthValue = useMemo(() => {
    return getMonthValueByName(query?.month || month);
  }, [month, query?.month]);

  useEffect(() => {
    if (query?.month) return;
    const currentMonthName = getCurrentMonthName();
    updateQuery({ month: currentMonthName });
  }, [query?.month, updateQuery]);

  /** @type {DescendantVideoMinutesItems[]} */
  const filteredTenantTree = useMemo(() => {
    const applyFilter = (tree, depth = 0) => {
      if (!tree?.length) return undefined;

      const filteredTree = [];
      for (const tenant of tree) {
        if (!tenant) continue;

        // Recursively apply filter to children
        const filteredChildren = applyFilter(tenant.children, depth + 1);

        // Check if the current tenant matches the filter condition
        const matchesFilter =
          !searchText ||
          tenant.name?.toLowerCase()?.includes(searchText?.toLowerCase()) ||
          filteredChildren?.length > 0;

        // Add the tenant to the filtered tree if it matches the filter condition
        if (matchesFilter) {
          filteredTree.push({ ...tenant, children: filteredChildren });
        }
      }

      // Sort the filtered tree by data type
      filteredTree.sort((a, b) => {
        const order = { TENANT: 1, GROUP: 2, ENDPOINT: 3 };
        return order[a.type] - order[b.type];
      });

      return filteredTree;
    };
    return applyFilter(itemList);
  }, [itemList, searchText]);

  // eslint-disable-next-line no-unused-vars
  const updateChildrenById = (items, targetId, updatedArray) => {
    return items.map((item) => {
      if (item.id === targetId) {
        return { ...item, children: updatedArray };
      } else if (item.children && item.children.length > 0) {
        item.children = updateChildrenById(item.children, targetId, updatedArray);
      }
      return item;
    });
  };

  const handleTenantChange = async (tree) => {
    const selectedTenantId = tree?.at(tree?.length - 1);
    const subTenants = await getSubTenantList(selectedTenantId);

    const subTenantData = async () => {
      const mappedData = subTenants?.map((item) => ({
        name: item?.tenantName,
        id: item?.tenantId,
        type: 'TENANT',
        parentTenantId: selectedTenantId,
      }));
      const sortedData = sortBy(mappedData, [(item) => item.name.toLowerCase()]);
      await getParentTenantAndChildVideoMinutes(selectedTenantId);
      return sortedData;
    };

    const tenantEndpointData = async () => {
      const endpoints = await getTenantEndpointList(selectedTenantId);
      const mappedEndpointsData = endpoints?.map((item) => ({
        name: item?.deviceLabel || `Smarter AI Dashcam ${item?.deviceSerialNo}`,
        id: item?.endpointId,
        parentTenantId: selectedTenantId,
        type: 'ENDPOINT',
      }));
      await getTenantEndpointsVideoMinutes(selectedTenantId);
      return mappedEndpointsData;
    };

    if (subTenants?.length > 0) {
      const tenants = await subTenantData();
      const endpoints = await tenantEndpointData();
      const data = [...tenants, ...endpoints];
      setItemList((prev) => updateChildrenById(prev, selectedTenantId, data));
    } else {
      const endpoints = await tenantEndpointData();
      setItemList((prev) => updateChildrenById(prev, selectedTenantId, endpoints));
    }
  };

  /**
   * Fetch Tenant Data
   */
  const getParentTenantDetails = useCallback(async () => {
    try {
      const request = api.ac.v5.tenant.$tenantId(tenantId).$get({
        headers: {
          Authorization: secretToken,
        },
      });
      await request.process();
      return request.result;
    } catch (ex) {}
  }, [secretToken, tenantId]);

  /**
   * @typedef {object} V5EndpointListGetParams
   * @property {!number} limit Limit number of endpoints.
   * @property {!number} offset Offset
   * @property {!string} status Endpoint Status [ACTIVE, RESET or ANY_STATUS]
   * @property {!string} tenantId A unique Tenant ID
   * @property {!string} type Endpoint Type [APP, DEVICE or ANY]
   */

  const getTenantEndpointList = useCallback(
    async (tenantId) => {
      try {
        const request = api.ac.v5.endpoint.list.$get({
          headers: {
            Authorization: secretToken,
          },
          params: {
            limit: 500,
            offset: 0,
            status: 'ACTIVE',
            tenantId: tenantId,
            type: 'DEVICE',
          },
        });
        await request.process();
        return request.result?.endpointInfoList;
      } catch (ex) {}
    },
    [secretToken]
  );

  const getParentTenantAndChildVideoMinutes = useCallback(
    async (tenantId) => {
      try {
        const request = api.ac.v5['video-minutes'].tenants.$tenantId(tenantId).$get({
          headers: {
            Authorization: secretToken,
          },
          params: {
            year: new Date().getFullYear().toString(),
            monthOfTheYear: currentMonthValue,
          },
        });
        await request.process();
        setTenantsMinutes((prev) => [...prev, ...request.result]);
        return request.result;
      } catch (ex) {}
    },
    [secretToken, currentMonthValue]
  );

  const getTenantEndpointsVideoMinutes = useCallback(
    async (tenantId) => {
      try {
        const request = api.ac.v5['video-minutes'].tenants.$tenantId(tenantId).endpoints.$get({
          headers: {
            Authorization: secretToken,
          },
          params: {
            year: new Date().getFullYear().toString(),
            monthOfTheYear: currentMonthValue,
          },
        });
        await request.process();
        setTenantsMinutes((prev) => [...prev, ...request.result]);
        return request.result;
      } catch (ex) {}
    },
    [secretToken, currentMonthValue]
  );

  const getSubTenantList = useCallback(
    async (tenantId, signal) => {
      try {
        if (!tenantId) return;
        const results = [];
        while (true) {
          const request = api.ac.v5.tenant.$tenantId(tenantId).subtenants.$get({
            signal: signal,
            headers: {
              Authorization: secretToken,
            },
          });
          await request.process();
          if (!request.result) break;
          const result = request.result;
          results.push(...result);
          break;
        }
        return results;
      } catch (ex) {}
    },
    [secretToken]
  );

  const fetchSubTenantList = useCallback(
    async (signal) => {
      //get the current tenant data
      const currentTenant = await getParentTenantDetails();
      const currentTenantEndpoints = await getTenantEndpointList(tenantId);

      //get current tenant subtenants data
      const results = await getSubTenantList(tenantId, signal);
      const mappedData = results?.map((item) => ({
        name: item?.tenantName,
        id: item?.tenantId,
        type: 'TENANT',
        parentTenantId: currentTenant?.tenantId,
      }));
      const sortedData = sortBy(mappedData, [(item) => item.name.toLowerCase()]);

      const mappedEndpointsData = currentTenantEndpoints?.map((item) => ({
        name: item?.deviceLabel,
        id: item?.endpointId,
        parentTenantId: tenantId,
        type: 'ENDPOINT',
      }));

      //merge sub tenants and group data
      const mergedData = [...sortedData, ...mappedEndpointsData];

      //creating the final array
      const tenantData = [
        {
          name: currentTenant?.tenantName,
          id: currentTenant?.tenantId,
          type: 'TENANT',
          children: mergedData,
        },
      ];
      setItemList(tenantData);
      setTenantsLoading(false);
    },
    [tenantId, getParentTenantDetails, getTenantEndpointList, getSubTenantList]
  );

  useEffect(() => {
    setTenantsLoading(true);
    const aborter = new AbortController();
    fetchSubTenantList(aborter?.signal);
    getTenantEndpointsVideoMinutes(tenantId);
    getParentTenantAndChildVideoMinutes(tenantId);
    return () => aborter.abort();
  }, [
    tenantId,
    fetchSubTenantList,
    getParentTenantAndChildVideoMinutes,
    getTenantEndpointsVideoMinutes,
  ]);

  useEffect(() => {
    setItemList([]);
    setTenantsMinutes([]);
  }, [currentMonthValue]);

  return (
    <Box sx={{ maxHeight: '96vh' }}>
      {tenantsLoading ? (
        <CenterBox sx={{ height: '80vh !important' }}>
          <CircularProgress />
        </CenterBox>
      ) : filteredTenantTree?.length ? (
        <Box sx={{ maxHeight: '86vh', overflowY: 'auto', pb: xlAndUp ? 0 : 6 }}>
          <TableContainer component={Paper} sx={{ maxHeight: '78vh' }}>
            <Table stickyHeader sx={{ width: '100%' }} size="small">
              <TableHead>
                <TableRow
                  sx={{
                    '& .MuiTableCell-head': {
                      border: '1px solid #e0e0e0',
                      fontSize: xlAndUp ? '1rem' : '0.78rem',
                    },
                  }}
                >
                  <TableCell align="left"></TableCell>
                  <TableCell align="center">Recording Playback</TableCell>
                  <TableCell align="center">Event Playback</TableCell>
                  <TableCell align="center">Live Streaming</TableCell>
                  <TableCell align="center" sx={{ borderRight: '1px solid #e0e0e0' }}>
                    Total
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredTenantTree?.map((tenant) => (
                  <TenantTreeItem
                    key={tenant.id}
                    tree={tenant}
                    filter={searchText}
                    selected={supportPath || []}
                    tenantsMinutes={tenantsMinutes}
                    onSelect={handleTenantChange}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      ) : (
        <CenterBox>
          <IconMessageBox
            src={NO_SEARCH_RESULT_IMG}
            gap={0}
            size="256px"
            message="No search results"
          />
        </CenterBox>
      )}
    </Box>
  );
}

/**
 * @typedef {object} TenantTreeItemProps
 * @property {number} [indent]
 * @property {string} [filter]
 * @property {string[]} selected
 * @property {DescendantVideoMinutesItems} tree
 * @property {(path: string[]) => any} [onSelect]
 * @property {Array<TenantVideoMinuteUsages>} [tenantsMinutes ]
 */

/** @param {TenantTreeItemProps} props */
export function TenantTreeItem(props) {
  const { indent = 0, tree, filter, selected, tenantsMinutes, onSelect } = props;

  const tenantId = useSelector(selectTenantId);
  const secretToken = useSelector(selectSecretToken);

  const [collapsed, setCollapsed] = useState(true);

  const [endpointTransaction, setEndpointTransaction] = useState([]);
  const { month } = useContext(VideoMinutesFilterContext);

  const active =
    tree.id?.toString() === tenantId?.toString() ||
    (selected?.length === 1 && selected[0] === tree.id);

  const getEndpointTransaction = (endpointId) => {
    try {
      const currentMonthValue = getMonthValueByName(month);
      const request = api.ac.v5['video-minutes'].tenants
        .$tenantId(tenantId)
        .endpoints.$endpointId(endpointId)
        .$get({
          headers: {
            Authorization: secretToken,
          },
          params: {
            year: new Date().getFullYear().toString(),
            monthOfTheYear: currentMonthValue,
          },
        });

      request?.process()?.then((data) => {
        setEndpointTransaction(data?.transactions);
      });
    } catch (ex) {}
  };

  useEffect(() => {
    setCollapsed(!selected?.includes(tree?.id));
  }, [tree.id, filter, selected, tenantId]);

  const nameParts = useMemo(() => {
    const name = tree.name || '';
    if (!filter?.length) {
      return [name];
    }
    let offset = 0;
    const parts = [];
    for (const part of name.toLowerCase().split(filter)) {
      parts.push(name.substring(offset, offset + part.length));
      offset += part.length;
      if (name.length === offset) break;
      parts.push(name.substring(offset, offset + filter.length));
      offset += filter.length;
    }
    return parts;
  }, [tree.name, filter]);

  const tenantDuration = useMemo(() => {
    const data = tenantsMinutes?.find((t) =>
      // @ts-ignore
      tree?.type === 'TENANT' ? t.tenantId === tree?.id : t.endpointId?.toString() === tree?.id
    );

    if (data) {
      const sumDurationSeconds = (type) => {
        const filteredData = data?.videoMinuteUsages?.filter((item) => item.type.includes(type));
        const sum = filteredData.reduce((total, item) => total + item.durationSeconds, 0);
        return sum ? formatSecondsToMinutesAndSeconds(sum) : '';
      };

      const liveDuration = sumDurationSeconds('LIVE');
      const eventDuration = sumDurationSeconds('EVENT');
      const recordingDuration = sumDurationSeconds('RECORDING');
      const totalDuration = data?.videoMinuteUsages?.reduce(
        (total, item) => total + item.durationSeconds,
        0
      );

      return {
        live: liveDuration,
        event: eventDuration,
        recording: recordingDuration,
        total: formatSecondsToMinutesAndSeconds(totalDuration),
      };
    } else {
      return null;
    }
  }, [tenantsMinutes, tree?.id, tree?.type]);

  const itemImage = (type) => {
    return type === 'TENANT' ? TENANT_ICON_OFF : type === 'ENDPOINT' ? CAMERA_N_OFF_ICON : null;
  };

  return (
    <>
      <TableRow
        sx={{
          '&:hover': {
            bgcolor: active ? undefined : '#F6F9FE',
          },
          '& .MuiTableCell-body': {
            borderBottom: '1px solid #e0e0e0',
          },
        }}
      >
        <TableCell
          align="left"
          key={tree.id}
          sx={{
            pl: 4 + indent * 3.8,
            width: '40%',
          }}
        >
          <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
            {tenantDuration && (
              <Box
                width="24px"
                height="24px"
                ml="-25px !important"
                onClick={(e) => {
                  e.stopPropagation();
                  setCollapsed((v) => !v);
                  if (tree?.type === 'ENDPOINT') getEndpointTransaction(tree?.id);
                  if (tree?.type === 'TENANT') onSelect([tree?.id]);
                }}
              >
                <svg
                  width="12px"
                  height="12px"
                  viewBox="0 0 2 2"
                  xmlns="http://www.w3.org/2000/svg"
                  style={{
                    transition: 'all 0.3s ease-in',
                    margin: collapsed ? '6px 10px' : '10px 6px',
                    transform: collapsed ? 'rotate(-90deg)' : undefined,
                  }}
                >
                  <path d="M1,1,2,0H0Z" fill="#5C6A80" />
                </svg>
              </Box>
            )}
            <Box flex="1" fontWeight={tree?.type === 'TENANT' ? 450 : 400} fontSize=".90rem">
              <IconLabelItem
                sx={{ alignItems: 'center' }}
                src={itemImage(tree?.type)}
                title={
                  nameParts?.map((part, i) => (i % 2 ? <b>{part}</b> : part)) ||
                  'Smarter AI Dashcam'
                }
              ></IconLabelItem>
            </Box>
          </Box>
        </TableCell>
        <TableCell
          align="center"
          sx={{
            borderLeft: '1px solid #e0e0e0',
            borderRight: '1px solid #e0e0e0',
            fontSize: '.90rem',
          }}
        >
          {tenantDuration?.recording || ''}
        </TableCell>
        <TableCell align="center" sx={{ fontSize: '.90rem' }}>
          {tenantDuration?.event || ''}
        </TableCell>
        <TableCell
          align="center"
          sx={{
            borderLeft: '1px solid #e0e0e0',
            borderRight: '1px solid #e0e0e0',
            fontSize: '.90rem',
          }}
        >
          {tenantDuration?.live || ''}
        </TableCell>
        <TableCell align="center" sx={{ width: '12%', fontSize: '.90rem' }}>
          {tenantDuration?.total || ''}
        </TableCell>
      </TableRow>

      {collapsed || !tree?.children?.length ? null : (
        <>
          {tree?.children.map((child) => (
            <TenantTreeItem
              key={child.id}
              indent={indent + 1}
              tree={child}
              filter={filter}
              selected={selected}
              tenantsMinutes={tenantsMinutes}
              onSelect={(path) => onSelect([tree.id, ...path])}
            />
          ))}
        </>
      )}

      {endpointTransaction && !collapsed && (
        <EndpointTreeItem endpointTransaction={endpointTransaction} />
      )}
    </>
  );
}

export function EndpointTreeItem(props) {
  const { indent = 0, endpointTransaction } = props;

  const groupedData = groupBy(
    endpointTransaction,
    (item) => `${format(item.createdAt, 'yyyyMMddHHmmss')}-${item.usagesType}`
  );

  const resultArray = [];

  const dates = [];

  // Process the data and calculate durations
  Object.values(groupedData).forEach((arr) => {
    let liveDuration = 0;
    let eventDuration = 0;
    let recordingDuration = 0;
    let createdAt = null;

    arr.forEach((item) => {
      createdAt = item.createdAt; // Update createdAt for each item processed
      if (item.usagesType.includes('LIVE')) {
        liveDuration += item.durationSeconds;
      } else if (item.usagesType.includes('EVENT')) {
        eventDuration += item.durationSeconds;
      } else if (item.usagesType.includes('RECORDING')) {
        recordingDuration += item.durationSeconds;
      }
    });

    const createdAtDate = new Date(createdAt);
    const formattedDate = format(createdAtDate, 'yyyyMMdd');
    const isContains = dates.some((date) => format(date, 'yyyyMMdd') === formattedDate);
    if (!isContains) {
      dates.push(createdAtDate);
    }

    // Create the result object for each loop iteration
    const resultObject = {
      live: liveDuration ? formatSecondsToMinutesAndSeconds(liveDuration) : '',
      event: eventDuration ? formatSecondsToMinutesAndSeconds(eventDuration) : '',
      recording: recordingDuration ? formatSecondsToMinutesAndSeconds(recordingDuration) : '',
      total: formatSecondsToMinutesAndSeconds(liveDuration + eventDuration + recordingDuration),
      date: isContains ? '' : createdAt,
      time: createdAt,
    };

    resultArray.push(resultObject); // Add the result object to the array
  });

  return resultArray?.map((item) => (
    <>
      <TableRow
        sx={{
          '&:hover': {
            bgcolor: '#F6F9FE',
          },
          '& .MuiTableCell-body': {
            borderBottom: '1px solid #e0e0e0',
          },
        }}
      >
        <TableCell
          align="left"
          sx={{
            pl: 11.5 + indent * 3.5,
            width: '40%',
          }}
        >
          <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
            <Box flex="1" fontSize=".90rem">
              {licenseTimestampFormat(item?.date)}
            </Box>
            <Box flex="1" fontSize=".90rem">
              {format12HourTime(item?.time)}
            </Box>
          </Box>
        </TableCell>
        <TableCell
          align="center"
          sx={{
            borderLeft: '1px solid #e0e0e0',
            borderRight: '1px solid #e0e0e0',
            fontSize: '.90rem',
          }}
        >
          {item?.recording || ''}
        </TableCell>
        <TableCell align="center" sx={{ fontSize: '.90rem' }}>
          {item?.event || ''}
        </TableCell>
        <TableCell
          align="center"
          sx={{
            borderLeft: '1px solid #e0e0e0',
            borderRight: '1px solid #e0e0e0',
            fontSize: '.90rem',
          }}
        >
          {item?.live || ''}
        </TableCell>
        <TableCell align="center" sx={{ width: '12%', fontSize: '.90rem' }}>
          {item?.total || ''}
        </TableCell>
      </TableRow>
    </>
  ));
}
