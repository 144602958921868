import { Close as CloseIcon } from '@mui/icons-material';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useState } from 'react';

/**
 * @template T
 * @typedef {object} LongMessageViewerProps<T>
 * @property {string} [message]
 * @property {string} [title]
 * @property {string} [moreText]
 * @property {boolean} [showOnCLick]
 */

/**
 * @template T
 * @param {LongMessageViewerProps<T>} props
 */
export function LongMessageViewer(props) {
  const { message, title, moreText = '', showOnCLick } = props;

  const theme = useTheme();
  const xlAndUp = useMediaQuery(theme.breakpoints.up('xl'));

  const maxChars = xlAndUp ? 40 : 30;
  const modalEnabled = message?.length > maxChars || message?.includes('\n');

  const [showModal, setShowModal] = useState(false);

  const handleModalClose = (e) => {
    setShowModal(false);
  };

  return (
    <Box style={{ cursor: modalEnabled ? 'pointer' : 'default' }}>
      {showOnCLick ? (
        <Box sx={{ color: '#1E59A8' }} onClick={() => setShowModal(true)}>
          {moreText || 'Details'}
        </Box>
      ) : (
        <Box onClick={() => message?.length > maxChars && setShowModal(true)}>
          {message?.substr(0, maxChars)}
          {message?.length > maxChars ? '... ' : ''}
          {modalEnabled && (
            <a
              href="/"
              onClick={(e) => {
                e.preventDefault();
              }}
              style={{ color: '#1E59A8' }}
            >
              {moreText || 'more'}
            </a>
          )}
        </Box>
      )}

      {showModal && (
        <>
          <Dialog open fullWidth maxWidth={'md'} onClose={handleModalClose}>
            <DialogTitle display="flex" justifyContent="space-between">
              <Box>{title}</Box>
              <IconButton size="small" onClick={handleModalClose}>
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <Divider />
            <DialogContent>
              <pre style={{ whiteSpace: 'pre-wrap' }}>{message}</pre>
            </DialogContent>
            <Divider />
            <DialogActions sx={{ p: 2 }}>
              <Button onClick={handleModalClose}>Close</Button>
            </DialogActions>
          </Dialog>
        </>
      )}
    </Box>
  );
}
