export const FROM_TYPES = {
  INPUT: 'input',
  SWITCH: 'switch',
  SLIDER: 'slider',
  SELECT: 'select',
  MULTISELECT: 'MULTISELECT',
  DATE_TIME: 'time',
  GEO_FENCE: 'geo',
  AI_MODEL_LEVELS: 'AI_MODEL_LEVELS',
  OBD2: 'OBD2',
  NUMBER: 'number',
  NUMBER_RANGE: 'number_range',
  DEVICE_WIFI_CONNECTIVITY: 'DEVICE_WIFI_CONNECTIVITY',
};

export const DASHCAM_ASSOCIATED_TYPE_ID = 'c078a302-3926-453f-87f3-036ffc2d1018';

export const GROUP_SETTINGS_CATEGORIES = {
  RECORDING: 'recording',
  STREAMING: 'streaming',
  ONBOARDING: 'onboarding',
  DEVICE: 'device_settings',
  POWER: 'power-management',
  VISION: 'vision',
  PROFILING: 'profiling',
  SENSOR: 'sensor_pipelines',
  PRIVACY: 'object_blurring_settings',
  CONNECTIVITY: 'deviceWifiConnectivity',
  DRIVER: 'driver-login',
  TRAFFIC: 'traffic-features',
  FACE_LOGIN: 'face-login',
  REBOOT: 'reboot',
};
