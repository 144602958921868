import { store } from '@/store';
import { doLogout, selectAuthenticationType } from '@/store/auth';
import { PageView, selectToolbarMenu } from '@/store/page-view';
import { BoxImage } from '@/web/@components/BoxImage';
import { CircleBox } from '@/web/@components/CircleBox';
import {
  Box,
  Divider,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from '@mui/material';
import { useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { SwitchTenant } from '../SwitchTenant';
import { UserAvatar } from '../UserAvatar';
import { ChangePasswordDialog } from './ChangePasswordDialog';

/** @param {import('@mui/material').BoxProps} props */
export function UserMenu(props) {
  const buttonRef = useRef();

  const menuOpen = useSelector(selectToolbarMenu);
  const authenticationType = useSelector(selectAuthenticationType);

  /** @type {StateVariable<boolean>} */
  const [passwordOpen, setPasswordOpen] = useState(false);

  // @ts-ignore
  const isGeoTabLogin = useMemo(() => authenticationType === 'GEOTAB', [authenticationType]);

  const setMenuOpen = (value) => {
    store.dispatch(PageView.setToolbarMenuOpen(value));
  };

  const openPasswordResetForm = () => {
    setPasswordOpen(true);
    store.dispatch(PageView.setToolbarMenuOpen(false));
  };

  const handleLogout = () => {
    store.dispatch(doLogout());
  };

  return (
    <Box {...props}>
      <IconButton ref={buttonRef} onClick={() => setMenuOpen(true)} sx={{ p: 0 }}>
        <CircleBox size="42px">
          <UserAvatar />
        </CircleBox>
      </IconButton>

      {buttonRef.current && (
        <Menu
          open={menuOpen}
          anchorEl={buttonRef.current}
          onClose={() => setMenuOpen(false)}
          elevation={4}
          slotProps={{
            paper: {
              sx: {
                'mt': 1,
                'width': { xs: '100% !important', md: '400px !important' },
                'borderRadius': '8px',
                'overflow': 'visible',
                'filter': 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                '&::before': {
                  content: '""',
                  display: 'block',
                  position: 'absolute',
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: 'background.paper',
                  transform: 'translateY(-50%) rotate(45deg)',
                  zIndex: 0,
                },
              },
            },
          }}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          MenuListProps={{
            sx: {
              'py': 0,
              '& .MuiMenuItem-root': {
                fontWeight: 400,
                py: 0.75,
              },
            },
          }}
        >
          <SwitchTenant onSwitch={() => setMenuOpen(false)} />

          <Divider sx={{ my: '0 !important' }} />

          {!isGeoTabLogin && (
            <MenuItem
              sx={{ bgcolor: '#F6F9FE' }}
              style={{ padding: '10px 16px' }}
              onClick={openPasswordResetForm}
            >
              Reset Password
            </MenuItem>
          )}

          <Divider sx={{ my: '0 !important' }} />

          <MenuItem
            onClick={handleLogout}
            sx={{ bgcolor: '#F6F9FE' }}
            style={{ padding: '10px 16px' }}
          >
            <ListItemIcon>
              <BoxImage src="/images/layouts/topbar/logout-icon.svg" size={16} />
            </ListItemIcon>
            <ListItemText sx={{ ml: '-10px' }}>Logout</ListItemText>
          </MenuItem>
        </Menu>
      )}

      <ChangePasswordDialog open={passwordOpen} onClose={() => setPasswordOpen(false)} />
    </Box>
  );
}
