import { sortDataByKey } from '@/web/@components/ItemsTableView/utils';
import { Table, TableBody, TableContainer, TableFooter, TableRow, useTheme } from '@mui/material';
import { useMemo, useState } from 'react';
import { ItemTableViewHead } from './ItemTableViewHead';
import { ItemTableViewTableCell } from './ItemTableViewTableCell';

/**
 * @template T
 * @typedef {object} ItemsTableViewPropsBase<T>
 * @property {Array<T>} [data]
 * @property {string} [defaultSortKey]
 * @property {Array<TableColumn<T>>} [columns]
 * @property {import('@mui/material').TableBodyProps['children']} [children]
 */

/**
 * @template T
 * @typedef {ItemsTableViewPropsBase<T> & import('@mui/material').TableContainerProps} ItemsTableViewProps<T>
 */

/**
 * @template T
 * @param {ItemsTableViewProps<T>} props
 */
export function ItemsTableView(props) {
  const { data, columns, defaultSortKey, children, ...tableProps } = props;

  const theme = useTheme();

  /** @type {StateVariable<import('@mui/material').SortDirection>} */
  const [order, setOrder] = useState('asc');
  /** @type {StateVariable<string>} */
  const [orderBy, setOrderBy] = useState(defaultSortKey || '');

  /** @type {Array<T>} */
  const sortedData = useMemo(
    () => sortDataByKey(data, orderBy, order, columns[orderBy]?.fallback),
    [data, orderBy, order, columns]
  );

  /** @type {import('./ItemTableViewHead').ItemTableViewHeadProps<T>['onSortRequest']} */
  const handleSortRequest = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  return (
    <TableContainer
      {...tableProps}
      sx={{
        'height': {
          xs: 'fit-content',
          md: 'calc(100vh - 220px)',
        },
        'fontSize': '0.875rem',
        '& .MuiTableHead-root .MuiTableCell-root': {
          'fontSize': '1rem',
          'color': theme.palette.text.secondary,
          'borderTop': '1px solid #E0ECFC',
          'borderBottom': '1px solid #E0ECFC',
          'fontWeight': 500,
          ':first-of-type': {
            borderLeft: '1px solid #E0ECFC',
            borderRadius: '4px 0 0 4px',
          },
          ':last-child': {
            borderRight: '1px solid #E0ECFC',
            borderRadius: '0 4px 4px 0',
          },
        },
        '& .MuiTableHead-root .MuiCheckbox-root > .MuiSvgIcon-root': {
          fill: '#D3E0F2',
          display: 'none',
        },
        '& .MuiTableBody-root': {
          '& .MuiTableRow-root': {},
          '& .MuiTableRow-root:hover': {
            backgroundColor: '#adb4bc17',
          },
          '& .MuiTableCell-root': {
            'borderBottom': '1px solid #E0ECFC',
            'fontSize': '1rem',
            'py': '6px',
            '& .Mui-checked': {
              color: '#608AC3',
            },
          },
        },
        ...tableProps.sx,
      }}
    >
      <Table size="small" stickyHeader>
        <ItemTableViewHead
          columns={columns}
          data={data}
          order={order}
          orderBy={orderBy}
          onSortRequest={handleSortRequest}
        />
        <TableBody>
          <>
            {sortedData.map((row, index) => (
              <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                {columns.map((column, index) => (
                  <ItemTableViewTableCell key={column.id + index} column={column} row={row} />
                ))}
              </TableRow>
            ))}
          </>
        </TableBody>
        <TableFooter>{children}</TableFooter>
      </Table>
    </TableContainer>
  );
}
