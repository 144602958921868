import { selectTeamList, selectTenantId, selectUserEmail, selectUserRole } from '@/store/auth';
import { filterByUserAccess } from '@/utils/user-access';
import { ConfirmationDialog } from '@/web/@components/ConfirmationDialog';
import { PaginatedTableContext } from '@/web/@components/PaginatedTableContext';
import { SearchField } from '@/web/@components/SearchField';
import { TableActionButton } from '@/web/@components/TableActionButton';
import { Box, Typography } from '@mui/material';
import { isFunction } from 'lodash';
import { useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { FilterContext, SetFilterContext } from '../FilterContext';
import { SelectField } from '../SelectField';

/**
 * @template T
 * @typedef {object} ItemsListHeaderProps<T>
 * @property {string} [title]
 * @property {string} [secondaryTitle]
 * @property {Array<TableHeaderAction<T>>} [actions]
 * @property {Array<T>} [selectedItems]
 * @property {(type: TableHeaderAction<T>['type'], selected: Array<T>, reload: function) => any} [onAction]
 */

/**
 * @template T
 * @param {ItemsListHeaderProps<T> & import('@mui/material').BoxProps} props
 */
export function ItemsListHeader(props) {
  const { title: tableTitle, actions, onAction, secondaryTitle, ...boxProps } = props;

  const userRole = useSelector(selectUserRole);
  const userEmail = useSelector(selectUserEmail);
  const tenantId = useSelector(selectTenantId);
  const teamList = useSelector(selectTeamList);

  const { t } = useTranslation();
  const setFilter = useContext(SetFilterContext);
  const { searchText, searchType } = useContext(FilterContext);
  const {
    selectedItems,
    filteredDataCount: totalCount,
    setTableReload,
    setSelectedItems,
  } = useContext(PaginatedTableContext);

  const navigate = useNavigate();
  /** @type {StateVariable<number>} */
  const [loading, setLoading] = useState();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogTitle, setDialogTitle] = useState('');
  const [openActionType, setOpenActionType] = useState(null);

  const filterTableHeaderItems = useMemo(() => {
    return actions?.filter(filterByUserAccess({ userRole, userEmail, tenantId, teamList }));
  }, [actions, userEmail, userRole, tenantId, teamList]);

  /**
   * @param {TableHeaderAction<T>} item
   * @param {number} index
   */
  const handleButtonClick = (item, index) => {
    if (item.useCustomDialog) {
      const promise = onAction && onAction(item.type, selectedItems, () => setTableReload(true));
      if (promise instanceof Promise) {
        setLoading(index);
        promise.catch(console.error).finally(() => setLoading(undefined));
      }
    } else if ('link' in item) {
      navigate(isFunction(item.link) ? item.link(...selectedItems) : item.link);
    } else {
      setDialogOpen(true);
      setDialogTitle(item.confirmationMessage);
      setOpenActionType(item.type);
    }
  };

  /**
   * @param {string} text
   * @param {TableHeaderAction<T>} item
   */
  const handleSearch = (text, item) => {
    setSelectedItems([]);
    setFilter({
      offset: 0,
      searchText: text,
      searchType: searchType || item.options?.at(0).value,
    });
  };

  /**
   * @param {string} type
   * @param {TableHeaderAction<T>} item
   */
  const handleSearchTypeChange = (type, item) => {
    setSelectedItems([]);
    setFilter({
      offset: 0,
      searchText: '',
      searchType: type,
    });
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
    setOpenActionType(null);
  };

  const handleDialogConfirmation = () => {
    setDialogOpen(false);
    onAction && onAction(openActionType, selectedItems, () => setTableReload(true));
  };

  return (
    <Box
      mb={2}
      gap="10px"
      display="flex"
      flexWrap="wrap"
      alignItems="center"
      justifyContent="space-between"
      flexDirection={{ xs: 'column', sm: 'row' }}
      sx={{ position: 'sticky', zIndex: 100, top: 0, bgcolor: 'white' }}
      {...boxProps}
    >
      <ConfirmationDialog
        open={dialogOpen}
        message={dialogTitle}
        onCancel={handleDialogClose}
        onApply={handleDialogConfirmation}
      />

      <Box width={{ xs: '100%', sm: 'fit-content' }}>
        {totalCount > 0 && tableTitle ? (
          <>
            <Typography component="span" fontSize="1.125rem" fontWeight="500" mr="5px">
              {totalCount}
            </Typography>
            <Typography component="span" variant="body2" fontSize="1.125rem" fontWeight="500">
              {t(tableTitle)}
            </Typography>
          </>
        ) : (
          <>
            {!totalCount && tableTitle && (
              <Typography variant="body2" fontSize="1.125rem" fontWeight="500">
                {t(tableTitle)}
              </Typography>
            )}
            {secondaryTitle && (
              <Typography variant="body2" fontSize="0.875rem" fontWeight="500">
                {t(secondaryTitle)}
              </Typography>
            )}
          </>
        )}
      </Box>
      <Box
        gap="8px"
        display="flex"
        flexWrap="wrap"
        alignItems="center"
        justifyContent="flex-end"
        width={{ xs: '100%', sm: 'fit-content' }}
        flexDirection={{ xs: 'column', sm: 'row' }}
      >
        {filterTableHeaderItems
          ?.filter((item) => {
            if (typeof item.hidden === 'boolean') {
              return !item.hidden;
            } else if (typeof item.hidden === 'function') {
              return !item.hidden(selectedItems || []);
            }
            return true;
          })
          ?.map((item, index) =>
            item.component === 'BUTTON' ? (
              <TableActionButton
                key={index}
                item={item}
                loading={loading === index}
                onClick={() => handleButtonClick(item, index)}
              />
            ) : item.component === 'SEARCH' ? (
              <Box
                key={index}
                display="flex"
                gap="8px"
                alignItems="center"
                width={{ xs: '100%', sm: 'fit-content' }}
              >
                <SelectField
                  options={item.options}
                  value={searchType || item.options?.at(0)?.value || ''}
                  onChange={(value) => handleSearchTypeChange(value, item)}
                  sx={{
                    width: { xs: '100%', sm: 'calc(25% - 4px)', md: 'fit-content' },
                  }}
                />
                <SearchField
                  clearable
                  value={searchText}
                  placeholder={t(item.label)}
                  onSearch={(value) => handleSearch(value, item)}
                  searchOnEnter={!item.searchOnEveryKey}
                />
              </Box>
            ) : null
          )}
      </Box>
    </Box>
  );
}
