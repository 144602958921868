import api from '@/api';
import { selectSecretToken, selectTenantId } from '@/store/auth';
import { toastWarning } from '@/utils/toaster';
import { Box, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ExpressionTriggerForm } from '../../@components/ExpressionTriggerForm';

export function ExpressionTriggerEdit() {
  const secretToken = useSelector(selectSecretToken);
  const tenantId = useSelector(selectTenantId);
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  /** @type {import('../../@components/ExpressionTriggerForm').ExpressionTriggerFormProps['onSubmitData']} */
  const updateTrigger = async (data, triggerId) => {
    try {
      setLoading(true);
      const req = await api.ac.v5.trigger.composite.$triggerId(triggerId).$put({
        headers: {
          Authorization: secretToken,
        },
        data,
      });
      await req.process();
      navigate('../..');
    } catch (err) {
      toastWarning('Error', 'Failed to update, please try later.');
    } finally {
      setLoading(false);
    }
  };

  // Navigate triggers list page while tenant change
  useEffect(() => {
    return () => navigate('../..');
  }, [tenantId, navigate]);

  return (
    <Box m={3}>
      <Typography variant="body2" fontSize="1.12rem" fontWeight={'medium'}>
        Update Trigger
      </Typography>
      <ExpressionTriggerForm onSubmitData={updateTrigger} isLoading={loading} />
    </Box>
  );
}
