import api from '@/api';
import { NO_SEARCH_RESULT_IMG } from '@/assets/constants/images';
import { selectSecretToken } from '@/store/auth';
import { getAiContainerDeploymentStatus } from '@/utils/ai-containers';
import { CenterBox } from '@/web/@components/CenterBox';
import { IconMessageBox } from '@/web/@components/IconMessageBox';
import { CameraDetailsContext } from '@/web/@layouts/CameraDetailsLayout';
import { Box, Grid, Typography } from '@mui/material';
import { camelCase, capitalize } from 'lodash';
import { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

export function CameraContainer() {
  const { cameraId, capabilities } = useContext(CameraDetailsContext);
  const secretToken = useSelector(selectSecretToken);

  const [container, setContainer] = useState(null);
  const [containerModels, setContainerModels] = useState([]);

  useEffect(() => {
    const resp = api.ac.v2.repository.aicontainer.deployed.endpoint.$get({
      params: {
        endpointId: cameraId,
        pageSize: 1,
        secretToken,
      },
    });
    resp.process().then((result) => {
      setContainer(result.data?.at(0));
    });
  }, [secretToken, cameraId]);

  useEffect(() => {
    if (capabilities?.modelStatus0 || capabilities?.modelStatus1) {
      setContainerModels([
        capabilities?.modelStatus0 && capabilities?.modelStatus0,
        capabilities?.modelStatus1 && capabilities?.modelStatus1,
      ]);
    }
  }, [capabilities]);

  if (!container) {
    return (
      <CenterBox>
        <IconMessageBox src={NO_SEARCH_RESULT_IMG} size="100px" message="No AI Container" />
      </CenterBox>
    );
  }

  return (
    <Box display="grid" gap="10px">
      <Grid container spacing={2.5} textAlign="left">
        <Grid item xs={12} md={4} lg={3}>
          <Typography variant="subtitle2" lineHeight="18px">
            Name
          </Typography>
          <Typography variant="subtitle1" fontWeight={500} lineHeight="18px">
            {container?.name || 'Not Available'}
          </Typography>
        </Grid>
        <Grid item xs={12} md={4} lg={3} position="relative">
          <Typography variant="subtitle2" lineHeight="18px">
            Version
          </Typography>
          <Typography variant="subtitle1" fontWeight={500} lineHeight="18px">
            {container?.releaseVersion || 'Not Available'}
          </Typography>
        </Grid>
        <Grid item xs={12} md={4} lg={3} position="relative">
          <Typography variant="subtitle2" lineHeight="18px">
            Deployment Status
          </Typography>
          <Typography variant="subtitle1" fontWeight={500} lineHeight="18px">
            {getAiContainerDeploymentStatus(capabilities?.aiContainerDeploymentStatus) ||
              'Not Available'}
          </Typography>
        </Grid>
        <Grid item xs={12} md={4} lg={3} position="relative">
          <Typography variant="subtitle2" lineHeight="18px">
            Publication Status
          </Typography>
          <Typography variant="subtitle1" fontWeight={500} lineHeight="18px">
            {capitalize(camelCase(container?.publicationStatus)) || 'Not Available'}
          </Typography>
        </Grid>
        <Grid item xs={12} md={4} lg={3} position="relative">
          <Typography variant="subtitle2" lineHeight="18px">
            Description
          </Typography>
          <Typography variant="subtitle1" fontWeight={500} lineHeight="18px">
            {container?.description || 'Not Available'}
          </Typography>
        </Grid>
        {containerModels?.length > 0 && (
          <Grid item xs={12} md={4} lg={3} position="relative">
            <Typography variant="subtitle2" lineHeight="18px">
              Model Status
            </Typography>
            {containerModels?.map((item, index) => (
              <Grid key={index}>
                <Typography variant="subtitle1" fontWeight={500} lineHeight="18px">
                  {item}
                </Typography>
              </Grid>
            ))}
          </Grid>
        )}
      </Grid>
    </Box>
  );
}
