import { useRouteQuery } from '@/hooks/useRouteQuery';
import { Box, IconButton, Typography } from '@mui/material';
import { useCallback, useEffect, useRef, useState } from 'react';
import { BoxImage } from '../BoxImage';
import { SearchField } from '../SearchField';
import { isEmpty } from 'lodash';

export function CollapsibleSearch(props) {
  const { collapsedText = 'Search' } = props;

  const { query, updateQuery } = useRouteQuery();

  const wrapperRef = useRef();
  const filterRef = useRef('');

  /** @type {StateVariable<string>} */
  const [filter, setFilter] = useState('');
  /** @type {StateVariable<boolean>} */
  const [searchEnable, setSearchEnable] = useState(false);

  useEffect(() => {
    if (isEmpty(query?.search)) return;
    setFilter(query?.search + '');
    setSearchEnable(true);
  }, [setFilter, query]);

  // Handle Click outside for bigger display
  const handleMenuCloseWhileClickOutside = useCallback(
    (e) => {
      if (filterRef?.current?.trim()?.length) return;
      if (
        wrapperRef.current &&
        searchEnable &&
        // @ts-ignore
        !wrapperRef.current?.contains(e.target)
      ) {
        setTimeout(() => {
          setSearchEnable(false);
        }, 100);
      }
    },
    [searchEnable]
  );

  useEffect(() => {
    document.addEventListener('mousedown', handleMenuCloseWhileClickOutside);
    return () => {
      document.removeEventListener('visibilitychange', handleMenuCloseWhileClickOutside);
    };
  }, [handleMenuCloseWhileClickOutside]);

  useEffect(() => {
    filterRef.current = filter;
  }, [filter]);

  return searchEnable ? (
    <Box
      ref={wrapperRef}
      sx={{
        'width': '100%',
        'display': 'flex',
        '& .MuiInputBase-root': {
          'padding': '5px !important',
          '& input::placeholder': {
            fontSize: '16px',
          },
          '& fieldset': {
            display: 'none',
          },
        },
      }}
    >
      <SearchField
        clearable
        ignoreCase
        autoFocus
        value={filter}
        placeholder="Search"
        onSearch={(text) => {
          setFilter(text);
          updateQuery({ search: text });
        }}
        sx={{ width: '100%', height: '44px', borderRadius: '5px' }}
      />
    </Box>
  ) : (
    <Box
      sx={{
        display: 'flex',
        textAlign: 'end',
        justifyContent: 'flex-end',
        alignItems: 'center',
        cursor: 'pointer',
        mt: '20px',
      }}
      onClick={() => setSearchEnable(true)}
    >
      <IconButton sx={{ mr: '-5px' }}>
        <BoxImage src="/images/commons/search-icon.svg" size="16px" />
      </IconButton>
      <Typography variant="subtitle2" color="#99a9bd">
        {collapsedText}
      </Typography>
    </Box>
  );
}
