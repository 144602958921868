import api from '@/api';
import { toastWarning } from '@/utils/toaster';
import { Auth, getAllDescendants } from '..';
import { isTenantLoggedIn, selectIsSupport } from '../selectors';

/**
 * Refresh the main and support access token
 * @returns {StoreAction<Promise<any>>}
 */
export const refreshToken = () => async (dispatch, getState) => {
  await refreshMainToken(dispatch, getState);
  await refreshSupportToken(dispatch, getState);

  const state = getState();
  if (['SUPPORT', 'SUPER_ADMIN'].includes(state.auth.role)) {
    const request = api.ac.v5.tenant.descendants.$get({
      headers: {
        Authorization: state.auth.accessToken,
      },
    });
    const tree = await request.process();
    const tenant = state.auth.tenantList.find((x) => x.tenantId === state.auth.tenantId);
    /** @type {DescendantTenant} */
    const root = {
      tenantId: tenant.tenantId,
      tenantName: tenant.tenantName,
      descendantTenantList: tree.descendantTenantList,
    };
    dispatch(Auth.setTenantTree(root));
  }
};

/**
 * Refresh the main access token
 * @type {import('@reduxjs/toolkit').ThunkAction<Promise, StoreState, any, any>}
 */
const refreshMainToken = async (dispatch, getState) => {
  const state = getState();
  if (!isTenantLoggedIn(state)) return;

  // check if access token should be refreshed
  if (state.auth.accessTokenExpiry - Date.now() > 60 * 1000) {
    return;
  }

  // logout if refresh token is expired
  if (state.auth.refreshTokenExpiry - Date.now() < 60 * 1000) {
    toastWarning('Session expired', 'Please login again');
    dispatch(Auth.logout());
    return;
  }

  // check if remember me is enabled
  if (state.auth.request.authenticationType === 'PASSWORD' && !state.auth.remember) {
    toastWarning('Session expired', 'Please login again');
    dispatch(Auth.logout());
    return;
  }

  // build api request
  const request = api.ac.v5.auth['tenant-access'].$post({
    data: {
      email: state.auth.email,
      tenantId: state.auth.tenantId,
      token: state.auth.refreshToken,
    },
  });

  // call api and update token
  try {
    dispatch(Auth.setTokenRefreshing(true));
    await request.process();
    const result = request.result;
    if (!request.result?.accessToken) {
      throw new Error('No access token in response');
    }
    dispatch(Auth.setTenant(result));
    dispatch(getAllDescendants()).catch(console.error);
  } catch (err) {
    console.error('Token refresh error', err);
    if ((request.response?.status || 500) >= 400) {
      toastWarning('Session expired', 'Please login again');
      dispatch(Auth.logout());
    }
  } finally {
    dispatch(Auth.setTokenRefreshing(false));
  }
};

/**
 * Refresh the support access token
 * @type {import('@reduxjs/toolkit').ThunkAction<Promise, StoreState, any, any>}
 */
async function refreshSupportToken(dispatch, getState) {
  const state = getState();
  if (!selectIsSupport(state)) return;

  // check if access token should be refreshed
  if (state.auth.supportAccessTokenExpiry - Date.now() > 60 * 1000) {
    return;
  }

  // logout if refresh token is expired
  if (state.auth.supportRefreshTokenExpiry - Date.now() < 60 * 1000) {
    toastWarning('Support session expired');
    dispatch(Auth.setSupportTenant());
    return;
  }

  // build api request
  const request = api.ac.v5.auth['tenant-access'].$post({
    data: {
      email: state.auth.email,
      tenantId: state.auth.supportTenantId,
      token: state.auth.supportRefreshToken,
      asSupport: true,
    },
  });

  // call api and update token
  try {
    dispatch(Auth.setTokenRefreshing(true));
    await request.process();
    const result = request.result;
    if (!request.result?.accessToken) {
      throw new Error('No access token in response');
    }
    const action = Auth.setSupportTenant({
      result,
      support: state.auth.supportPath,
    });
    dispatch(action);
  } catch (err) {
    console.error('Support token refresh error', err);
    if ((request.response?.status || 500) >= 400) {
      toastWarning('Support session expired');
      dispatch(Auth.setSupportTenant());
    }
  } finally {
    dispatch(Auth.setTokenRefreshing(false));
  }
}
