import api from '@/api';
import { T } from '@/assets/locales';
import { TRIGGERS_MEDIA_TYPES, TRIGGER_CHIME_OPTIONS } from '@/assets/triggers';
import { useFetchTriggerCategories } from '@/hooks/useFetchTriggerCategories';
import { useLabelListFetch } from '@/hooks/useLabelListFetch';
import { selectSecretToken, selectTenantId } from '@/store/auth';
import { trimString } from '@/utils/formatting';
import { findExpressionLabels } from '@/utils/triggers';
import { CustomInput, CustomSelect } from '@/web/@components/CustomForm';
import { MainContext } from '@/web/@components/PageBreadcrumb/BreadcrumbContext';
import { Box, Button, CircularProgress, Divider, Typography } from '@mui/material';
import { useCallback, useContext, useEffect, useMemo } from 'react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { ExpressionGenerator } from '../ExpressionGenerator';
import { TRIGGER_SENSITIVITY_UPLOAD_TYPE_OPTIONS } from '@/web/administration/triggers-configuration/assets';

/**
 * @typedef {object} ExpressionTriggerFormPropsBase
 * @property {(data: CompositeTriggerRequest, triggerId: any) => any} onSubmitData
 * @property {boolean} isLoading
 */

const defaultValues = {
  cooldownTimer: 30,
  mediaType: TRIGGERS_MEDIA_TYPES[3],
  chime: TRIGGER_CHIME_OPTIONS[0],
  tts: '',
  critical: false,
  triggerCategory: null,
  preBuffer: 5,
  postBuffer: 5,
  enableTts: true,
  uploadStrategy: TRIGGER_SENSITIVITY_UPLOAD_TYPE_OPTIONS[0],
  expression: '',
  displayFormula: '',
  magnitudeFormula: '',
  snapshotFormula: '',
  classifications: [],
  magnitudeClassifications: [],
  snapshotClassifications: [],
};

/**
 * @typedef {ExpressionTriggerFormPropsBase & import('@mui/material').BoxProps} ExpressionTriggerFormProps
 */

/**
 * @param {ExpressionTriggerFormProps} props
 */
export function ExpressionTriggerForm(props) {
  const { onSubmitData, isLoading, ...extra } = props;
  const tenantId = useSelector(selectTenantId);
  const secretToken = useSelector(selectSecretToken);
  const { setBreadcrumbTitle } = useContext(MainContext);
  const params = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { result: labels } = useLabelListFetch();
  const { result: triggerCategories, loading: triggerCategoryLoading } =
    useFetchTriggerCategories();

  const triggerId = useMemo(() => params.id, [params]);

  const form = useForm({
    mode: 'all',
    defaultValues,
    shouldUnregister: false,
  });

  const displayFormulaField = useWatch({
    control: form.control,
    name: `displayFormula`,
  });

  const magnitudeFormulaField = useWatch({
    control: form.control,
    name: `magnitudeFormula`,
  });

  const snapshotFormulaField = useWatch({
    control: form.control,
    name: `snapshotFormula`,
  });

  const classificationsField = useWatch({
    control: form.control,
    name: `classifications`,
  });

  const magnitudeClassificationsField = useWatch({
    control: form.control,
    name: `magnitudeClassifications`,
  });

  const snapshotClassificationsField = useWatch({
    control: form.control,
    name: `snapshotClassifications`,
  });

  const handleCancel = () => {
    navigate('/smarter-ai/triggers');
  };

  /** @param {typeof defaultValues} data */
  const prepareAndSubmit = (data) => {
    if (!onSubmitData) return;

    data.expression = trimString(data.expression);
    data.displayFormula = trimString(data.displayFormula);

    const {
      cooldownTimer,
      mediaType,
      chime,
      tts,
      critical,
      displayFormula,
      triggerCategory,
      classifications,
      postBuffer,
      preBuffer,
      enableTts,
      magnitudeFormula,
      snapshotFormula,
      uploadStrategy,
      magnitudeClassifications,
      snapshotClassifications,
    } = data;
    const name = triggerCategory?.name || 'Default';

    const bodyObj = {
      expression: displayFormula,
      displayFormula: displayFormula,
      classifications: classifications || [],
      type: 'EXPRESSION',
    };

    const magnitudeFormulaObj = {
      expression: trimString(magnitudeFormula) || null,
      displayFormula: trimString(magnitudeFormula) || null,
      classifications: magnitudeClassifications || null,
      type: trimString(magnitudeFormula) ? 'EXPRESSION' : null,
    };

    const snapshotFormulaObj = {
      expression: trimString(snapshotFormula) || null,
      displayFormula: trimString(snapshotFormula) || null,
      classifications: snapshotClassifications || null,
      type: trimString(snapshotFormula) ? 'EXPRESSION' : null,
    };

    /** @type {CompositeTriggerRequest} */
    const formData = {
      name,
      cooldown: false,
      cooldownTimer: cooldownTimer ? cooldownTimer * 1000 : 0,
      mediaType: mediaType?.value,
      critical,
      tts,
      triggerCategoryId: triggerCategory?.id || 1,
      chime: chime?.value,
      uploadStrategy: uploadStrategy?.value,
      postBuffer,
      preBuffer,
      enableTts,
      body: JSON.stringify(bodyObj),
      magnitudeFormula: JSON.stringify(magnitudeFormulaObj),
      snapshotFormula: JSON.stringify(snapshotFormulaObj),
    };

    onSubmitData(formData, triggerId);
  };

  useEffect(() => {
    if (triggerCategoryLoading || !triggerCategories?.length) return;

    if (!triggerId) {
      const triggerCategory = triggerCategories?.find((item) => item.id === 1); //default
      form.setValue('triggerCategory', triggerCategory);
      setBreadcrumbTitle(triggerCategory?.category || triggerCategory?.name || 'Default');
      return;
    }

    const req = api.ac.v5.trigger.composite.$triggerId(triggerId).$get({
      headers: {
        Authorization: secretToken,
      },
      params: {
        tenantId,
      },
    });
    req.process().then((result) => {
      if (!result) return;
      const {
        name,
        mediaType,
        critical,
        chime,
        tts,
        cooldownTimer,
        body,
        triggerCategoryId,
        preBuffer,
        postBuffer,
        enableTts,
        magnitudeFormula,
        snapshotFormula,
        uploadStrategy,
      } = result;

      if (cooldownTimer) {
        form.setValue('cooldownTimer', cooldownTimer?.value / 1000);
      }
      form.setValue('tts', tts);
      form.setValue('critical', critical);

      if (preBuffer) {
        form.setValue('preBuffer', preBuffer?.value);
      }

      if (postBuffer) {
        form.setValue('postBuffer', postBuffer?.value);
      }

      form.setValue('enableTts', enableTts?.value || true);

      const uploadItem = TRIGGER_SENSITIVITY_UPLOAD_TYPE_OPTIONS?.find(
        (item) => item.value === uploadStrategy?.value
      );

      if (uploadItem) {
        form.setValue('uploadStrategy', uploadItem);
      }

      const triggerCategory = triggerCategories?.find((item) => item.id === triggerCategoryId);
      form.setValue('triggerCategory', triggerCategory);
      setBreadcrumbTitle(triggerCategory?.name || name);

      const selectedMediaType = TRIGGERS_MEDIA_TYPES?.find(
        (item) => item.value === mediaType?.value
      );

      if (selectedMediaType) {
        form.setValue('mediaType', selectedMediaType);
      }

      const selectedChime = TRIGGER_CHIME_OPTIONS?.find((item) => item.value === chime?.value);
      if (selectedChime) form.setValue('chime', selectedChime);
      const expression = body?.displayFormula || body?.expression;
      form.setValue('expression', expression);
      form.setValue('displayFormula', expression);
      form.setValue('classifications', body?.classifications || []);

      if (magnitudeFormula?.expression) {
        form.setValue('magnitudeFormula', magnitudeFormula?.expression);
        // @ts-ignore
        form.setValue('magnitudeClassifications', magnitudeFormula?.magnitudeClassifications);
      }

      if (snapshotFormula?.expression) {
        form.setValue('snapshotFormula', snapshotFormula?.expression);
        // @ts-ignore
        form.setValue('snapshotClassifications', snapshotFormula?.snapshotClassifications);
      }
    });

    return () => req.abort();
  }, [
    form,
    triggerId,
    secretToken,
    tenantId,
    setBreadcrumbTitle,
    triggerCategories,
    triggerCategoryLoading,
  ]);

  const updateClassifications = useCallback(
    (formulaField, classificationsField, classificationsName) => {
      const exp = findExpressionLabels(labels, formulaField);
      const value = classificationsField || [];
      const extra = exp?.filter((x) => !value.some((y) => y.label === x.label));
      if (!extra?.length) return;
      form.setValue(classificationsName, [...value, ...extra]);
    },
    [labels, form]
  );

  useEffect(() => {
    updateClassifications(displayFormulaField, classificationsField, 'classifications');
  }, [classificationsField, displayFormulaField, updateClassifications]);

  useEffect(() => {
    updateClassifications(
      snapshotFormulaField,
      snapshotClassificationsField,
      'snapshotClassifications'
    );
  }, [snapshotClassificationsField, snapshotFormulaField, updateClassifications]);

  useEffect(() => {
    updateClassifications(
      magnitudeFormulaField,
      magnitudeClassificationsField,
      'magnitudeClassifications'
    );
  }, [magnitudeClassificationsField, magnitudeFormulaField, updateClassifications]);

  return (
    <Box {...extra}>
      <Typography
        variant="body2"
        fontSize="1rem"
        mt={'20px'}
        mb={'10px'}
        sx={{ color: ' #0B2547', opacity: 0.68, fontSize: '16px' }}
      >
        Trigger Details
      </Typography>
      <Divider />
      <FormProvider {...form}>
        <form autoComplete="off" noValidate>
          <Box
            display="grid"
            gridTemplateColumns={{ sm: '1fr', md: '1fr 1fr 1fr' }}
            gap="10px"
            paddingTop="10px"
            paddingX="10px"
          >
            <CustomSelect
              name="triggerCategory"
              label="Trigger Name *"
              options={triggerCategories || []}
              loading={triggerCategoryLoading}
              getKey="id"
              getLabel="name"
              placeholder="Select Trigger Name"
              rules={{ required: 'Trigger name is required' }}
            />

            <CustomInput
              name="cooldownTimer"
              label="Cooldown Timer *"
              placeholder="Enter cooldown timer"
              type="number"
              InputProps={{
                endAdornment: (
                  <Typography sx={{ fontSize: '12px', color: '#8492a3' }}>seconds</Typography>
                ),
              }}
              rules={{
                required: 'Cooldown Timer is required',
                validate: {
                  cooldownTimer: (value) =>
                    value < 1 || value > 3600
                      ? 'Cooldown Timer should be between 1 to 3600'
                      : undefined,
                },
              }}
            />

            <CustomSelect
              name="mediaType"
              label="Media Type *"
              options={TRIGGERS_MEDIA_TYPES}
              placeholder="Select media type"
              rules={{ required: 'Media type is required' }}
            />

            <CustomSelect
              name="uploadStrategy"
              label="Upload Strategy *"
              options={TRIGGER_SENSITIVITY_UPLOAD_TYPE_OPTIONS}
              placeholder="Select upload strategy"
              rules={{ required: 'Upload strategy is required' }}
            />

            <CustomSelect
              name="chime"
              label="Chime"
              options={TRIGGER_CHIME_OPTIONS}
              placeholder="Select chime"
            />

            <CustomInput
              name="preBuffer"
              label="Pre Buffer *"
              placeholder="Pre Buffer"
              type="number"
              InputProps={{
                endAdornment: (
                  <Typography sx={{ fontSize: '12px', color: '#8492a3' }}>seconds</Typography>
                ),
              }}
              rules={{
                required: 'Pre Buffer is required',
                validate: {
                  cooldownTimer: (value) =>
                    value < 1 || value > 50 ? 'Pre Buffer should be between 1 to 50' : undefined,
                },
              }}
            />

            <CustomInput
              name="postBuffer"
              label="Post Buffer *"
              placeholder="Post Buffer"
              type="number"
              InputProps={{
                endAdornment: (
                  <Typography sx={{ fontSize: '12px', color: '#8492a3' }}>seconds</Typography>
                ),
              }}
              rules={{
                required: 'Post Buffer is required',
                validate: {
                  cooldownTimer: (value) =>
                    value < 1 || value > 50 ? 'Post Buffer should be between 1 to 50' : undefined,
                },
              }}
            />

            <CustomInput
              name="tts"
              label="Voice Alert"
              placeholder="Voice alert for your Driver"
              TextFieldProps={{
                multiline: false,
                inputProps: {
                  maxLength: 30,
                },
              }}
              rules={{
                validate: {
                  tts: (value) => (value?.length > 30 ? 'Maximum length is 30' : undefined),
                },
              }}
            />
          </Box>

          <Box
            pt={2.5}
            px={1.5}
            mt="20px"
            borderRadius="11px"
            minHeight="137px"
            py={2}
            rowGap={2}
            bgcolor="#FAFAFA"
          >
            <ExpressionGenerator
              labels={labels}
              itemLabel={'Trigger Formula *'}
              isRequired={true}
              keyName={'displayFormula'}
            />
            <CustomSelect
              name="classifications"
              label="Labels"
              getKey="label"
              getLabel="name"
              loading={labels?.length <= 0}
              options={labels?.length > 0 ? labels : []}
              placeholder="Select Labels"
              AutocompleteProps={{
                multiple: true,
              }}
            />
          </Box>

          <Box
            pt={2.5}
            px={1.5}
            mt="20px"
            borderRadius="11px"
            minHeight="137px"
            py={2}
            rowGap={2}
            bgcolor="#FAFAFA"
          >
            <ExpressionGenerator
              labels={labels}
              itemLabel={'Magnitude Formula'}
              keyName={'magnitudeFormula'}
            />
            <CustomSelect
              name="magnitudeClassifications"
              label="Labels"
              getKey="label"
              getLabel="name"
              loading={labels?.length <= 0}
              options={labels?.length > 0 ? labels : []}
              placeholder="Select Labels"
              AutocompleteProps={{
                multiple: true,
              }}
            />
          </Box>

          <Box
            pt={2.5}
            px={1.5}
            mt="20px"
            borderRadius="11px"
            minHeight="137px"
            py={2}
            rowGap={2}
            bgcolor="#FAFAFA"
          >
            <ExpressionGenerator
              labels={labels}
              itemLabel={'Snapshot Formula'}
              keyName={'snapshotFormula'}
            />
            <CustomSelect
              name="snapshotClassifications"
              label="Labels"
              getKey="label"
              getLabel="name"
              loading={labels?.length <= 0}
              options={labels?.length > 0 ? labels : []}
              placeholder="Select Labels"
              AutocompleteProps={{
                multiple: true,
              }}
            />
          </Box>

          <Box display={'flex'} justifyContent={'flex-end'} gap={'5px'} mb={15} mt={3}>
            <Button variant="text" onClick={handleCancel}>
              {t(T['button.cancel'])}
            </Button>
            <Button
              disabled={isLoading}
              variant="contained"
              onClick={form.handleSubmit(prepareAndSubmit)}
            >
              {isLoading && <CircularProgress color="inherit" size={16} sx={{ mr: '5px' }} />}
              {triggerId ? t(T['button.update']) : t(T['button.save'])}
            </Button>
          </Box>
        </form>
      </FormProvider>
    </Box>
  );
}
