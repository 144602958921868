export const customOperators = [
  { label: 'NONE', value: 'NONE' },
  { label: 'SMOOTH', value: 'SMOOTH' },
  { label: 'FILTER', value: 'FILTER' },
  { label: 'PERSISTENT', value: 'PERSISTENT' },
];

export const TRIGGER_CHIME_OPTIONS = [
  { label: 'None', value: 'NONE' },
  { label: 'Chime 1', value: 'Chime1' },
  { label: 'Chime 2', value: 'Chime2' },
  { label: 'Chime 3', value: 'Chime3' },
];

export const COOL_DOWN_OPTIONS = [
  { label: 'True', value: true },
  { label: 'False', value: false },
];

/** @type {Array<{value: 'NONE'|'SNAPSHOT'|'VIDEO'|'SNAPSHOT_AND_VIDEO', label: string}>}  */
export const TRIGGERS_MEDIA_TYPES = [
  { value: 'NONE', label: 'None' },
  { value: 'SNAPSHOT', label: 'Snapshot' },
  { value: 'VIDEO', label: 'Video' },
  { value: 'SNAPSHOT_AND_VIDEO', label: 'Snapshot and Video' },
];

// TODO: to be used later after backend changes
// export const TRIGGERS_MEDIA_TYPES = [
//   { value: 'NONE', label: 'None' },
//   { value: 'NO_VIDEO', label: 'Snapshot' },
//   { value: 'NO_SNAPSHOT', label: 'Video' },
//   { value: 'ALL', label: 'Snapshot and Video' },
// ];

export const TRIGGER_OPERATION_OPERATORS = [
  {
    key: '<',
    value: '<',
  },
  {
    key: '<=',
    value: '<=',
  },
  {
    key: '>',
    value: '>',
  },
  {
    key: '>=',
    value: '>=',
  },
  {
    key: '==',
    value: '==',
  },
];

export const TRIGGER_ARITHMETIC_OPERATORS = [
  {
    key: '+',
    value: '+',
  },
  {
    key: '-',
    value: '-',
  },
  {
    key: '*',
    value: '*',
  },
  {
    key: '/',
    value: '/',
  },
  {
    key: '%',
    value: '%',
  },
];

export const TRIGGER_LOGICAL_OPERATORS = [
  {
    key: 'AND',
    value: 'AND',
  },
  {
    key: 'OR',
    value: 'OR',
  },
  {
    key: 'XOR',
    value: 'XOR',
  },
  {
    key: 'NOT',
    value: 'NOT',
  },
];

export const TRIGGER_BRACKETS = [
  {
    key: '(',
    value: '(',
  },
  {
    key: '{',
    value: '{',
  },
  {
    key: '[',
    value: '[',
  },
  {
    key: ']',
    value: ']',
  },
  {
    key: '}',
    value: '}',
  },
  {
    key: ')',
    value: ')',
  },
];

export const TRIGGER_OPEN_BRACKETS = [
  {
    key: '(',
    value: '(',
  },
  {
    key: '{',
    value: '{',
  },
  {
    key: '[',
    value: '[',
  },
];

export const TRIGGER_CLOSE_BRACKETS = [
  {
    key: ']',
    value: ']',
  },
  {
    key: '}',
    value: '}',
  },
  {
    key: ')',
    value: ')',
  },
];

export const TRIGGER_FORMULAS = [
  {
    key: '/filter',
    value: 'FILTER( _, _, _)',
  },
  {
    key: '/between',
    value: 'BETWEEN( _, _, _, _ )',
  },
  {
    key: '/cross_limit',
    value: 'CROSS_LIMIT( _, _, _ )',
  },
  {
    key: '/for',
    value: 'FOR( _, _ )',
  },
  {
    key: '/persistent',
    value: 'PERSISTENT(_)',
  },
  {
    key: '/smooth',
    value: 'SMOOTH(_ , _)',
  },
];

export const TRIGGER_MATH_OPERATORS = [
  {
    key: 'min',
    value: 'min( _ , _ )',
  },
  {
    key: 'max',
    value: 'max(_ , _)',
  },
  {
    key: 'avg',
    value: 'avg(_,_)',
  },
  {
    key: 'sum',
    value: 'sum(_,_)',
  },
  {
    key: 'abs',
    value: 'abs(_)',
  },
  {
    key: 'ceil',
    value: 'ceil(_)',
  },
  {
    key: 'floor',
    value: 'floor(_)',
  },
  {
    key: 'round',
    value: 'round(_)',
  },
  {
    key: 'roundn',
    value: 'roundn(_)',
  },
  {
    key: 'exp',
    value: 'exp(_)',
  },
  {
    key: 'log',
    value: 'log(_)',
  },
  {
    key: 'log10',
    value: 'log10(_)',
  },
  {
    key: 'logn',
    value: 'logn(_)',
  },
  {
    key: 'pow',
    value: 'pow(_)',
  },
  {
    key: 'root',
    value: 'root(_)',
  },
  {
    key: 'sqrt',
    value: 'sqrt(_)',
  },
  {
    key: 'clamp',
    value: 'clamp(_)',
  },
  {
    key: 'inrange',
    value: 'inrange(_,_)',
  },
];

export const TRIGGER_TRIGONOMETRY_OPERATORS = [
  {
    key: 'sin',
    value: 'sin(_)',
  },
  {
    key: 'cos',
    value: 'cos(_)',
  },
  {
    key: 'tan',
    value: 'tan(_)',
  },
  {
    key: 'acos',
    value: 'acos(_)',
  },
  {
    key: 'asin',
    value: 'asin(_)',
  },
  {
    key: 'atan',
    value: 'atan(_)',
  },
  {
    key: 'atan2',
    value: 'atan2(_)',
  },
  {
    key: 'cosh',
    value: 'cosh(_)',
  },
  {
    key: 'atan2',
    value: 'atan2(_)',
  },
  {
    key: 'cot',
    value: 'cot(_)',
  },
  {
    key: 'csc',
    value: 'csc(_)',
  },
  {
    key: 'sec',
    value: 'sec(_)',
  },
  {
    key: 'sinh',
    value: 'sinh(_)',
  },
  {
    key: 'tanh',
    value: 'tanh(_)',
  },
  {
    key: 'd2r',
    value: 'd2r(_)',
  },
  {
    key: 'r2d',
    value: 'r2d(_)',
  },
  {
    key: 'd2g',
    value: 'd2g(_)',
  },
  {
    key: 'g2d',
    value: 'g2d(_)',
  },
  {
    key: 'hyp',
    value: 'hyp(_)',
  },
];
