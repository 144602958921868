import api from '@/api';
import {
  AI_CONTAINER_CAMERA_VIDEO_ICON,
  TRIGGER_CHIME_ICON,
  TRIGGER_ON_CAMERA_ICON,
  TRIGGER_ON_CLOUD_ICON,
  TRIGGER_SNAPSHOT_ICON,
} from '@/assets/constants/images';
import { selectSecretToken } from '@/store/auth';
import { toastWarning } from '@/utils/toaster';
import { BoxImage } from '@/web/@components/BoxImage';
import { CustomInput, CustomSlider } from '@/web/@components/CustomForm';
import { LongTextShortener } from '@/web/@components/LongTextShortener';
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  InputAdornment,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import { capitalize, isEmpty, startCase } from 'lodash';
import { useContext, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { TriggerConfigurationContext } from '../../@context/TriggerConfigurationContext';
import {
  COOLDOWN_MENU_ITEMS,
  LANGUAGE_MENU_ITEMS,
  SENSITIVITY_MEDIA_MAPPING,
  TRIGGER_SENSITIVITY_CHIME_OPTIONS,
  TRIGGER_SENSITIVITY_MEDIA_TYPES,
  TRIGGER_SENSITIVITY_UPLOAD_TYPE_OPTIONS,
} from '../../assets';
import { convertValueByUnit, getSliderMarks } from '../../utils';
import LineChart from '../LineChart';
import { TriggerResetButton } from '../TriggerResetButton';

const selectStyle = {
  '& .MuiSelect-select': {
    'border': 'none',
    'fontSize': '12px !important',
    'color': '#8592a3',
    '& fieldset': { border: 'none !important' },
    '&  .Mui-focused': {
      '& fieldset': { border: 'none !important' },
    },
  },
  '& .MuiInputAdornment-root': {
    'marginRight': '-14px !important',
    '& fieldset': { border: 'none  !important' },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none !important',
    },
  },
  '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
    display: 'none',
  },
  '& input[type=number]': {
    MozAppearance: 'textfield',
  },
};

export function TriggerForm(props) {
  const { onCancel, triggerItem: trigger } = props;

  const secretToken = useSelector(selectSecretToken);
  const { selectionType, setTriggers, triggerCategories, variablesDefinition } = useContext(
    TriggerConfigurationContext
  );

  /** @type {StateVariable<string>} */
  const [coolDownUnit, setCoolDownUnit] = useState('SECONDS');
  /** @type {StateVariable<boolean>} */
  const [isEnableCoolDown, setIsEnableCoolDown] = useState(false);
  /** @type {StateVariable<string>} */
  const [ttsLanguage, setTtsLanguage] = useState('EN');
  /** @type {StateVariable<string>} */
  const [chimeValue, setChimeValue] = useState('');
  /** @type {StateVariable<string>} */
  const [uploadTypeValue, setUploadTypeValue] = useState('');
  /** @type {StateVariable<array>} */
  const [mediaTypeValues, setMediaTypeValues] = useState([]);
  /** @type {StateVariable<string>} */
  const [actionType, setActionType] = useState('');
  /** @type {StateVariable<array>} */
  const [overridesValues, setOverridesValues] = useState([]);
  /** @type {StateVariable<boolean>} */
  const [loading, setLoading] = useState(false);

  const form = useForm({
    mode: 'all',
    defaultValues: {
      cooldownTimer: 0,
      tts: '',
      preBuffer: 0,
      postBuffer: 0,
    },
  });

  const { setValue, getValues } = form;

  useEffect(() => {
    const mediaType = SENSITIVITY_MEDIA_MAPPING[trigger?.mediaType?.value];
    setMediaTypeValues(mediaType);
    setChimeValue(trigger?.chime?.value);
    setUploadTypeValue(trigger?.uploadStrategy?.value || 'SD_CARD');

    setIsEnableCoolDown(trigger?.cooldown?.value);
    if (!trigger?.cooldown?.value) {
      setCoolDownUnit('TURN_OFF');
    }

    setValue('cooldownTimer', trigger?.cooldownTimer?.value / 1000); //milli to sec
    setValue('preBuffer', trigger?.preBuffer?.value);
    setValue('postBuffer', trigger?.postBuffer?.value);
    setValue('tts', trigger?.tts);

    if (!trigger?.enableTts?.value) {
      setTtsLanguage('TURN_OFF');
    }
  }, [trigger, setValue]);

  const triggerVariableOverrideUpdate = async (newOverridesValues, isReset = false) => {
    try {
      if (!selectionType || !newOverridesValues || newOverridesValues.length === 0) {
        return;
      }

      const requests = newOverridesValues
        ?.filter((x) =>
          variablesDefinition?.find((y) => x.variableName === y.name && y.customerFacing)
        )
        ?.map(async (item) => {
          const defaultVal = variablesDefinition?.find(
            (va) => va?.name === item?.variableName
          )?.defaultValue;

          const request = api.ac.v5.trigger.variable.override
            .$variableName(item?.variableName)
            .$put({
              headers: {
                Authorization: secretToken,
              },
              data: {
                value: isReset ? defaultVal : item?.value,
                targetID: selectionType.value,
                targetLevel: selectionType.type,
              },
            });
          await request.process();
        });
      await Promise.all(requests);
    } catch (ex) {
      toastWarning('Failed to override variable config');
    }
  };

  /**
   * Get the actual media types while submitting.
   * @returns {'NONE'|'SNAPSHOT'|'VIDEO'|'SNAPSHOT_AND_VIDEO'} The actual media type.
   */
  const getActualMediaTypesWhileSubmit = () => {
    if (mediaTypeValues?.includes('VIDEO') && mediaTypeValues?.includes('SNAPSHOT'))
      return 'SNAPSHOT_AND_VIDEO';
    else if (mediaTypeValues?.includes('VIDEO')) return 'VIDEO';
    else if (mediaTypeValues?.includes('SNAPSHOT')) return 'SNAPSHOT';
    else return 'NONE';
  };

  const triggerSettingsOverride = async (data, isReset = false) => {
    if (!selectionType || !data) return;
    let newValue;
    if (isReset) {
      newValue = {
        chime: data?.chime?.value,
        mediaType: data?.mediaType?.value,
        postBuffer: data?.postBuffer?.value,
        preBuffer: data?.preBuffer?.value,
        targetId: selectionType?.value,
        targetLevel: selectionType?.type,
        cooldown: data?.cooldown?.value,
        cooldownTimer: data?.cooldownTimer?.value,
        uploadStrategy: data?.uploadStrategy?.value,
        //tts: data?.tts,
        enableTts: data?.enableTts?.value,
      };
    } else {
      const mediaType = getActualMediaTypesWhileSubmit();
      /** @type {CompositeTriggerConfigUpdateRequest} */
      newValue = {
        chime: chimeValue,
        mediaType,
        postBuffer: data.postBuffer,
        preBuffer: data.preBuffer,
        targetId: selectionType?.value,
        targetLevel: selectionType?.type,
        cooldown: isEnableCoolDown,
        cooldownTimer: data.cooldownTimer * 1000,
        uploadStrategy: uploadTypeValue,
        //tts: data?.tts,
        enableTts: ttsLanguage === 'TURN_OFF' ? false : true,
      };
    }

    try {
      const request = api.ac.v5.trigger.composite
        .$triggerCategoryId(trigger.triggerCategoryId)
        .config.$put({
          headers: {
            Authorization: secretToken,
          },
          data: newValue,
        });
      await request?.process();
      //triggerLocalDataUpdate
      updateTriggerItemByIdAcrossKeys(trigger?.id, newValue, isReset);
    } catch (ex) {
      toastWarning('Failed to update trigger configuration');
    }
  };

  const triggerVariableOverride = (variableName, value, unFormattedValue) => {
    setOverridesValues((prevArray) =>
      prevArray.some((item) => item.variableName === variableName)
        ? prevArray.map((item) =>
            item.variableName === variableName
              ? {
                  ...item,
                  value,
                  unFormattedValue,
                }
              : item
          )
        : [...prevArray, { variableName, value, unFormattedValue }]
    );
  };

  const handleApply = async (data) => {
    try {
      setLoading(true);
      setActionType('APPLY');
      await triggerVariableOverrideUpdate(overridesValues);
      await triggerSettingsOverride(data);
      onCancel();
    } catch (ex) {
    } finally {
      setActionType('');
      setLoading(false);
    }
  };

  const handleReset = async () => {
    try {
      setLoading(true);
      setActionType('RESET');
      const triggerDefaultVariables = trigger?.defaultValues?.body?.variableOverrides?.variables;
      await triggerVariableOverrideUpdate(triggerDefaultVariables, true);
      await triggerSettingsOverride(trigger?.defaultValues, true);
      onCancel();
    } catch (ex) {
    } finally {
      setActionType('');
      setLoading(false);
    }
  };

  /**
   * Update local data
   * Updates a trigger item across keys by its ID with specified changes.
   * @param {string} id - The ID of the item to update.
   * @param {object} changes - The changes to apply to the item.
   * @param {boolean} [isReset] - The changes to apply to the item.
   */
  const updateTriggerItemByIdAcrossKeys = (id, changes, isReset = false) => {
    setTriggers((prevTriggers) => {
      return prevTriggers.map((item) => {
        if (item.id === id) {
          const newValues = {
            cooldown: createNewValue(changes?.cooldown, item?.cooldown),
            preBuffer: createNewValue(changes.preBuffer, item?.preBuffer),
            postBuffer: createNewValue(changes.postBuffer, item?.postBuffer),
            cooldownTimer: createNewValue(changes.cooldownTimer, item?.cooldownTimer),
            mediaType: createNewValue(changes.mediaType, item?.mediaType),
            chime: createNewValue(changes.chime, item?.chime),
            uploadStrategy: createNewValue(changes.uploadStrategy, item?.uploadStrategy),
            enableTts: createNewValue(changes.enableTts, item?.enableTts),
            variables: getUpdatedVariables(item, overridesValues, isReset),
          };
          return { ...trigger, ...newValues };
        }
        return item;
      });
    });
  };

  /**
   * Creates a new value object based on the changes and existing value.
   * @param {string} newValue - The new value.
   * @param {object} item - The existing value object.
   * @returns {object} - The new value object.
   */
  function createNewValue(newValue, item) {
    const existingValue = item?.value;
    const prevTargetId = item?.targetId;
    const prevTargetLevel = item?.targetLevel;
    const prevTargetName = item?.targetName;
    return {
      value: newValue,
      targetLevel: newValue === existingValue ? prevTargetId : selectionType?.type,
      targetId: newValue === existingValue ? prevTargetLevel : selectionType?.value,
      targetName: newValue === existingValue ? prevTargetName : null,
    };
  }

  /**
   * Updates variables in the trigger item.
   * @param {object} item - The trigger item.
   * @param {object[]} overridesValues - The array of override values.
   * @param {boolean} [isReset] - The updated variables array.
   * @returns {object[]} - The updated variables array.
   */
  function getUpdatedVariables(item, overridesValues, isReset = false) {
    return (
      item?.variables?.map((variable) => {
        const override = overridesValues?.find((ov) => ov.variableName === variable?.key);
        return {
          ...variable,
          value: isReset
            ? override?.default
            : override?.unFormattedValue || override?.value || variable?.value,
        };
      }) || []
    );
  }

  const inheritance = (type) => {
    let typeData = {};

    if (type === 'sensitivity') {
      typeData = {
        targetLevel: trigger?.variables?.at(0)?.targetLevel,
      };
    } else if (type === 'tts') {
      typeData = {
        targetLevel: 'TRIGGER',
      };
    } else {
      typeData = trigger ? trigger[type] : {};
    }

    let targetName;
    if (typeData?.targetLevel === 'TRIGGER' || typeData?.targetLevel === 'DEFAULT') {
      targetName = trigger?.name;
    } else if (typeData?.targetLevel === 'TRIGGER_CATEGORY') {
      const categoryName = triggerCategories?.find(
        (category) => category.id === trigger?.triggerCategoryId
      )?.name;
      targetName = categoryName || typeData?.targetName;
    } else {
      targetName = capitalize(startCase(typeData?.targetName)) || trigger?.name;
    }

    return (
      <Box
        display="flex"
        sx={{
          '& .MuiTypography-root': {
            fontSize: '0.70rem',
          },
        }}
      >
        <Box display="grid" textAlign="center" alignItems="center">
          <Typography variant="subtitle2" textAlign="left">
            Inherited from
          </Typography>
          <Box fontWeight={500} textAlign="left">
            <LongTextShortener
              text={targetName ? `${targetName}` : ''}
              length={50}
              sx={{ fontWeight: 500 }}
            />
          </Box>
        </Box>
      </Box>
    );
  };

  return (
    <Box sx={{ ...selectStyle }}>
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(handleApply)} autoComplete="off" noValidate>
          <Box
            display="flex"
            flexDirection="column"
            gap={1.5}
            sx={{
              '& .MuiMenuItem-root': {
                fontSize: '12px !important',
              },
              '& .MuiFormHelperText-root': {
                marginLeft: '0px !important',
                // color: '#4D6076 !important',
                opacity: '0.7',
                fontWeight: 400,
              },
            }}
          >
            {/* Chime Settings */}
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12} md={2}>
                <Typography variant="subtitle2">Chime</Typography>
              </Grid>
              <Grid item xs={12} md={7}>
                <Box display="flex" gap="10px" height="48px">
                  {TRIGGER_SENSITIVITY_CHIME_OPTIONS?.map((item, index) => (
                    <Button
                      key={index}
                      variant={chimeValue === item?.value ? 'contained' : 'outlined'}
                      sx={{
                        width: '50%',
                        borderRadius: '10px',
                        backgroundColor: item?.value !== chimeValue ? '#EFF4FA' : '-moz-initial',
                      }}
                      onClick={() => {
                        setChimeValue(chimeValue === item?.value ? 'NONE' : item?.value);
                      }}
                    >
                      <Box
                        display={'inline-flex'}
                        gap={'5px'}
                        fontSize="0.80rem"
                        alignItems="center"
                      >
                        <BoxImage
                          src={TRIGGER_CHIME_ICON}
                          size="16px"
                          sx={{
                            filter:
                              chimeValue === item?.value ? 'brightness(0) invert(1)' : 'white',
                          }}
                        />
                        {item?.label}
                      </Box>
                    </Button>
                  ))}
                </Box>
              </Grid>
              <Grid item xs={12} md={3}>
                {inheritance('chime')}
              </Grid>
            </Grid>

            {/* Media Settings */}
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12} md={2}>
                <Typography variant="subtitle2">Media</Typography>
              </Grid>
              <Grid item xs={12} md={7}>
                <Box display="flex" gap="10px" height="48px">
                  {TRIGGER_SENSITIVITY_MEDIA_TYPES?.map((item) => (
                    <Button
                      variant={mediaTypeValues?.includes(item?.value) ? 'contained' : 'outlined'}
                      sx={{
                        width: '50%',
                        borderRadius: '10px',
                        backgroundColor: !mediaTypeValues?.includes(item?.value)
                          ? '#EFF4FA'
                          : '-moz-initial',
                      }}
                      onClick={() => {
                        if (mediaTypeValues.includes(item?.value)) {
                          setMediaTypeValues((prevValues) =>
                            prevValues.filter((i) => i !== item?.value)
                          );
                        } else {
                          setMediaTypeValues((prevValues) => [...prevValues, item?.value]);
                        }
                      }}
                    >
                      <Box
                        display={'inline-flex'}
                        gap={'5px'}
                        fontSize="0.80rem"
                        alignItems="center"
                      >
                        <BoxImage
                          src={
                            item?.value === 'VIDEO'
                              ? AI_CONTAINER_CAMERA_VIDEO_ICON
                              : TRIGGER_SNAPSHOT_ICON
                          }
                          size="16px"
                          sx={{
                            filter: mediaTypeValues?.includes(item?.value)
                              ? 'brightness(0) invert(1)'
                              : 'white',
                          }}
                        />
                        {item?.label}
                      </Box>
                    </Button>
                  ))}
                </Box>
              </Grid>
              <Grid item xs={12} md={3}>
                {inheritance('mediaType')}
              </Grid>
            </Grid>

            {/* Recordings Upload Type Settings */}
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12} md={2}>
                <Typography variant="subtitle2">Recording</Typography>
              </Grid>
              <Grid item xs={12} md={7}>
                <Box display="flex" gap="10px" height="48px">
                  {TRIGGER_SENSITIVITY_UPLOAD_TYPE_OPTIONS?.map((item) => (
                    <Button
                      variant={item?.value === uploadTypeValue ? 'contained' : 'outlined'}
                      sx={{
                        width: '50%',
                        borderRadius: '10px',
                        backgroundColor:
                          item?.value !== uploadTypeValue ? '#EFF4FA' : '-moz-initial',
                      }}
                      onClick={() => {
                        setUploadTypeValue(item?.value);
                      }}
                    >
                      <Box
                        display={'inline-flex'}
                        gap={'5px'}
                        fontSize="0.80rem"
                        alignItems="center"
                      >
                        <BoxImage
                          src={
                            item?.value === 'SD_CARD'
                              ? TRIGGER_ON_CAMERA_ICON
                              : TRIGGER_ON_CLOUD_ICON
                          }
                          size="16px"
                          sx={{
                            filter:
                              item?.value === uploadTypeValue ? 'brightness(0) invert(1)' : 'white',
                          }}
                        />
                        {item?.label}
                      </Box>
                    </Button>
                  ))}
                </Box>
              </Grid>
              <Grid item xs={12} md={3}>
                {inheritance('uploadStrategy')}
              </Grid>
            </Grid>

            {/* Pre Buffer Settings */}
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12} md={2}>
                <Typography variant="subtitle2">Buffer (Pre)</Typography>
              </Grid>
              <Grid item xs={12} md={7}>
                <CustomInput
                  name="preBuffer"
                  label=""
                  type="number"
                  placeholder="Enter pre buffer"
                  InputProps={{
                    endAdornment: (
                      <Typography sx={{ fontSize: '12px', color: '#8492a3' }}>seconds</Typography>
                    ),
                  }}
                  TextFieldProps={{
                    helperText: `Default Buffer (Pre) ${trigger?.defaultValues?.preBuffer?.value} sec`,
                  }}
                />
              </Grid>
              <Grid item xs={12} md={3} marginTop={'-25px'}>
                {inheritance('preBuffer')}
              </Grid>
            </Grid>

            {/* Post Buffer Settings */}
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12} md={2}>
                <Typography variant="subtitle2">Buffer (Post)</Typography>
              </Grid>
              <Grid item xs={12} md={7}>
                <CustomInput
                  name="postBuffer"
                  label=""
                  type="number"
                  placeholder="Enter post buffer"
                  InputProps={{
                    endAdornment: (
                      <Typography sx={{ fontSize: '12px', color: '#8492a3' }}>seconds</Typography>
                    ),
                  }}
                  TextFieldProps={{
                    helperText: `Default Buffer (Post) ${trigger?.defaultValues?.postBuffer?.value} sec`,
                  }}
                />
              </Grid>
              <Grid item xs={12} md={3} marginTop={'-25px'}>
                {inheritance('postBuffer')}
              </Grid>
            </Grid>

            {/* CoolDown Settings */}
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12} md={2}>
                <Typography variant="subtitle2">Cooldown Time</Typography>
              </Grid>
              <Grid item xs={12} md={7}>
                <CustomInput
                  name="cooldownTimer"
                  label=""
                  type="number"
                  placeholder="Enter cooldown time"
                  InputProps={{
                    readOnly: coolDownUnit === 'TURN_OFF',
                    sx: { color: coolDownUnit === 'TURN_OFF' ? 'white' : '-moz-initial' },
                    endAdornment: (
                      <InputAdornment position="end">
                        <Select
                          size="small"
                          value={coolDownUnit}
                          onChange={(e) => {
                            const newUnit = e.target.value;

                            if (['MINUTES', 'SECONDS'].includes(newUnit)) {
                              setIsEnableCoolDown(true);
                            } else {
                              setIsEnableCoolDown(false);
                            }

                            if (newUnit !== coolDownUnit) {
                              const collDownValue = getValues('cooldownTimer');
                              if (coolDownUnit === 'MINUTES' && newUnit === 'SECONDS') {
                                setValue('cooldownTimer', collDownValue * 60);
                              } else if (coolDownUnit === 'SECONDS' && newUnit === 'MINUTES') {
                                setValue('cooldownTimer', collDownValue / 60);
                              }
                            }
                            setCoolDownUnit(newUnit);
                          }}
                          autoWidth
                        >
                          {COOLDOWN_MENU_ITEMS?.map((item) => (
                            <MenuItem
                              key={item?.value}
                              value={item?.value}
                              sx={{ fontSize: '12px' }}
                            >
                              {item?.value === 'TURN_OFF' && coolDownUnit === 'TURN_OFF'
                                ? 'Turned Off'
                                : item?.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </InputAdornment>
                    ),
                  }}
                  TextFieldProps={{
                    helperText: `Default Cooldown Time ${
                      trigger?.defaultValues?.cooldownTimer?.value / 1000
                    } sec`,
                  }}
                />
              </Grid>
              <Grid item xs={12} md={3} marginTop={'-25px'}>
                {inheritance('cooldownTimer')}
              </Grid>
            </Grid>

            {/* TTS Settings */}
            <Grid container spacing={2} alignItems="center" className="tts">
              <Grid item xs={12} md={2}>
                <Typography variant="subtitle2">Voice Alert</Typography>
              </Grid>
              <Grid item xs={12} md={7}>
                <CustomInput
                  name="tts"
                  label=""
                  placeholder="Enter voice alert"
                  InputProps={{
                    disabled: ttsLanguage !== 'TURN_OFF',
                    readOnly: ttsLanguage === 'TURN_OFF',
                    sx: { color: ttsLanguage === 'TURN_OFF' ? 'white' : '-moz-initial' },
                    endAdornment: (
                      <InputAdornment position="end">
                        <Select
                          size="small"
                          value={ttsLanguage}
                          onChange={(e) => {
                            setTtsLanguage(e.target.value);
                          }}
                          autoWidth
                        >
                          {LANGUAGE_MENU_ITEMS?.map((item) => (
                            <MenuItem
                              key={item?.value}
                              value={item?.value}
                              sx={{ fontSize: '12px' }}
                            >
                              {item?.value === 'TURN_OFF' && ttsLanguage === 'TURN_OFF'
                                ? 'Turned Off'
                                : item?.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                {inheritance('tts')}
              </Grid>
            </Grid>

            {/* Sensitivity Settings */}
            {trigger.variables?.length ? (
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={12} md={2}>
                  <Typography variant="subtitle2">Sensitivity</Typography>
                </Grid>
                <Grid item xs={12} md={7}>
                  <Box>
                    {trigger?.variables?.length > 0 && (
                      <Box pt={3} sx={{ '& .MuiTypography-root': { fontSize: '0.75rem' } }}>
                        {[21, 22].includes(trigger?.triggerCategoryId) ? (
                          <Box>
                            <LineChart
                              variables={trigger?.variables}
                              onChange={(variableName, value) => {
                                triggerVariableOverride(variableName, value?.toFixed(1));
                              }}
                            />
                            <Typography sx={{ opacity: '0.5' }}>
                              Default Sensitivity [
                              {trigger?.variables
                                ?.map((item) => `${item.default} ${item?.unit}`)
                                ?.join(', ')}
                              ]
                            </Typography>
                          </Box>
                        ) : (
                          trigger?.variables?.slice(0, 1)?.map((variable, indexChild) => (
                            <Box key={indexChild}>
                              <CustomSlider
                                name="sensitivity"
                                label=""
                                step={trigger?.triggerCategoryId === 2 ? 1 : 0.01}
                                min={variable?.minValue}
                                max={variable?.maxValue}
                                height={5}
                                defaultVal={Number(variable?.value || variable?.default)}
                                SliderProps={{
                                  onChange: (event, value) => {
                                    value = Number(value);
                                    const result = convertValueByUnit(
                                      value,
                                      variable?.unit,
                                      variable?.originalUnit
                                    );
                                    triggerVariableOverride(variable?.key, result, value);
                                  },
                                  disabled: isEmpty(variable),
                                  marks: getSliderMarks(
                                    variable?.minValue,
                                    variable?.maxValue,
                                    variable?.stepSize,
                                    variable?.unit
                                  ),
                                }}
                                postText={variable?.unit + ' '}
                              />
                              <Typography sx={{ opacity: '0.5' }}>
                                Default Sensitivity {variable?.default} {variable?.unit}
                              </Typography>
                            </Box>
                          ))
                        )}
                      </Box>
                    )}
                  </Box>
                </Grid>
                <Grid item xs={12} md={3}>
                  {inheritance('sensitivity')}
                </Grid>
              </Grid>
            ) : null}
          </Box>

          <Box display="flex" justifyContent="flex-end" pt={5} gap={0.5} pb={10} pr={4}>
            <Button onClick={onCancel} size="small" disabled={loading}>
              Cancel
            </Button>

            <TriggerResetButton loading={loading} actionType={actionType} onReset={handleReset} />

            <Button
              autoFocus
              variant="contained"
              size="small"
              type="submit"
              disabled={loading}
              startIcon={
                loading &&
                actionType === 'APPLY' && <CircularProgress size={20} sx={{ color: 'white' }} />
              }
            >
              Apply
            </Button>
          </Box>
        </form>
      </FormProvider>
    </Box>
  );
}
