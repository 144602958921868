import { generateUniqueKey } from '@/utils/table';
import { FormLabel, TextField } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import FormControl from '@mui/material/FormControl';
import { isFunction } from 'lodash';
import { Controller, useFormContext } from 'react-hook-form';

/**
 * @param {any} item
 */
function getDefaultItemId(item) {
  if (typeof item === 'boolean' || typeof item === 'number' || typeof item === 'string') {
    return item + '';
  }
  return item?.value || item?.id || generateUniqueKey(Object.values(item));
}

/**
 * @param {any} item
 */
function getDefaultItemLabel(item) {
  return '' + (item?.label ?? item?.name ?? JSON.stringify(item));
}

/**
 * @template T
 * @typedef {object} OptionItem<T>
 * @property {string} key
 * @property {string} label
 * @property {T} value
 */

/**
 * @template T
 * @typedef {object} CustomSelectProps<T>
 * @property {string} name
 * @property {string} [label]
 * @property {string} [placeholder]
 * @property {boolean} [disabled]
 * @property {boolean} [loading]
 * @property {Array<T>} [options]
 * @property {(option: T) => any} [getValue]
 * @property {(keyof T)|((option: T) => string)} [getKey]
 * @property {(keyof T)|((option: T) => string)} [getLabel]
 * @property {(option: T, value: T) => string} [isOptionEqualToValue]
 * @property {import('react-hook-form').ControllerProps<T>['rules']} [rules]
 * @property {Partial<import('@mui/material').AutocompleteProps<OptionItem<T>>>} [AutocompleteProps]
 */

/**
 * @template T
 * @param {CustomSelectProps<T> & Partial<import('@mui/material').FormControlProps>} props
 */
export function CustomSelect(props) {
  const {
    name,
    label,
    rules,
    options: items,
    placeholder,
    loading = false,
    disabled = false,
    AutocompleteProps,
    getValue = (item) => item,
    getKey: itemKey = getDefaultItemId,
    getLabel: itemLabel = getDefaultItemLabel,
    ...extraProps
  } = props;

  const { control } = useFormContext();

  /** @param {T} item */
  const getKey = (item) => {
    if (!item) return '';
    if (isFunction(itemKey)) return itemKey(item);
    return '' + item[itemKey];
  };

  /** @param {T} item */
  const getLabel = (item) => {
    if (!item) return '';
    if (isFunction(itemLabel)) return itemLabel(item);
    return '' + item[itemLabel];
  };

  return (
    <FormControl
      fullWidth
      size="medium"
      variant="outlined"
      margin="dense"
      {...extraProps}
      sx={{
        mt: 0,
        ...extraProps?.sx,
      }}
    >
      {Boolean(label) && (
        <FormLabel color="secondary" sx={{ mb: '4px', fontWeight: 500, fontSize: '0.875rem' }}>
          {label}
        </FormLabel>
      )}
      <Controller
        name={name}
        rules={rules}
        control={control}
        render={({ field, fieldState: { error } }) => (
          <Autocomplete
            disableClearable
            disabled={disabled}
            handleHomeEndKeys
            loading={loading}
            options={items || []}
            value={(items || [])?.find((x) => getKey(x) === field.value) || field.value || ''}
            onChange={(e, value) => field.onChange(getValue(value))}
            getOptionLabel={getLabel}
            isOptionEqualToValue={(option, value) => {
              return getKey(option) === getKey(value);
            }}
            renderOption={(props, option) => (
              <li {...props} key={getKey(option)}>
                {getLabel(option)}
              </li>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                inputRef={field.ref}
                error={Boolean(error)}
                helperText={error?.message}
                placeholder={placeholder}
                onBlur={field.onBlur}
              />
            )}
            {...AutocompleteProps}
          />
        )}
      />
    </FormControl>
  );
}
