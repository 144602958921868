import api from '@/api';
import { selectSecretToken, selectTenantId } from '@/store/auth';
import { CenterBox } from '@/web/@components/CenterBox';
import { CameraDetailsContext } from '@/web/@layouts/CameraDetailsLayout';
import { ArrowDropDown } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  CircularProgress,
} from '@mui/material';
import Typography from '@mui/material/Typography';
import { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { CameraAudioInputInfo } from '../../@components/CameraAudioInputInfo';
import { CameraSensorInfo } from '../../@components/CameraSensorInfo';
import { CameraVideoImagerInputs } from '../../@components/CameraVideoImagerInputs';
import { SENSOR_TAB_ITEMS } from '../../@assets/sensors';
import { CameraDigitalIo } from '../../@components/CameraDigitalIo';

export function CameraSensorPage() {
  const { capabilities, camera } = useContext(CameraDetailsContext);
  const secretToken = useSelector(selectSecretToken);
  const tenantId = useSelector(selectTenantId);

  const [loading, setLoading] = useState(true);
  const [videoInputs, setVideoInputs] = useState([]);
  const [sensorInputs, setSensorInputs] = useState([]);
  const [audioInputs, setAudioInputs] = useState([]);
  const [expanded, setExpanded] = useState('video');

  useEffect(() => {
    const request = api.ac.v1.tenant.product.$get({
      params: {
        productId: camera.productId,
        tenantId,
        secretToken,
      },
    });
    request
      .process()
      .then((res) => {
        //sensors
        const sensors = res.inputs.filter((item) => item?.inputType === 'SENSOR');
        setSensorInputs(sensors);

        //audio
        const audio = res.inputs.filter((item) => item?.inputType === 'AUDIO');
        setAudioInputs(audio);

        const inputs = capabilities?.Camera ? JSON.parse(capabilities.Camera) : [];
        const newArray = inputs?.map((item) => ({
          inputName: res.inputs?.find((inputs) => item?.inputSource === inputs.inputCode)
            ?.inputName,
          ...item,
        }));
        setVideoInputs(newArray);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [tenantId, secretToken, capabilities, camera]);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  if (loading) {
    return (
      <CenterBox>
        <CircularProgress></CircularProgress>
      </CenterBox>
    );
  }

  return (
    <Box
      p={2.5}
      px={3}
      mt={2}
      sx={{
        '& .MuiPaper-elevation': {
          border: '1px solid #c5d9f0',
          borderRadius: '5px',
        },
        'overflowY': 'auto',
      }}
      height="100%"
    >
      {SENSOR_TAB_ITEMS.map((item) => (
        <Accordion
          key={item.key}
          disableGutters
          elevation={0}
          square
          TransitionProps={{ unmountOnExit: false, mountOnEnter: true }}
          expanded={expanded === item.key}
          onChange={handleChange(item.key)}
        >
          <AccordionSummary
            expandIcon={<ArrowDropDown fontSize="large" />}
            aria-controls={`${item.key}-content`}
            id={`${item.key}-header`}
          >
            <Typography>{item.label}</Typography>
          </AccordionSummary>
          <AccordionDetails key={item.key}>
            <Box mt={2}>
              {item.key === 'video' ? (
                <CameraVideoImagerInputs videoInputs={videoInputs} />
              ) : item.key === 'sensor' ? (
                <CameraSensorInfo
                  sensorInputs={sensorInputs}
                  adrStatus={capabilities?.adrStatus || 'Unknown'}
                />
              ) : item.key === 'audio' ? (
                <CameraAudioInputInfo audioInputs={audioInputs} />
              ) : item.key === 'digitalIO' ? (
                <CameraDigitalIo />
              ) : null}
            </Box>
          </AccordionDetails>
        </Accordion>
      ))}
    </Box>
  );
}
