import { carNames } from '@/utils/group-settings';
import { FROM_TYPES, GROUP_SETTINGS_CATEGORIES } from '../constants';
import {
  logLevelOptions,
  sdCardFormattingOptions,
  switchOptions,
  tripDecisionSources,
  usbModeOptions,
} from '../options';

/** @type {Array<GroupSettingsItems>} */
export const deviceSettings = [
  {
    title: 'Device Settings',
    key: GROUP_SETTINGS_CATEGORIES.DEVICE,
    children: [
      {
        label: 'TTS',
        key: 'ttsEnabled',
        type: FROM_TYPES.SWITCH,
      },
      {
        label: 'Chime',
        key: 'chimeEnabled',
        type: FROM_TYPES.SWITCH,
      },
      {
        label: 'Volume',
        key: 'deviceVolume',
        type: FROM_TYPES.SLIDER,
        postText: '%',
        min: 0,
        max: 100,
      },
      {
        label: 'Log Level',
        key: 'logLevel',
        type: FROM_TYPES.SELECT,
        options: logLevelOptions,
      },
      {
        label: 'Allocated Log Space',
        key: 'allocatedLogSpace',
        type: FROM_TYPES.INPUT,
        postText: 'MB',
        extra: 'parseInt',
        min: 0,
        inputType: 'number',
      },
      {
        label: 'Car Name',
        key: 'carName',
        type: FROM_TYPES.SELECT,
        options: carNames,
        // isHidden: (data, isDefault) => isDefault,
      },
      {
        label: 'Trip Decision Source',
        key: 'tripTriggersBy',
        type: FROM_TYPES.SELECT,
        options: tripDecisionSources,
      },
      {
        label: 'USB Management',
        key: 'usbManagement',
        children: [
          {
            label: 'USB mode',
            key: 'usbMode',
            type: FROM_TYPES.SELECT,
            options: usbModeOptions,
          },
          {
            label: 'ADB',
            key: 'usbAdb',
            type: FROM_TYPES.SELECT,
            options: switchOptions,
          },
          {
            label: 'Host Mode',
            key: 'usbHostMode',
            type: FROM_TYPES.SELECT,
            options: switchOptions,
          },
          {
            label: 'File Transfer',
            key: 'usbFileTransfer',
            type: FROM_TYPES.SWITCH,
            isDisabled: (watch) => watch('usbMode') !== 'FILE_TRANSFER',
          },
        ],
      },
      {
        label: 'SD-Card Management',
        key: 'sdCardManagement',
        children: [
          {
            label: 'Automatic SD-card formatting on insertion',
            key: 'sdCardFormatOption',
            type: FROM_TYPES.SELECT,
            options: sdCardFormattingOptions,
          },
        ],
      },
      {
        label: 'Digital I/O',
        key: 'digitalIO',
        underline: true,
        children: [
          {
            label: 'Cover Detection',
            key: 'enableCoverDetect',
            type: FROM_TYPES.SWITCH,
          },
          {},
          {
            label: 'Digital Input (Brown)',
            key: 'enableGPIO0',
            type: FROM_TYPES.SWITCH,
          },
          {
            label: 'Digital Input (Brown) Change Detection',
            key: 'enableGPIO0ChangeDetectOnFallingEdge',
            type: FROM_TYPES.SWITCH,
            isDisabled: (watch) => watch('enableGPIO0') === false,
          },
          {
            label: 'Digital Input (Yellow)',
            key: 'enableGPIO1',
            type: FROM_TYPES.SWITCH,
          },
          {
            label: 'Digital Input (Yellow) Change Detection',
            key: 'enableGPIO1ChangeDetectOnFallingEdge',
            type: FROM_TYPES.SWITCH,
            isDisabled: (watch) => watch('enableGPIO1') === false,
          },
          {
            label: 'Digital Input (Purple)',
            key: 'enableGPIO2',
            type: FROM_TYPES.SWITCH,
          },
          {
            label: 'Digital Input (Purple) Change Detection',
            key: 'enableGPIO2ChangeDetectOnFallingEdge',
            type: FROM_TYPES.SWITCH,
            isDisabled: (watch) => watch('enableGPIO2') === false,
          },
          {
            label: 'Digital Output (Gray)',
            key: 'enableGPIO3',
            type: FROM_TYPES.SWITCH,
          },
          {
            label: 'Digital Output (Gray) Change Detection',
            key: 'enableGPIO3ChangeDetectOnFallingEdge',
            type: FROM_TYPES.SWITCH,
            isDisabled: (watch) => watch('enableGPIO3') === false,
          },
          {
            label: 'Digital Output (Gray) Set High',
            key: 'setGPIO3',
            type: FROM_TYPES.SWITCH,
            isDisabled: (watch) => watch('enableGPIO3') === false,
          },
        ],
      },
      {
        label: 'Imager State',
        key: 'deviceImagerState',
        underline: true,
        children: [
          {
            label: ' Video ',
            key: 'deviceVideoImagerState',
            secondaryUnderline: true,
            children: [],
          },
          {
            label: 'Audio',
            key: 'deviceAudioImagerState',
            secondaryUnderline: true,
            children: [],
          },
        ],
      },
      {
        label: 'Audio Pipeline',
        key: 'enableAudioPipeline',
        type: FROM_TYPES.SWITCH,
        isHidden: (watch) => true,
      },
    ],
  },
];
