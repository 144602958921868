import api from '@/api';
import { selectSecretToken } from '@/store/auth';
import { endOfTheDay, startOfTheDay } from '@/utils/datetime';
import { CenterBox } from '@/web/@components/CenterBox';
import { ArrowDropDown } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  CircularProgress,
  Divider,
  Typography,
} from '@mui/material';
import { Fragment, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { ScoringFilterContext } from '../../@context/ScoringFilterContext';
import { TriggerReportDetailsGraph } from '../TriggerReportGraph';
import { TriggerStreak } from '../TriggerStreak';

/**
 * @typedef {object} TriggerDetailsByTypeProps
 * @property {'DRIVER' | 'ENDPOINT'} type
 * @property {string} entityId
 * @property {number} personaId
 */

/**
 * @param {TriggerDetailsByTypeProps} props
 */
export function TriggerDetailsByType(props) {
  const { type, entityId, personaId } = props;

  const secretToken = useSelector(selectSecretToken);
  const { filteredData } = useContext(ScoringFilterContext);

  const [expanded, setExpanded] = useState('');
  const [triggers, setTriggers] = useState([]);
  const [personaTriggerCategories, setPersonaTriggerCategories] = useState([]);
  const [loading, setLoading] = useState(true);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : '');
  };

  const getEndpointTriggers = useCallback(async () => {
    try {
      const request = api.ac.v5.events.occurred.endpoint
        .$endpointId(Number(entityId))
        .trigger.$get({
          headers: {
            Authorization: secretToken,
          },
          params: {
            fromTimestamp: startOfTheDay(filteredData?.dateRange?.from),
            toTimestamp: endOfTheDay(filteredData?.dateRange?.to),
          },
        });
      const result = await request?.process();
      setTriggers(result?.list);
      setLoading(false);
    } catch (ex) {}
  }, [entityId, filteredData?.dateRange?.from, filteredData?.dateRange?.to, secretToken]);

  const getDriverTriggers = useCallback(async () => {
    try {
      const request = api.ac.v5.events.occurred.driver.$driverId(Number(entityId)).trigger.$get({
        headers: {
          Authorization: secretToken,
        },
        params: {
          fromTimestamp: startOfTheDay(filteredData?.dateRange?.from),
          toTimestamp: endOfTheDay(filteredData?.dateRange?.to),
        },
      });
      const result = await request?.process();
      setTriggers(result?.list);
      setLoading(false);
    } catch (ex) {}
  }, [entityId, filteredData?.dateRange?.from, filteredData?.dateRange?.to, secretToken]);

  const getTriggerCategoryByPersona = useCallback(async () => {
    try {
      const request = api.ac.v5.persona.$personaId(personaId).trigger.categories.$get({
        headers: {
          Authorization: secretToken,
        },
      });
      const result = await request?.process();
      setPersonaTriggerCategories(result?.map((item) => item?.id));
      setLoading(false);
    } catch (ex) {
      setLoading(false);
    }
  }, [personaId, secretToken]);

  const getFilteredTriggers = useMemo(
    () => triggers?.filter((item) => personaTriggerCategories?.includes(item?.triggerCategoryId)),
    [triggers, personaTriggerCategories]
  );

  useEffect(() => {
    setLoading(true);
    setExpanded('');
    getTriggerCategoryByPersona();
  }, [getTriggerCategoryByPersona]);

  useEffect(() => {
    const aborter = new AbortController();
    if (!type) return;
    setLoading(true);
    setTriggers([]);
    setExpanded('');
    if (type === 'DRIVER') {
      getDriverTriggers();
    } else if (type === 'ENDPOINT') {
      getEndpointTriggers();
    }
    return () => aborter?.abort();
  }, [type, getEndpointTriggers, getDriverTriggers]);

  return (
    <Box mt={5}>
      {loading ? (
        <CenterBox style={{ height: '400px' }}>
          <CircularProgress />
        </CenterBox>
      ) : getFilteredTriggers?.length > 0 ? (
        <Box mb={12}>
          <Divider />
          {getFilteredTriggers?.map((item, index) => (
            <Fragment key={index}>
              <Accordion
                sx={{
                  'border': 'none',
                  '& .MuiAccordionSummary-root': {
                    alignItems: 'stretch',
                  },
                  '& .MuiAccordionDetails-root': {
                    border: 'none',
                  },
                  '& .MuiAccordion-root:before': {
                    backgroundColor: 'none !important',
                  },
                  '& .MuiPaper-root:before': {
                    backgroundColor: 'none !important',
                  },
                  '& .MuiSvgIcon-root': {
                    marginTop: '7px',
                  },
                  '& .MuiAccordionSummary-content': {
                    width: '100%',
                  },
                }}
                disableGutters
                elevation={0}
                square
                disabled={loading}
                TransitionProps={{ unmountOnExit: true, mountOnEnter: true }}
                expanded={expanded === item?.triggerCategoryId}
                onChange={handleChange(item?.triggerCategoryId)}
              >
                <AccordionSummary expandIcon={<ArrowDropDown fontSize="large" />}>
                  <Box sx={{ width: '100% !important' }}>
                    <Box display="flex" justifyContent="space-between">
                      <Typography
                        sx={{
                          color: '#0B2547',
                          opacity: '0.68',
                          fontSize: '1rem',
                          fontWeight: 600,
                        }}
                      >
                        {item?.triggerCategoryName}
                      </Typography>
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        gap={3}
                        alignItems="center"
                      >
                        {expanded === item?.triggerCategoryId && (
                          <Typography
                            sx={{
                              color: '#4D6076',
                              opacity: '0.5',
                              fontSize: '0.75rem',
                              fontWeight: 500,
                            }}
                          >
                            Average {item?.triggerCategoryName}
                          </Typography>
                        )}
                        <Typography
                          sx={{ color: '#0B2547', fontSize: '0.875rem', fontWeight: 500 }}
                        >
                          {item?.averageCount?.toFixed(2)} avg
                        </Typography>
                      </Box>
                    </Box>

                    <Box mt={2}>
                      <TriggerStreak
                        value={item?.averageCount}
                        min={item?.minCount}
                        max={item?.maxCount}
                      />
                    </Box>
                  </Box>
                </AccordionSummary>
                <AccordionDetails sx={{ minHeight: '200px', mt: 2, py: 0, px: 2 }}>
                  <TriggerReportDetailsGraph item={item} />
                </AccordionDetails>
              </Accordion>
              <Divider />
            </Fragment>
          ))}
        </Box>
      ) : null}
    </Box>
  );
}
