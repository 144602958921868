import { GROUP_SETTINGS_CATEGORIES } from '@/assets/schemes/constants';
import { VIDEO_RECORDING_DEFAULT_RESOLUTIONS } from '@/assets/schemes/options';
import { isEmpty, isUndefined } from 'lodash';
import { trimSpaceFromString } from '.';
import {
  AUDIO_PIPELINE_SETTINGS,
  BOOLEAN_OPTIONS,
  FIRST_LEVEL_ITEMS,
  IMAGER_STATE_SETTINGS,
  SCHEME_FIRMWARE_CATEGORIES,
  SCHEME_PARENT_ITEMS,
  SCHEME_SETTINGS_SUBCATEGORIES,
  VIDEO_PIPELINE_SETTINGS,
} from '../assets';
import { groupSettingsByCategory } from './settings';

/**
 * Transforms and formats data from various sources, ensuring uniqueness.
 * @param {AiContainerDto} aiContainer - Data related to AI containers.
 * @param {Array<CompositeTriggerDto>} triggers - Data related to triggers.
 * @param {Array<FirmwareInfo>} firmwares - Data related to firmwares.
 * @param {Array} settings - Data related to settings.
 * @param {Array} inputs - Data related to settings.
 * @returns {Array} Formatted and unique array of transformed data.
 */
export const transformAndFormatData = (aiContainer, triggers, firmwares, settings, inputs) => {
  const containers = transformAiContainer(aiContainer);
  const transformedTriggers = transformTriggers(triggers);
  const transformedFirmwares = transformFirmwares(firmwares);
  const transformedSettings = transformSettings(settings, inputs);
  // Combine all transformed arrays into a single array
  const allTransformedItems = [
    ...containers,
    ...transformedTriggers,
    ...transformedFirmwares,
    ...transformedSettings,
  ];
  // Use a Set to remove duplicate items based on JSON.stringify
  const uniqueItems = new Set(allTransformedItems.map((item) => JSON?.stringify(item)));
  // Convert Set back to an array
  const result = Array.from(uniqueItems, (item) => JSON?.parse(item));
  return result;
};

/**
 * Transforms a deployed AI container into a standardized format.
 * @param {AiContainerDto} deployedContainer - The deployed AI container to be transformed.
 * @returns {Array} - An array containing the transformed AI container.
 */
export function transformAiContainer(deployedContainer) {
  if (!deployedContainer) return [];

  const selectedItem = FIRST_LEVEL_ITEMS.find(
    (item) => item.value === SCHEME_PARENT_ITEMS.AI_CONTAINERS
  );

  return selectedItem
    ? [
        {
          item: [selectedItem],
          value: {
            label: `${deployedContainer?.name} v${deployedContainer?.versionNumber}`,
            value: deployedContainer.containerId,
            id: deployedContainer.containerId,
            ...deployedContainer,
          },
        },
      ]
    : [];
}

/**
 * Transforms an array of deployed triggers into a standardized format.
 * @param {Array<CompositeTriggerDto>} deployedTriggers - The array of deployed triggers to be transformed.
 * @returns {Array} - An array containing the transformed triggers.
 */
export function transformTriggers(deployedTriggers) {
  if (!deployedTriggers?.length) return [];

  const selectedItem = FIRST_LEVEL_ITEMS.find(
    (item) => item.value === SCHEME_PARENT_ITEMS.TRIGGERS
  );

  return deployedTriggers.map((trigger) => ({
    item: [selectedItem],
    value: {
      ...trigger,
      label: trigger.name,
      value: trigger.id,
      id: trigger.id,
    },
  }));
}

/**
 * Transforms an array of deployed firmwares into a standardized format.
 * @param {Array<FirmwareInfo>} deployedFirmwares - The array of deployed firmwares to be transformed.
 * @returns {Array} - An array containing the transformed firmwares.
 */
export function transformFirmwares(deployedFirmwares) {
  if (!deployedFirmwares?.length) return [];

  const selectedItem = FIRST_LEVEL_ITEMS.find(
    (item) => item.value === SCHEME_PARENT_ITEMS.FIRMWARES
  );

  return deployedFirmwares.map((firmware) => {
    const firmwareSubType = SCHEME_FIRMWARE_CATEGORIES.find(
      (item) => item.value === firmware.firmwareType
    );
    return {
      item: [selectedItem, firmwareSubType],
      value: {
        label: `${firmware.firmwareName} v${firmware.firmwareVersion}`,
        value: firmware.firmwareId,
        id: firmware.firmwareId,
        ...firmware,
      },
    };
  });
}

/**
 * Finds an item in an object by a specified key.
 * @param {object} obj - The object to search for the key.
 * @param {string} keyToFind - The key to find in the object.
 * @returns {object} - An object containing the parent key and the found item, or null if not found.
 */
function findItemsByKey(obj, keyToFind) {
  for (const [parentKey, items] of Object.entries(obj)) {
    if (Array.isArray(items)) {
      for (const item of items) {
        if (item.value === keyToFind) {
          return { parentKey, item };
        }
      }
    }
  }
  return null;
}

/**
 * Transforms group settings into a standardized format.
 * NEED REAFCTOR
 * @param {object} groupSettings - The group settings to be transformed.
 * @param {Array} inputs - The group settings to be transformed.
 * @returns {Array} - An array containing the transformed settings.
 */
export function transformSettings(groupSettings, inputs) {
  const ignoredKeys = [
    'imagerState', // processing another way below
    'videoPipeline', // processing another way below
    'audioPipeline', // processing another way below
    'wifiNetworks',
    'highways',
    'loggedObd2',
    'lastUpdatedTime',
  ];

  const defaultSettings = groupSettingsByCategory();

  if (isEmpty(groupSettings)) return [];

  const selectedItem = FIRST_LEVEL_ITEMS.find(
    (item) => item.value === SCHEME_PARENT_ITEMS.SETTINGS
  );

  const transformedSettings = [];

  for (const [key, value] of Object.entries(groupSettings)) {
    const result = findItemsByKey(defaultSettings, key);

    const subType = SCHEME_SETTINGS_SUBCATEGORIES?.find(
      (item) => item?.value === result?.parentKey
    );

    if (key === 'imagerState') {
      if (!value) continue;
      const imagers = JSON?.parse(value);
      const secondItem = SCHEME_SETTINGS_SUBCATEGORIES?.find(
        (item) => item.value === GROUP_SETTINGS_CATEGORIES.DEVICE
      );
      imagers?.forEach((element) => {
        const label = inputs.find((i) => i.inputCode === element.inputCode)?.inputName;
        const thirdItem = {
          label: trimSpaceFromString(label),
          value: element.inputCode,
          inputType: element?.inputType,
          customEnable: true,
          isEnable: element.isEnable,
        };
        const fourthItem = IMAGER_STATE_SETTINGS;
        const transformedData = {
          item: [selectedItem, secondItem, thirdItem, fourthItem],
          value: BOOLEAN_OPTIONS.find((i) => i.value === element.isEnable),
        };
        transformedSettings.push(transformedData);
      });
    } else if (['videoPipeline', 'audioPipeline'].includes(key)) {
      if (!value) continue;
      const pipelineSettings = JSON.parse(value);
      pipelineSettings.forEach((element) => {
        const secondItem = SCHEME_SETTINGS_SUBCATEGORIES.find(
          (item) => item.value === element.pipeline
        );
        const input = inputs.find((i) => i.inputCode === element.src);
        const thirdItem = {
          label: trimSpaceFromString(input?.inputName),
          value: input?.inputCode,
          inputType: input?.inputType,
          customEnable: true,
        };

        for (const [key, value] of Object.entries(element)) {
          if (['src', 'pipeline'].includes(key)) continue;
          const pipelineDefaultSettings = (
            input?.inputType === 'VIDEO' ? VIDEO_PIPELINE_SETTINGS : AUDIO_PIPELINE_SETTINGS
          ).map((i) => ({
            ...i,
            value: i.key,
          }));
          const fourthItem = pipelineDefaultSettings.find((i) => i.key === key);
          const itemVal = fourthItem?.options?.find((i) => i?.value === value) || value;
          const transformedData = {
            item: [selectedItem, secondItem, thirdItem, fourthItem],
            value: itemVal,
          };
          transformedSettings.push(transformedData);
        }
      });
    } else {
      if (ignoredKeys.includes(key) || !result?.parentKey || !subType) continue;

      if (['recordingResolution', 'streamingResolution'].includes(key)) {
        result.item.options = VIDEO_RECORDING_DEFAULT_RESOLUTIONS;
      }

      const itemVal = !isUndefined(result?.item?.options)
        ? result?.item?.options?.find((i) => i?.value === value)
        : value;

      if (isUndefined(itemVal)) continue;

      const transformedData = {
        item: [selectedItem, subType, result.item],
        value: itemVal,
      };

      transformedSettings.push(transformedData);
    }
  }

  return transformedSettings;
}
