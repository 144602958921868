import { CAMERA_OFF_ICON, CAMERA_ON_ICON } from '@/assets/constants/images';
import { formatCameraName } from '@/utils/cameras';
import { formatTimestamp } from '@/utils/datetime';
import { BoxImage } from '@/web/@components/BoxImage';
import { ScoreMiniWidget } from '@/web/safety/scoring/@components/ScoreMiniWidget';
import { Avatar, Box, Typography } from '@mui/material';

export const COACHING_HISTORY_TABLE_COLUMNS = [
  {
    id: 'endpointId',
    label: 'Camera',
    align: 'left',
    isPrimary: true,
    searchable: true,
    format: (value, row) => {
      return (
        <Box display="flex" alignItems="center" gap={1}>
          <BoxImage src={row?.camera?.isOnline ? CAMERA_ON_ICON : CAMERA_OFF_ICON} height="25px" />
          <Typography fontSize="0.875rem">
            {row?.camera
              ? formatCameraName(row?.camera.label, row?.camera.deviceSerialNumber)
              : 'Smarter AI Dashcam'}
          </Typography>
        </Box>
      );
    },
  },
  {
    id: '',
    label: 'Coach',
    align: 'left',
    searchable: true,
    format: (value, row) => {
      return (
        <Box display="flex" alignItems="center" gap={1}>
          <Avatar
            alt={row?.coachInfo?.name}
            src={row?.coachInfo?.pictureURL}
            sx={{ width: 36, height: 36 }}
          />
          <Typography fontSize="0.875rem">{row?.coachInfo?.name}</Typography>
        </Box>
      );
    },
  },
  {
    id: 'totalCoachedEvent',
    label: 'Event Coached',
    align: 'center',
    searchable: true,
  },
  {
    id: 'scorePrev',
    label: 'Previous Score',
    align: 'center',
    format: (value, row) => {
      return (
        <Box width="28px" height="28px" position="relative" sx={{ width: '100%' }}>
          <ScoreMiniWidget value={value} />
          <Typography
            position="absolute"
            left={5}
            right={5}
            top={1}
            bottom={0}
            textAlign={'center'}
            borderRadius="50%"
            lineHeight={value ? '28px' : '20px'}
            fontWeight={600}
            fontSize={Math.round(value) > 99 ? '10px' : '12px'}
          >
            {Math.round(value) || '...'}
          </Typography>
        </Box>
      );
    },
  },
  {
    id: 'scoreCurrent',
    label: 'New Score',
    align: 'center',
    format: (value, row) => {
      return (
        <Box width="28px" height="28px" position="relative" sx={{ width: '100%' }}>
          <ScoreMiniWidget value={value} />
          <Typography
            position="absolute"
            left={5}
            right={5}
            top={1}
            bottom={0}
            textAlign={'center'}
            borderRadius="50%"
            lineHeight={value ? '28px' : '20px'}
            fontWeight={600}
            fontSize={Math.round(value) > 99 ? '10px' : '12px'}
          >
            {Math.round(value) || '...'}
          </Typography>
        </Box>
      );
    },
  },
  {
    id: 'startTime',
    label: 'Date',
    align: 'center',
    format: (value, row) => formatTimestamp(value),
  },
];
