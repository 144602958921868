import { BOX_MODIFY_ICON } from '@/assets/constants/images';
import { T } from '@/assets/locales';
import { AddOutlined } from '@mui/icons-material';

/** @type {Array<TableHeaderAction<DeviceBatchResponse>>} */
export const ACTION_ITEMS = [
  // {
  //   label: 'Discontinue',
  //   iconImage: BOX_DISCONTINUE,
  //   component: 'BUTTON',
  //   type: 'DISCONTINUE',
  //   variant: 'outlined',
  //   hidden: (selected) => !selected.length,
  //   confirmationMessage: T['device.batch.confirmations.discontinue'],
  // },
  {
    label: T['device-batch.actions.modify'],
    iconImage: BOX_MODIFY_ICON,
    component: 'BUTTON',
    variant: 'outlined',
    hidden: (selected) => selected.length !== 1,
    link: (selectedItem) => `/smarter-ai/device-batches/${selectedItem.id}/edit`,
  },
  {
    label: 'Search',
    placeholder: T['device-batch.actions.search.placeholder'],
    component: 'SEARCH',
  },
  {
    label: T['device-batch.actions.create'],
    icon: AddOutlined,
    component: 'BUTTON',
    variant: 'contained',
    link: '/smarter-ai/device-batches/create',
  },
];
