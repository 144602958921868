import { streamingSettings } from './streaming';
import { onboardingSettings } from './onboarding';
import { rebootSettings } from './firmware';
import { deviceSettings } from './device';
import { parkingSettings } from './parking';
import { visionSettings } from './vision';
import { profilingSettings } from './profiling';
import { recordingSettings } from './recording';
import { blurringSettings } from './blurring';
import { sensorsPipelineSettings } from './sensor-pipelines';
import { deviceWifiConnectivitySettings } from './connectivity';
import { driverSettings } from './driver';
import { trafficSettings } from './traffic';
import { faceLoginSettings } from './faceLogin';

export const DEVICE_GROUP_SETTINGS = [
  ...recordingSettings,
  ...streamingSettings,
  ...onboardingSettings,
  ...rebootSettings,
  ...deviceSettings,
  ...parkingSettings,
  ...visionSettings,
  ...profilingSettings,
  ...sensorsPipelineSettings,
  ...blurringSettings,
  ...deviceWifiConnectivitySettings,
  ...driverSettings,
  ...trafficSettings,
  ...faceLoginSettings,
];
