import { MIN_RECORDING_SELECT_TIME } from '@/assets/player/config';
import { useDashcamPlayer } from '@/hooks/useDashcamPlayer';
import { useRouteQuery } from '@/hooks/useRouteQuery';
import { store } from '@/store';
import {
  selectIsSupport,
  selectRefreshToken,
  selectSecretToken,
  selectTenantId,
} from '@/store/auth';
import { PageView } from '@/store/page-view';
import { trimString } from '@/utils/formatting';
import { CenterBox } from '@/web/@components/CenterBox';
import { HubConnectionProvider } from '@/web/@components/HubConnectionContext';
import { PlayerEventList } from '@/web/@components/PlayerEventList';
import { Box, CircularProgress } from '@mui/material';
import { useEffect, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';

export function LiveStreamPage() {
  const { query } = useRouteQuery();

  /** @type {import('react').Ref<HTMLDivElement>} */
  const parentRef = useRef();

  const player = useDashcamPlayer();
  const tenantId = useSelector(selectTenantId);
  const support = useSelector(selectIsSupport);
  const accessToken = useSelector(selectSecretToken);
  const refreshToken = useSelector(selectRefreshToken);

  const endpointId = useMemo(() => Number(query.id), [query.id]);

  useEffect(() => {
    if (!player) return;
    player.setAttribute('support', support ? 'on' : 'off');
  }, [player, support]);

  useEffect(() => {
    if (!player) return;
    player.setAttribute('tenant-id', tenantId);
  }, [player, tenantId]);

  useEffect(() => {
    if (!player) return;
    player.setAttribute('refresh-token', refreshToken);
  }, [player, refreshToken]);

  useEffect(() => {
    if (!player) return;
    player.setAttribute('access-token', accessToken);
  }, [player, accessToken]);

  useEffect(() => {
    if (!player) return;
    player.setAttribute('endpoint-id', trimString(endpointId));
  }, [player, endpointId]);

  useEffect(() => {
    if (!player) return;
    const time = Math.floor(Number(query.time));
    if (time && time >= Date.now() - MIN_RECORDING_SELECT_TIME) {
      player.setAttribute('time', time + '');
    } else {
      player.setAttribute('time', null);
    }
  }, [player, query.time]);

  // display on view
  useEffect(() => {
    if (!player || !refreshToken || !accessToken) return;
    parentRef?.current?.replaceChildren(player);
    store.dispatch(PageView.setHideToolbar(true));
    store.dispatch(PageView.setFullWidthLayout(true));
  }, [player, refreshToken, accessToken]);

  return (
    <>
      {player && accessToken && (
        <HubConnectionProvider>
          <PlayerEventList
            player={player}
            cameraId={endpointId}
            tenantId={tenantId}
            secretToken={accessToken}
          />
        </HubConnectionProvider>
      )}
      <Box ref={parentRef} width="100%" height="100%" p={1}>
        <CenterBox fullView>
          <CircularProgress />
        </CenterBox>
      </Box>
    </>
  );
}
