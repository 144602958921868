/**
 * ----------------- HOW IT WORKS --------------------
 * Action sequence to establish an SignalR connection:
 *  1. Generate a session id using this device id
 *  2. Auto refresh session id on expiry
 *  3. Get access token and SignalR connection URL
 *  4. Establish a SignalR HubConnection connection
 *
 * Documentations:
 *  - https://docs.microsoft.com/en-us/aspnet/core/signalr/javascript-client?view=aspnetcore-6.0&tabs=visual-studio-code
 * ---------------------------------------------------
 */

import api from '@/api';
import { SESSION_TIMEOUT } from '@/assets/signalr/config';
import { isProduction } from '@/config';
import { store } from '@/store';
import { selectSecretToken, selectTenantId } from '@/store/auth';
import { Hub, selectDeviceId } from '@/store/hub-connection';
import { CustomLogger } from '@/utils/logger';
import { SentryEvents, reportEvent } from '@/utils/sentry';
import { HubConnectionBuilder } from '@microsoft/signalr';
import { createContext, useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

const logger = new CustomLogger('SignalR');

/** @type {Array<keyof Console>} */
const log_levels = [null, 'debug', 'info', 'warn', 'error', 'error', null];

/** @type {import('react').Context<import("@microsoft/signalr").HubConnection>} */
export const HubConnectionContext = createContext(null);

/** @param {{children: import("react").ReactNode}} props */
export function HubConnectionProvider(props) {
  const parentHub = useContext(HubConnectionContext);
  if (parentHub !== null) return props.children;
  return <InnerHubConnectionProvider {...props} />;
}

/** @param {{children: import("react").ReactNode}} props */
function InnerHubConnectionProvider(props) {
  const deviceId = useSelector(selectDeviceId);
  const tenantId = useSelector(selectTenantId);
  const secretToken = useSelector(selectSecretToken);

  /** @type {StateVariable<import("@microsoft/signalr").HubConnection>} */
  const [connection, setConnection] = useState(null);
  const [accessToken, setAccessToken] = useState(null);
  const [connectionUrl, setConnectionUrl] = useState(null);

  /// -----------------------------------------------------
  /// 0 | Reset the current state for a new session
  /// -----------------------------------------------------
  useEffect(() => {
    store.dispatch(Hub.setDeviceId());
  }, []);

  useEffect(() => {
    if (!deviceId || !tenantId) return;
    let iid;
    const aborter = new AbortController();
    /// -----------------------------------------------------
    /// 1 | Generate a session id using this device id
    /// -----------------------------------------------------
    async function refreshSession() {
      store.dispatch(Hub.setLoading(true));
      const request = api.ac.v2.endpoint.dashboard.session.create.$post({
        signal: aborter.signal,
        headers: {
          Authorization: secretToken,
        },
        params: {
          tenantId,
          deviceId,
        },
      });
      try {
        const result = await request.process();
        if (!result?.sessionId) return;
        logger.debug('Session ID:', result.sessionId);
        store.dispatch(Hub.setSessionId(result.sessionId));
        await refreshAccessToken(result.sessionId);
      } catch (err) {
        logger.error(err);
        clearInterval(iid);
        store.dispatch(Hub.setLoading(false));
        store.dispatch(Hub.setSessionId(null));
        reportEvent(SentryEvents.CREATE_SESSION_FAILED, '', {
          err,
          url: request.request.url,
          tags: request.request.params,
          headers: request.request.headers,
          result: request.result,
        });
      }
    }

    /// -----------------------------------------------------
    /// 2 | Get access token and SignalR connection URL
    /// -----------------------------------------------------
    const refreshAccessToken = async (/** @type {number} */ sessionId) => {
      store.dispatch(Hub.setLoading(true));
      const request = api.ac.v2['event-messaging'].negotiate.$post({
        signal: aborter.signal,
        params: {
          tenantId,
          secretToken,
          endpointid: sessionId,
          endpointtype: 'DASHBOARDPUSH',
        },
      });
      try {
        const result = await request.process();
        const connectionUrl = result?.url || result?.endpoint;
        const accessToken = result?.accessToken || result?.accessKey;
        if (!accessToken || !connectionUrl) return;
        logger.debug('Connection URL:', connectionUrl);
        logger.debug('Access Token:', accessToken);
        setAccessToken(accessToken);
        setConnectionUrl(connectionUrl);
      } catch (err) {
        logger.error('Failed negotiation', err);
        setAccessToken(null);
        setConnectionUrl(null);
        store.dispatch(Hub.setLoading(false));
        store.dispatch(Hub.setFailureReason('GET_NEGOTIATE_TOKEN_FAILED'));
        reportEvent(SentryEvents.GET_NEGOTIATE_TOKEN_FAILED, '', {
          err,
          url: request.request.url,
          tags: request.request.params,
          headers: request.request.headers,
          result: request.result,
        });
      }
    };

    /// -----------------------------------------------------
    /// 3 | Auto refresh session after session timeout
    /// -----------------------------------------------------
    refreshSession();
    iid = setInterval(refreshSession, SESSION_TIMEOUT);
    return () => {
      aborter.abort();
      clearInterval(iid);
    };
  }, [tenantId, secretToken, deviceId]);

  /// -----------------------------------------------------
  /// 4 | Establish a SignalR HubConnection connection
  /// -----------------------------------------------------
  useEffect(() => {
    if (!accessToken || !connectionUrl) {
      setConnection(null);
      return;
    }
    store.dispatch(Hub.setLoading(true));
    const hub = new HubConnectionBuilder()
      .withAutomaticReconnect()
      .withUrl(connectionUrl, {
        logMessageContent: !isProduction,
        accessTokenFactory: () => accessToken,
      })
      .configureLogging({
        log: (level, ...args) => logger._out(log_levels[level], ...args),
      })
      .build();

    hub.on('newMessage', (message) => {
      logger.debug(message.type, message);
    });

    hub.onclose(async () => {
      logger.debug('Hub connection is closed');
      setConnection(null);
      // store.dispatch(Hub.setLoading(false));
      // store.dispatch(Hub.setFailureReason('CONNECTION_CLOSED'));
      // reportEvent(SentryEvents.HUB_CONNECTION_CLOSED, '', {
      //   connectionState: hub.state,
      //   connectionId: hub.connectionId,
      //   tags: {
      //     accessToken,
      //     connectionUrl,
      //   },
      // });
    });

    hub.onreconnected(() => {
      logger.debug('Reconnected with id:', hub.connectionId);
      store.dispatch(Hub.setConnectionId(hub.connectionId));
    });

    const tid = setTimeout(async () => {
      try {
        await hub.start();
        setConnection(hub);
      } catch (err) {
        logger.warn('Failed connection', err);
        setConnection(null);
        store.dispatch(Hub.setFailureReason('SIGNALR_CONNECT_FAILURE'));
        reportEvent(SentryEvents.HUB_CONNECTION_FAILED, '', {
          connectionState: hub.state,
          connectionId: hub.connectionId,
          tags: {
            accessToken,
            connectionUrl,
          },
        });
      } finally {
        store.dispatch(Hub.setLoading(false));
      }
    }, 1000);

    return () => {
      clearTimeout(tid);
      if (hub.connectionId) {
        hub.stop().catch(logger.error);
      }
    };
  }, [accessToken, connectionUrl]);

  useEffect(() => {
    store.dispatch(Hub.setConnectionId(connection?.connectionId || null));
  }, [connection]);

  /// -----------------------------------------------------
  /// Pack necessary values through provider
  /// -----------------------------------------------------
  return (
    <HubConnectionContext.Provider value={connection || undefined} children={props.children} />
  );
}
