import api from '@/api';
import { selectSecretToken, selectTenantId } from '@/store/auth';
import { CameraDetailsContext } from '@/web/@layouts/CameraDetailsLayout';
import { createContext, useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

/**
 * @typedef {object} DeviceUsagesContextData
 * @property {boolean} isNewDataSource
 */

/** @type {import("react").Context<DeviceUsagesContextData>} */
export const DeviceUsagesContext = createContext(null);

export function DeviceUsagesLayout(props) {
  const { children } = props;
  const navigate = useNavigate();
  const tenantId = useSelector(selectTenantId);
  const secretToken = useSelector(selectSecretToken);

  const { cameraId } = useContext(CameraDetailsContext);

  const [initialTenantId] = useState(tenantId);
  const [isNewDataSource, setIsNewDataSource] = useState(false);

  useEffect(() => {
    const checkDeviceUpTime = async () => {
      try {
        const request = api.ac.v5.device.records['device-uptime'].search.$get({
          headers: {
            Authorization: secretToken,
          },
          params: {
            pageSize: 1,
            endpoint: cameraId + '',
            startTimestamp: 0 + '',
            endTimestamp: new Date().getTime()?.toString(),
          },
        });
        const result = await request?.process();
        setIsNewDataSource(result?.deviceRecords?.length > 1 ? true : false);
      } catch (ex) {}
    };
    checkDeviceUpTime();
  }, [secretToken, cameraId]);

  useEffect(() => {
    if (initialTenantId !== tenantId) {
      navigate('../');
    }
  }, [navigate, initialTenantId, tenantId]);

  return (
    <DeviceUsagesContext.Provider value={{ isNewDataSource }}>
      {children}
    </DeviceUsagesContext.Provider>
  );
}
