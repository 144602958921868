import { T } from '@/assets/locales';
import { BoxImage } from '@/web/@components/BoxImage';
import { Button } from '@mui/material';
import { t } from 'i18next';
import { useNavigate } from 'react-router-dom';

/**
 * @typedef {object} GeoTabLoginButtonPropsBase
 * @property {boolean} [disabled]
 */

/**
 * @typedef {import('@mui/material').ButtonProps & GeoTabLoginButtonPropsBase} GeoTabLoginButtonProp
 */

/**
 * @param {GeoTabLoginButtonProp} props
 */
export function GeoTabLoginButton(props) {
  const { disabled, ...extraProps } = props;
  const navigate = useNavigate();
  return (
    <Button
      {...extraProps}
      onClick={() => {
        navigate('../geotab-login');
      }}
    >
      <BoxImage
        src="/images/pages/auth/geotab-icon.svg"
        alt="google-login"
        width={16}
        height={16}
      />
      &nbsp;&nbsp;
      <span>{t(T['login.button.geotab'])}</span>
    </Button>
  );
}
