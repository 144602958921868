import { EMAIL_VALIDATION_REGEX } from '@/assets/regex';
import { parseJwtToken } from '@/utils/crypto';
import { createSlice } from '@reduxjs/toolkit';
import { isEqual, sortBy } from 'lodash';

/**
 * @typedef {object} TokenData
 * @property {'REFRESH_TOKEN'|'ACCESS_TOKEN'} token_type
 * @property {string} [tenant_id]
 * @property {number} [user_id]
 * @property {string} email
 * @property {string} session_id
 * @property {string} [role]
 * @property {number} exp unix timestamp in seconds
 * @property {number} iat unix timestamp in seconds
 */

/**
 * @typedef {object} InitialState
 * @property {string} avatar User avatar
 * @property {boolean} remember
 * @property {AccountLoginRequest} request
 * @property {string} accountToken Token after user login
 * @property {number} accountTokenExpiry
 * @property {TokenData} accountTokenData
 * @property {Array<TenantIdWithName>} tenantList
 * @property {string} accessToken Token after tenant login
 * @property {number} accessTokenExpiry
 * @property {TokenData} accessTokenData
 * @property {string} refreshToken Token to refresh the access token
 * @property {number} refreshTokenExpiry
 * @property {TokenData} refreshTokenData
 * @property {string} sessionId
 * @property {string} tenantId Selected tenant id
 * @property {number} userId
 * @property {string} email
 * @property {string} role
 * @property {boolean} tokenRefreshing
 * @property {AccountLoginResponse['teamList']} teamList
 * @property {{[key: string]: DescendantTenant}} tenantTree
 * @property {string[]} supportPath
 * @property {number} supportUserId
 * @property {string} supportTenantId
 * @property {string} supportAccessToken
 * @property {string} supportRefreshToken
 * @property {number} supportAccessTokenExpiry
 * @property {number} supportRefreshTokenExpiry
 * @property {{[id: string]: string}} tenantIdToName
 */

/** @returns {InitialState} */
export const getInitialState = () => ({
  tokenRefreshing: false,
  remember: true,
  request: null,
  avatar: null,
  tenantList: [],
  accountToken: null,
  accountTokenExpiry: 0,
  accountTokenData: null,
  userId: null,
  email: null,
  tenantId: null,
  sessionId: null,
  accessToken: null,
  accessTokenExpiry: 0,
  accessTokenData: null,
  refreshToken: null,
  refreshTokenExpiry: 0,
  refreshTokenData: null,
  role: null,
  teamList: [],
  tenantTree: {},
  supportPath: [],
  supportUserId: null,
  supportTenantId: null,
  supportAccessToken: null,
  supportRefreshToken: null,
  supportAccessTokenExpiry: 0,
  supportRefreshTokenExpiry: 0,
  tenantIdToName: {},
});

export const AuthSlice = createSlice({
  name: 'auth',
  initialState: getInitialState(),
  reducers: {
    /** @type {SliceReducer<InitialState, boolean>} */
    setTokenRefreshing(state, action) {
      state.tokenRefreshing = action.payload;
    },
    /** @type {SliceReducer<InitialState, void>} */
    logout(state) {
      Object.assign(state, getInitialState());
    },
    /** @type {SliceReducer<InitialState, string>} */
    setAvatar(state, action) {
      state.avatar = action.payload;
    },
    /** @type {SliceReducer<InitialState, boolean>} */
    setRemember(state, action) {
      state.remember = action.payload;
    },
    /** @type {SliceReducer<InitialState, AccountLoginRequest?>} */
    setRequest(state, action) {
      state.request = { ...action.payload };
      if (state.request) {
        state.request.password = null;
        state.request.authenticationToken = null;
      }
      state.email = null;
      if (action.payload && EMAIL_VALIDATION_REGEX.test(action.payload.email)) {
        state.email = action.payload.email;
      }
    },
    /** @type {SliceReducer<InitialState, { isGeoTabLogin?: boolean } & AccountLoginResponse?>} */
    setAccount(state, action) {
      state.email = action.payload?.email;
      state.teamList = action.payload?.teamList || [];
      state.accountToken = action.payload?.refreshToken;
      state.accountTokenData = parseJwtToken(state.accountToken);
      state.accountTokenExpiry = (state.accountTokenData?.exp || 0) * 1000;
      state.tenantList = sortBy(action.payload?.tenantAccessList || [], 'tenantName');
      state.tenantIdToName ||= {};
      for (const tenant of state.tenantList) {
        state.tenantIdToName[tenant.tenantId] = tenant.tenantName;
      }
      if (
        !action.payload?.isGeoTabLogin &&
        state.accountTokenData?.token_type !== 'REFRESH_TOKEN'
      ) {
        throw new Error('Invalid refresh token');
      }
    },
    /** @type {SliceReducer<InitialState,{ isGeoTabLogin?: boolean } & GetAccessTokenResponse?>} */
    setTenant(state, action) {
      const resp = action.payload || {};
      state.userId = resp.userId;
      state.tenantId = resp.tenantId;
      state.sessionId = resp.sessionId;
      state.accessToken = resp.accessToken;
      state.accessTokenData = parseJwtToken(resp.accessToken);
      state.role = state.accessTokenData?.role || 'SUPER_ADMIN';
      state.accessTokenExpiry = (state.accessTokenData?.exp || 0) * 1000;
      state.refreshToken = resp.refreshToken;
      state.refreshTokenData = parseJwtToken(resp.refreshToken);
      state.refreshTokenExpiry = (state.refreshTokenData?.exp || 0) * 1000;
      if (
        !action.payload?.isGeoTabLogin &&
        state.refreshTokenData?.token_type !== 'REFRESH_TOKEN'
      ) {
        throw new Error('Invalid refresh token');
      }
      state.supportUserId = null;
      state.supportTenantId = null;
      state.supportAccessToken = null;
      state.supportRefreshToken = null;
      state.supportPath = [state.tenantId];
    },
    /** @type {SliceReducer<InitialState, string>} */
    setUserRole(state, action) {
      if (state.role === action.payload) return;
      state.role = action.payload;
    },
    /** @type {SliceReducer<InitialState, {result: GetAccessTokenResponse, support: string[]}>} */
    setSupportTenant(state, action) {
      const { result, support } = action.payload || {};
      state.supportUserId = result?.userId;
      state.supportTenantId = result?.tenantId;
      state.supportAccessToken = result?.accessToken;
      state.supportRefreshToken = result?.refreshToken;
      state.supportPath = support || [state.tenantId];
      state.supportAccessTokenExpiry = result?.accessTokenExpiry;
      state.supportRefreshTokenExpiry = result?.refreshTokenExpiry;
    },
    /** @type {SliceReducer<InitialState, DescendantTenant>} */
    setTenantTree(state, action) {
      state.tenantTree = {
        ...state.tenantTree,
        [action.payload.tenantId]: action.payload,
      };
    },
    /** @type {SliceReducer<InitialState, InitialState>} */
    setStoredState(state, action) {
      if (state.remember !== action.payload.remember) {
        state.remember = action.payload.remember;
      }
      if (!isEqual(state.request, action.payload.request)) {
        state.request = action.payload.request;
      }
      if (state.avatar !== action.payload.avatar) {
        state.avatar = action.payload.avatar;
      }
      if (!isEqual(state.tenantList, action.payload.tenantList)) {
        state.tenantList = action.payload.tenantList || [];
      }
      if (!isEqual(state.tenantIdToName, action.payload.tenantIdToName)) {
        state.tenantIdToName = action.payload.tenantIdToName || {};
      }
      if (state.userId !== action.payload.userId) {
        state.userId = action.payload.userId;
      }
      if (state.email !== action.payload.email) {
        state.email = action.payload.email;
      }
      if (state.tenantId !== action.payload.tenantId) {
        state.tenantId = action.payload.tenantId;
      }
      if (state.sessionId !== action.payload.sessionId) {
        state.sessionId = action.payload.sessionId;
      }
      if (state.accessToken !== action.payload.accessToken) {
        state.accessToken = action.payload.accessToken;
        state.accessTokenData = action.payload.accessTokenData;
        state.accessTokenExpiry = action.payload.accessTokenExpiry;
      }
      if (state.accountToken !== action.payload.accountToken) {
        state.accountToken = action.payload.accountToken;
        state.accountTokenData = action.payload.accountTokenData;
        state.accountTokenExpiry = action.payload.accountTokenExpiry;
      }
      if (state.refreshToken !== action.payload.refreshToken) {
        state.refreshToken = action.payload.refreshToken;
        state.refreshTokenData = action.payload.refreshTokenData;
        state.refreshTokenExpiry = action.payload.refreshTokenExpiry;
      }
      if (state.role !== action.payload.role) {
        state.role = action.payload.role;
      }
      if (state.supportPath !== action.payload.supportPath) {
        state.supportPath = action.payload.supportPath;
      }
      if (state.supportTenantId !== action.payload.supportTenantId) {
        state.supportTenantId = action.payload.supportTenantId;
      }
      if (state.supportAccessToken !== action.payload.supportAccessToken) {
        state.supportAccessToken = action.payload.supportAccessToken;
      }
      if (state.supportRefreshToken !== action.payload.supportRefreshToken) {
        state.supportRefreshToken = action.payload.supportRefreshToken;
      }
      if (state.supportUserId !== action.payload.supportUserId) {
        state.supportUserId = action.payload.supportUserId;
      }
    },
  },
});
