import { FROM_TYPES } from '@/assets/schemes/constants';
import { CustomInput, CustomSelect } from '@/web/@components/CustomForm';
import {
  AUDIO_PIPELINE_SETTINGS,
  BOOLEAN_OPTIONS,
  FIRST_LEVEL_ITEMS,
  IMAGER_STATE_SETTINGS,
  SCHEME_PARENT_ITEMS,
  SCHEME_SETTINGS_SUBCATEGORIES,
  VIDEO_PIPELINE_SETTINGS,
  typeMapping,
} from '@/web/smarter-ai/scheme-management/schemes/assets';
import { uniqueArrayByKey } from '@/web/smarter-ai/scheme-management/schemes/utils';
import { getInputSelections } from '@/web/smarter-ai/scheme-management/schemes/utils/settings';
import { Box, Grid, InputAdornment, Typography } from '@mui/material';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { DateTimePickerForm } from '../TimeSelection';

function SchemePropertyFormControl(props) {
  const { item, fieldIndex, triggers, settings, productInputs } = props;

  const { control } = useFormContext();

  const [selectionKey] = useState('value');
  const [selectionLabel] = useState('label');
  const [itemType, setItemType] = useState(item?.type);

  const [lastItem, setLastItem] = useState(null);

  const valueKey = `properties[${fieldIndex}].value`;
  const labelsKey = `properties[${fieldIndex}].item`;

  const selectedValue = useWatch({ control, name: valueKey });
  const selectedLabels = useWatch({ control, name: labelsKey });

  const lastSelectedLabels = useMemo(
    () => selectedLabels?.at(selectedLabels?.length - 1),
    [selectedLabels]
  );

  /****************************************
   * SELECT BOX OPTIONS
   ****************************************/
  const getSettingsLabelOptions = useCallback(() => {
    const selectedLabelsLength = selectedLabels?.length || 0;
    const secondProperty = selectedLabels?.at(1)?.value;
    const thirdProperty = selectedLabels?.at(2)?.value;

    const options =
      thirdProperty && selectedLabelsLength === 3
        ? []
        : secondProperty && selectedLabelsLength === 2
          ? settings[secondProperty]
          : selectedLabelsLength <= 2
            ? SCHEME_SETTINGS_SUBCATEGORIES
            : [];

    if (['device_settings', 'streaming', 'recording'].includes(secondProperty) && !thirdProperty) {
      options.push(...getInputSelections(productInputs));
    }

    const customEnable = selectedLabels?.at(selectedLabelsLength - 1).customEnable || false;

    if (['streaming', 'recording'].includes(secondProperty) && thirdProperty && customEnable) {
      const isVideo = selectedLabels?.at(2)?.inputType === 'VIDEO';
      const items = (isVideo ? VIDEO_PIPELINE_SETTINGS : AUDIO_PIPELINE_SETTINGS).map((i) => ({
        ...i,
        value: i.key,
      }));
      options.push(...items);
    }

    if (secondProperty === 'device_settings' && thirdProperty && customEnable) {
      options.push(IMAGER_STATE_SETTINGS);
    }

    return uniqueArrayByKey(options, 'value');
  }, [productInputs, selectedLabels, settings]);

  const labelSelectionOptions = useCallback(() => {
    const selectedLabelsLength = selectedLabels?.length || 0;
    const firstProperty = selectedLabels?.at(0)?.value ?? null;

    if (firstProperty === SCHEME_PARENT_ITEMS.SETTINGS) {
      return getSettingsLabelOptions();
    }

    return selectedLabelsLength === 0 ? FIRST_LEVEL_ITEMS : [];
  }, [selectedLabels, getSettingsLabelOptions]);

  const valueSelectionOptions = useCallback(() => {
    const selectedLabelsLength = selectedLabels?.length || 0;
    const firstProperty = selectedLabels?.at(0)?.value ?? null;

    switch (firstProperty) {
      case SCHEME_PARENT_ITEMS.TRIGGERS:
        return triggers;
      case SCHEME_PARENT_ITEMS.SETTINGS:
        const lastItem = selectedLabels?.at(selectedLabelsLength - 1);
        return lastItem?.type === 'switch' ? BOOLEAN_OPTIONS : lastItem?.options || [];
      default:
        return [];
    }
  }, [selectedLabels, triggers]);

  const itemOptions = useMemo(() => {
    if (item.name === 'item') {
      return labelSelectionOptions();
    }
    return valueSelectionOptions();
  }, [item, labelSelectionOptions, valueSelectionOptions]);

  /****************************************
   * END OF SELECT BOX OPTIONS
   ****************************************/

  const autocompleteProps = useMemo(() => {
    return item.multiple
      ? {
          multiple: true,
          renderTags: (value) => (
            <Box
              sx={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {value
                .map((x) => x?.label)
                .filter(Boolean)
                .join('.')}
            </Box>
          ),
          open: itemOptions?.length > 0 ? true : false,
          forcePopupIcon: false,
        }
      : { forcePopupIcon: false };
  }, [item, itemOptions]);

  useEffect(() => {
    if (item.name === 'item') return;
    const lastItem = selectedLabels?.[selectedLabels?.length - 1];
    const itemType = (lastItem?.type && typeMapping[lastItem.type]) || FROM_TYPES.SELECT;
    setItemType(itemType);
    setLastItem(lastItem);
  }, [item, selectedLabels]);

  if (item.visibility && !item.visibility(selectedValue)) {
    return null;
  }

  const commonProps = {
    disabled: true,
    name: `properties[${fieldIndex}].${item.name}`,
    placeholder: item.placeholder,
    rules: { required: item?.mandatory ? 'This is required' : false },
  };

  let component = null;

  switch (itemType) {
    case FROM_TYPES.MULTISELECT:
      component = (
        <Grid item xs={12} md={5} lg={5}>
          <CustomSelect
            {...commonProps}
            options={itemOptions || []}
            getKey={'value'}
            getLabel={'label'}
            AutocompleteProps={autocompleteProps}
          />
        </Grid>
      );
      break;

    case 'select':
      component = (
        <Grid item xs={10} md={4} lg={4}>
          <Box sx={{ display: 'flex', width: '100%', gap: '10px', alignItems: 'center' }}>
            <CustomSelect
              {...commonProps}
              options={itemOptions}
              getKey={selectionKey || 'value'}
              getLabel={selectionLabel || 'label'}
              AutocompleteProps={autocompleteProps}
            />
          </Box>
        </Grid>
      );
      break;

    case FROM_TYPES.DATE_TIME:
      component = (
        <Grid item xs={10} md={5} lg={4}>
          <DateTimePickerForm item={lastItem} name={valueKey} />
        </Grid>
      );
      break;

    case 'input':
    case 'number':
      component = (
        <Grid item xs={10} md={4} lg={4}>
          <Box sx={{ display: 'flex', width: '100%', gap: '10px', alignItems: 'center' }}>
            <CustomInput
              {...commonProps}
              label={item.label}
              type={itemType === 'number' ? 'number' : 'input'}
              InputProps={{
                ...(lastSelectedLabels?.postText && {
                  endAdornment: (
                    <InputAdornment position="end">
                      <Typography fontSize="14px">{lastSelectedLabels?.postText}</Typography>
                    </InputAdornment>
                  ),
                }),
              }}
            />
          </Box>
        </Grid>
      );
      break;

    default:
      break;
  }

  return component;
}

export const MemoizedEffectiveSchemePropertyFormControl = memo(SchemePropertyFormControl);
