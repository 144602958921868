import { BoxImage } from '@/web/@components/BoxImage';
import { Box, Typography } from '@mui/material';

/**
 * @typedef {object} CameraIconProps
 * @property {string} [src]
 * @property {any} [icon]
 * @property {any} title
 * @property {any} [subtitle]
 * @property {any} [iconTitle]
 * @property {boolean} [hidden]
 * @property {import('@mui/system').ResponsiveStyleValue<any>} [iconSize]
 */

/** @param {Partial<import('@mui/material').BoxProps & CameraIconProps>} props */
export function IconLabelItem(props) {
  const {
    src,
    icon,
    title,
    subtitle,
    iconTitle,
    hidden,
    iconSize = {
      xs: '20px',
      md: '24px',
    },
    ...boxProps
  } = props;

  return (
    <Box display="flex" gap="5px" whiteSpace="nowrap" {...boxProps}>
      {!hidden ? (
        <>
          {(src || icon) && (
            <Box className="icon-image" height="100%" width="fit-content" title={iconTitle}>
              {icon ?? <BoxImage display="block" size={iconSize} src={src} alt="Camera" />}
            </Box>
          )}
          <Box
            className="label-content"
            sx={{
              'flex': 1,
              'width': '100%',
              'overflow': 'hidden',
              '& .label-title, & .label-title .MuiTypography-root': {
                // lineHeight: iconSize,
                lineHeight: 'inherit',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                fontWeight: 'inherit',
                fontSize: 'inherit',
                fontStyle: 'inherit',
              },
              '& .label-title, & .label-subtitle, & .MuiTypography-root': {
                overflow: 'hidden',
                fontWeight: 'inherit',
                fontSize: 'inherit',
                fontStyle: 'inherit',
              },
            }}
          >
            {typeof title === 'string' ? (
              <Typography className="label-title" variant="subtitle1" title={title}>
                {title}
              </Typography>
            ) : (
              <Box className="label-title">{title}</Box>
            )}
            {typeof subtitle === 'string' ? (
              <Typography className="label-subtitle" variant="subtitle2">
                {subtitle}
              </Typography>
            ) : (
              <Box className="label-subtitle">{subtitle}</Box>
            )}
          </Box>
        </>
      ) : null}
    </Box>
  );
}
