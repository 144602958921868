import { T } from '@/assets/locales';
import { DriverCoachingPage } from '.';
import { CoachingEndpointPage } from './_endpointId';
import { GuidedCoachingPage } from './_endpointId/guided';
import { CoachableEventsPage } from './coachable-events';

/** @type {Array<import('@/types').ExtendedRoute>} */
const routes = [
  {
    path: '',
    element: <DriverCoachingPage />,
    breadcrumb: T['coaching.page.title'],
  },
  {
    path: 'coachable-events',
    element: <CoachableEventsPage />,
    breadcrumb: T['coaching.events.page.title'],
  },
  {
    path: ':endpointId',
    breadcrumb: 'Endpoint',
    element: <CoachingEndpointPage />,
    children: [
      {
        path: 'guided',
        element: <GuidedCoachingPage />,
        breadcrumb: T['coaching.guided.page.title'],
      },
      {
        path: 'self',
        element: <GuidedCoachingPage />,
        breadcrumb: T['coaching.self.page.title'],
      },
    ],
  },
];

export default routes;
