import api from '@/api';
import { useFetchTriggerCategories } from '@/hooks/useFetchTriggerCategories';
import { selectSecretToken } from '@/store/auth';
import { flatten, isArray, uniq } from 'lodash';
import { createContext, useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { convertUnit, convertValueByUnit } from '../../utils';

export const TriggerConfigurationContext = createContext(null);

export const TriggerConfigurationProvider = ({ children }) => {
  const secretToken = useSelector(selectSecretToken);

  const { result: triggerCategories, loading: categoryLoading } = useFetchTriggerCategories();

  const [variablesDefinition, setVariableDefinition] = useState([]);
  const [selectionType, setSelectionType] = useState(null);
  const [parentLoading, setParentLoading] = useState(true);
  const [triggers, setTriggers] = useState(null);
  const [error, setError] = useState(true);

  const triggerCategoryIds = useMemo(
    () => uniq(triggerCategories?.map((i) => i?.id)),
    [triggerCategories]
  );

  const getVariableConstantsDetails = useCallback(
    async (signal) => {
      try {
        if (!triggerCategoryIds?.length || categoryLoading) return;
        const variableDefinitionRequests = api.ac.v5.trigger.variable.definition.$post({
          signal,
          headers: {
            Authorization: secretToken,
          },
          data: triggerCategoryIds,
        });
        const variableDefinitionResponse = await variableDefinitionRequests.process();

        const customerLabelVariables = flatten(
          variableDefinitionResponse.values?.map((i) =>
            i.variableDefinitions
              .filter((y) => y.customerFacing)
              .map((y) => ({ ...y, triggerCategoryId: i.triggerCategoryId }))
          )
        );
        setVariableDefinition(customerLabelVariables);
      } catch (ex) {}
    },
    [secretToken, triggerCategoryIds, categoryLoading]
  );

  const transFormData = useCallback(
    async (triggers) => {
      if (!triggerCategories?.length || !variablesDefinition?.length) return;

      const transformedResult = (triggers || [])
        .map((trigger) => {
          const triggerCategory = triggerCategories.find(
            (i) => i.id === trigger?.triggerCategoryId
          );

          // Skip the current item if trigger category is not found
          // Or it a Shadow Category , id = 36
          // Or it a Default Category , id = 1
          if (!triggerCategory || [1, 36].includes(triggerCategory?.id)) {
            return null;
          }

          const variablesData = trigger?.body?.variableOverrides?.variables;

          const triggerVariables = (variablesDefinition || [])
            ?.filter((variable) => variable.triggerCategoryId === trigger.triggerCategoryId)
            ?.map((variable) => {
              const originalUnit = variable.unit;
              const convertedUnitName = convertUnit(originalUnit);

              const triggerVariableData =
                variablesData?.find((i) => i.variableName === variable.name) || null;

              const { defaultValue, constraint } = variable;
              const { minValue, maxValue, stepSize } = constraint;

              const itemMinValue = convertValueByUnit(minValue, originalUnit, convertedUnitName);
              const itemMaxValue = convertValueByUnit(maxValue, originalUnit, convertedUnitName);
              const itemStepSize = convertValueByUnit(stepSize, originalUnit, convertedUnitName);
              const itemDefaultValue = convertValueByUnit(
                defaultValue,
                originalUnit,
                convertedUnitName
              );

              const itemValue = convertValueByUnit(
                triggerVariableData?.value ?? null,
                originalUnit,
                convertedUnitName
              );

              return {
                ...triggerVariableData,
                key: variable.name,
                description: variable.description,
                unit: convertedUnitName,
                originalUnit,
                minValue: itemMinValue,
                maxValue: itemMaxValue,
                stepSize: itemStepSize,
                default: itemDefaultValue,
                value: itemValue || itemDefaultValue,
              };
            })
            .sort((a, b) => a.key.localeCompare(b.key));

          return {
            ...trigger,
            triggerCategoryName: triggerCategory.name,
            variables: variablesData?.length === 0 ? variablesData : triggerVariables,
          };
        })
        .filter(Boolean);

      const sortedResult = transformedResult.sort((a, b) =>
        a.triggerCategoryName.localeCompare(b.triggerCategoryName)
      );

      return sortedResult;
    },
    [triggerCategories, variablesDefinition]
  );

  const fetchTenantTriggerData = useCallback(
    async (/** @type {AbortSignal} */ signal) => {
      try {
        const request = api.ac.v5.trigger.composite.tenant
          .$tenantId(selectionType?.value)
          .detail.$get({
            signal,
            headers: {
              Authorization: secretToken,
            },
          });
        await request.process();
        const result = request.result?.compositeTriggers;
        return result;
      } catch (ex) {
        setError(ex?.response?.data?.message);
        console.log('ex', ex);
      }
    },
    [secretToken, selectionType?.value]
  );

  const fetchGroupTriggerData = useCallback(
    async (/** @type {AbortSignal} */ signal) => {
      try {
        const request = api.ac.v5.trigger.composite.group
          .$groupId(Number(selectionType?.value))
          .detail.$get({
            signal,
            headers: {
              Authorization: secretToken,
            },
          });
        await request.process();
        const result = request.result?.compositeTriggers;
        return result;
      } catch (ex) {
        setError(ex?.response?.data?.message);
      }
    },
    [secretToken, selectionType?.value]
  );

  const fetchEndpointTriggerData = useCallback(
    async (/** @type {AbortSignal} */ signal) => {
      try {
        const request = api.ac.v5.trigger.composite.endpoint
          .$endpointId(Number(selectionType?.value))
          .detail.$get({
            signal,
            headers: {
              Authorization: secretToken,
            },
          });
        await request.process();
        const result = request.result?.compositeTriggers;
        return result;
      } catch (ex) {
        setError(ex?.response?.data?.message);
      }
    },
    [secretToken, selectionType?.value]
  );

  const fetchTriggerData = useCallback(
    async (id) => {
      const request = api.ac.v5.trigger.composite.$triggerId(id).$get({
        headers: {
          Authorization: secretToken,
        },
        params: {},
      });
      const result = await request?.process();
      const transformedResult = await transFormData([result]);
      return transformedResult?.at(0);
    },
    [secretToken, transFormData]
  );

  const fetchData = useCallback(
    async (abortController) => {
      setLoadingAndClearError(true);
      try {
        let result;
        if (selectionType.type === 'TENANT') {
          result = await fetchTenantTriggerData(abortController.signal);
        } else if (selectionType.type === 'ENDPOINT') {
          result = await fetchEndpointTriggerData(abortController.signal);
        } else if (selectionType.type === 'GROUP') {
          result = await fetchGroupTriggerData(abortController.signal);
        }
        const transformedTriggerData = await transFormData(result);

        await Promise.all(
          transformedTriggerData?.map(async (trigger) => {
            const defaultValues = await fetchTriggerData(trigger?.id);
            trigger.defaultValues = defaultValues;
          })
        );
        await setTriggers(transformedTriggerData);
      } catch (ex) {
        console.log('ex', ex);
        setError(ex?.response?.data?.message);
      }
    },
    [
      fetchEndpointTriggerData,
      fetchGroupTriggerData,
      fetchTriggerData,
      transFormData,
      fetchTenantTriggerData,
      selectionType?.type,
    ]
  );

  const setLoadingAndClearError = (loading) => {
    setParentLoading(loading);
    setError(null);
  };

  useEffect(() => {
    const abortController = new AbortController();
    getVariableConstantsDetails(abortController.signal);
    return () => {
      abortController.abort();
    };
  }, [getVariableConstantsDetails]);

  useEffect(() => {
    if (!selectionType?.type || !selectionType?.value) {
      setLoadingAndClearError(false);
      return;
    }

    const abortController = new AbortController();

    fetchData(abortController);

    return () => {
      abortController.abort();
      setLoadingAndClearError(false);
    };
  }, [selectionType?.type, selectionType?.value, fetchData]);

  useEffect(() => {
    if (isArray(triggers)) {
      setParentLoading(false);
    }
  }, [triggers]);

  return (
    <TriggerConfigurationContext.Provider
      value={{
        setSelectionType,
        selectionType,
        variablesDefinition,
        parentLoading: parentLoading || categoryLoading,
        triggers,
        error,
        setTriggers,
      }}
    >
      {children}
    </TriggerConfigurationContext.Provider>
  );
};
