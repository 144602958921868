import { T } from '@/assets/locales';
import { useFullProductList } from '@/hooks/useFullProductList';
import { trimString } from '@/utils/formatting';
import { CenterBox } from '@/web/@components/CenterBox';
import { CustomInput, CustomSelect } from '@/web/@components/CustomForm';
import { SchemeFormContext } from '@/web/@layouts/SchemeLayout';
import { Box, Button, CircularProgress, Typography } from '@mui/material';
import { createContext, useContext, useEffect, useState } from 'react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { transformAndFormatData } from '../../utils/format';
import { groupSettingsByCategory } from '../../utils/settings';
import { SchemePropertiesForm } from '../SchemePropertiesForm';

export const SchemeFormItemContext = createContext(null);

/**
 * @typedef {object} SchemeFormFields
 * @property {string} schemeName
 * @property {string} schemeDescription
 * @property {import('@/types').ProductProperties} [product]
 * @property {Array} properties
 */

/**
 * @typedef {object} SchemeFormProps
 * @property {import('react-hook-form').SubmitHandler<SchemeFormFields>} [onSubmitData]
 * @property {boolean} [loading]
 * @property {boolean} [isReadOnly]
 * @property {GroupV2} [scheme]
 */

/**
 * @param {SchemeFormProps} props
 */
export function SchemeForm(props) {
  const { scheme, onSubmitData, loading = false, isReadOnly = false } = props;

  const navigate = useNavigate();
  const { t } = useTranslation();

  const {
    schemeId,
    layoutLoading,
    deployedAiContainer,
    deployedTriggers,
    deployedFirmwares,
    groupSettings,
  } = useContext(SchemeFormContext);

  const { result: products, loading: productLoading } = useFullProductList();

  const [settings, setSettings] = useState(null);
  const [productInputs, setProductInputs] = useState([]);

  const form = useForm({
    mode: 'all',
    shouldUnregister: false,
    defaultValues: {
      schemeName: scheme?.name || '',
      schemeDescription: scheme?.groupDescription || '',
      product: null,
      properties: [],
    },
  });

  const {
    control,
    formState: { dirtyFields },
  } = form;

  const selectedProduct = useWatch({ control, name: `product` });

  /** @param {SchemeFormFields} data */
  const prepareAndSubmit = (data) => {
    if (!onSubmitData) return;
    data.schemeName = trimString(data.schemeName);
    data.schemeDescription = trimString(data.schemeDescription);
    onSubmitData(data);
  };

  useEffect(() => {
    form.setValue('schemeName', scheme?.name || '');
    form.setValue('schemeDescription', scheme?.groupDescription || '');
    if (scheme?.id && products?.length > 0) {
      const product = products?.find((item) => item.productId === scheme?.associatedTypeId);
      product && form.setValue('product', product);
    }
  }, [
    scheme?.id,
    scheme?.name,
    scheme?.groupDescription,
    products,
    scheme?.associatedTypeId,
    form,
  ]);

  useEffect(() => {
    if (!schemeId || productInputs.length === 0 || layoutLoading) return;

    const formattedData = transformAndFormatData(
      deployedAiContainer,
      deployedTriggers,
      deployedFirmwares,
      groupSettings,
      productInputs
    );

    if (formattedData.length > 0) {
      form.setValue('properties', formattedData);
    }
  }, [
    form,
    layoutLoading,
    schemeId,
    productInputs,
    deployedAiContainer,
    deployedTriggers,
    deployedFirmwares,
    groupSettings,
  ]);

  useEffect(() => {
    if (selectedProduct?.inputs?.length > 0) {
      const audioVideoInputCodes = selectedProduct.inputs.filter((item) =>
        ['AUDIO', 'VIDEO'].includes(item.inputType)
      );
      setProductInputs(audioVideoInputCodes);
    }
  }, [selectedProduct?.inputs]);

  useEffect(() => {
    const simplifiedSettings = groupSettingsByCategory();
    setSettings(simplifiedSettings);
  }, []);

  if (loading || layoutLoading) {
    return (
      <CenterBox sx={{ mt: '100px' }}>
        <CircularProgress />
      </CenterBox>
    );
  }

  return (
    <SchemeFormItemContext.Provider
      value={{
        settings,
        productInputs,
      }}
    >
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(prepareAndSubmit)} autoComplete="off" noValidate>
          {isReadOnly ? (
            <Box
              display="grid"
              gridTemplateColumns={{ sm: '1fr', md: '1fr 1fr 1fr 1fr' }}
              gap="10px"
              mt={2}
            >
              <Box>
                <Typography variant="subtitle2" lineHeight="18px">
                  Scheme Name
                </Typography>
                <Typography variant="subtitle1" fontWeight={500} lineHeight="18px">
                  {scheme?.name}
                </Typography>
              </Box>
              <Box>
                <Typography variant="subtitle2" lineHeight="18px">
                  Product
                </Typography>
                <Typography variant="subtitle1" fontWeight={500} lineHeight="18px">
                  {selectedProduct?.productName}
                </Typography>
              </Box>
              <Box>
                <Typography variant="subtitle2" lineHeight="18px">
                  Description
                </Typography>
                <Typography variant="subtitle1" fontWeight={500} lineHeight="18px">
                  {scheme?.groupDescription || '...'}
                </Typography>
              </Box>
            </Box>
          ) : (
            <Box
              display="grid"
              gridTemplateColumns={{ sm: '1fr', md: '1fr 1fr 1fr' }}
              gap="10px"
              mt={2}
              mb={5}
            >
              <CustomInput
                disabled={isReadOnly}
                name="schemeName"
                label={t(T['scheme.form.group.name.label'])}
                placeholder={t(T['scheme.form.group.name.placeholder'])}
                rules={{ required: t(T['scheme.form.group.name.hint']) }}
              />
              <CustomSelect
                name="product"
                label={t(T['scheme.form.select.product.label'])}
                loading={productLoading}
                options={products}
                disabled={!!scheme || isReadOnly}
                getKey="productId"
                getLabel="productName"
                placeholder={t(T['scheme.form.select.product.placeholder'])}
                rules={{ required: t(T['scheme.form.select.product.hint']) }}
              />
              <CustomInput
                disabled={isReadOnly}
                name="schemeDescription"
                label={t(T['pages.administration.deviceGroups.form.description.name'])}
                placeholder={t(T['scheme.form.description.placeholder'])}
              />
            </Box>
          )}

          <SchemePropertiesForm isReadOnly={isReadOnly} />

          {!isReadOnly && (
            <Box display={'flex'} justifyContent={'flex-end'} gap={'5px'} mt={4}>
              <Button variant="text" onClick={() => navigate('/smarter-ai/schemes')}>
                {t(T['button.cancel'])}
              </Button>
              <Button
                disabled={loading || !Object.keys(dirtyFields).length}
                variant="contained"
                type="submit"
              >
                {loading && <CircularProgress color="inherit" size={16} sx={{ mr: '5px' }} />}
                {scheme ? t(T['button.update']) : t(T['button.save'])}
              </Button>
            </Box>
          )}
        </form>
      </FormProvider>
    </SchemeFormItemContext.Provider>
  );
}
