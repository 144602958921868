import { STATUS_LIST } from '@/assets/constants/table';
import {
  TRIGGER_ARITHMETIC_OPERATORS,
  TRIGGER_BRACKETS,
  TRIGGER_CLOSE_BRACKETS,
  TRIGGER_FORMULAS,
  TRIGGER_LOGICAL_OPERATORS,
  TRIGGER_MATH_OPERATORS,
  TRIGGER_OPEN_BRACKETS,
  TRIGGER_OPERATION_OPERATORS,
  TRIGGER_TRIGONOMETRY_OPERATORS,
} from '@/assets/triggers';

import {
  EVENTS_N_OFF_ICON,
  TRIGGER_COLLISIONS,
  TRIGGER_DISTRACTION,
  TRIGGER_DRIVER_EATING,
  TRIGGER_DRIVER_ON_PHONE,
  TRIGGER_DRIVER_SMOKING,
  TRIGGER_ENGINE_COOLANT,
  TRIGGER_HARSH_ABC,
  TRIGGER_NO_SEATBELT,
  TRIGGER_PEDESTRIAN,
  TRIGGER_RED_LINE_VIOLATION,
  TRIGGER_ROAD_CONDITION,
  TRIGGER_ROLLING_STOP,
  TRIGGER_SPEEDING,
  TRIGGER_STOP_SIGN,
  TRIGGER_STRADDLING,
  TRIGGER_TAILGATING,
  TRIGGER_YELLOW_LINE_VIOLATION,
} from '@/assets/constants/images';

import { DRAFT_TRIGGER_DEPLOYMENT_TENANTS } from '../user-access';

/**
 *  Format Trigger Type
 * @param {string} name
 * @returns
 */

export const formatTriggerType = (name) => {
  if (name === 'BASIC') return 'Job';
  else if (name === 'COMPLEX') return 'Workflow';
  else if (name === 'EXPRESSION') return 'Expression';
  else return name;
};

/**
 * Format Trigger Media Type
 * @param {string} type
 * @returns
 */

export const formatMediaType = (type) => {
  if (type === 'NONE') return 'None';
  if (type === 'VIDEO') return 'Video';
  if (type === 'SNAPSHOT') return 'Snapshot';
  if (type === 'SNAPSHOT_AND_VIDEO') return 'Snapshot and Video';
  return type;
};

// export const formatMediaType = (type) => {
//   if (type === 'NONE') return 'None';
//   if (type === 'VIDEO' || type === 'NO_SNAPSHOT') return 'Video';
//   if (type === 'SNAPSHOT' || type === 'NO_VIDEO') return 'Snapshot';
//   if (type === 'SNAPSHOT_AND_VIDEO' || type === 'ALL') return 'Snapshot and Video';
//   return type;
// };

/**
 * Bracket CLosing Check
 * @param {string} expr
 * @returns
 */

export function bracketClosingCheck(expr) {
  const holder = [];
  const openBrackets = ['(', '{', '['];
  const closedBrackets = [')', '}', ']'];
  for (let letter of expr) {
    // loop trought all letters of expr
    if (openBrackets.includes(letter)) {
      // if its oppening bracket
      holder.push(letter);
    } else if (closedBrackets.includes(letter)) {
      // if its closing
      const openPair = openBrackets[closedBrackets.indexOf(letter)]; // find its pair
      if (holder[holder.length - 1] === openPair) {
        // check if that pair is the last element in the array
        holder.splice(-1, 1); // if so, remove it
      } else {
        // if its not
        holder.push(letter);
        break; // exit loop
      }
    }
  }
  return holder.length === 0; // return true if length is 0, otherwise false
}

/**
 * Insert substring in a string in a given position
 * @param {string} str
 * @param {string} sub
 * @param {number} pos
 * @returns
 */

export function insertTextAt(str, sub, pos) {
  return `${str.slice(0, pos)}${sub}${str.slice(pos)}`;
}

/**
 * Format Label Data for Trigger dropdown
 * @param {Array<TriggerClassification>} labels
 * @returns
 */

export function formatLabelData(labels) {
  const formattedLabels = labels
    ?.filter((item) => !parseInt(item.name))
    ?.map((item) => ({ key: item.name, value: item.name }));
  return uniqueArrayOfObject(formattedLabels);
}

/**
 * Remove Duplicate data from array
 * @param {Array<object>} array
 * @returns
 */

export function uniqueArrayOfObject(array) {
  return [...new Map(array?.map((item) => [item['value'], item])).values()];
}

/**
 * Adding BETWEEN formula with string by position wise
 * @param {string} expression
 * @param {string} selectedString
 * @returns
 */

export function handleTriggerBetweenFormula(expression, selectedString) {
  // if (selectedString.startsWith('BETWEEN')) {
  //   if (expression === '' || expression.startsWith('/b')) {
  //     return 'Condition_1 BETWEEN(0,1) Condition_2';
  //   } else {
  //     return 'BETWEEN(0,1) Condition_2';
  //   }
  // }
  return selectedString;
}

/**
 * Find Expression associate labels
 * @param {Array<TriggerClassification>} labels
 * @param {string} expression
 * @returns
 */

export function findExpressionLabels(labels, expression) {
  //checking some character and space to split the expression
  const rawExpressionArray = expression?.split(/[*,/,-,+,>,=,<,==,!,),(,{,}, ]/);
  const expressionArray = rawExpressionArray?.filter(Boolean);
  const expressionLabels = [];
  for (let i = 0; i < labels?.length; i++) {
    for (let j = 0; j < expressionArray?.length; j++) {
      const expressionItem = expressionArray[j]?.trim();
      //checking name contains at least One letter
      if (!/[a-zA-Z]/.test(expressionItem)) {
        continue;
      }
      if (labels[i].name === expressionItem) {
        if (!expressionLabels?.includes(labels[i])) {
          expressionLabels.push(labels[i]);
        }
      }
    }
  }
  return expressionLabels;
}

/**
 * Replace string with another by position number
 * @param {string} expression
 * @param {number} lastCursorPos
 * @param {string} selectedVal
 * @returns
 */

export function replaceStringAtPosition(expression, lastCursorPos, selectedVal) {
  const lastWord = expressionLastWordAtPosition(expression, lastCursorPos);
  const position = lastCursorPos - lastWord.length;
  return expression.replace(
    expression.substring(position < 0 ? 0 : position, lastCursorPos),
    selectedVal
  );
}

/**
 * Checking new selected value contains special formula or not
 * @param {string} lastWord
 * @param {string} selectedValue
 * @returns
 */

export function isSelectedValueContainsSpecialFormula(lastWord, selectedValue) {
  return (
    (lastWord !== '' && lastWord !== '(' && selectedValue.toLowerCase().includes(lastWord)) ||
    lastWord.startsWith('/m') ||
    lastWord.startsWith('/t') ||
    lastWord.startsWith('/f') ||
    lastWord.startsWith('/c') ||
    lastWord.startsWith('/b') ||
    lastWord.startsWith('/p') ||
    lastWord.startsWith('/s') ||
    lastWord === '/math' ||
    lastWord === '/trigonometry' ||
    lastWord === '/for' ||
    lastWord === '/cross_limit' ||
    lastWord === '/filter' ||
    lastWord === '/persistent' ||
    lastWord === '/smooth'
  );
}

/**
 * Get the last word from a expression by position
 * @param {string} expression
 * @param {number} lastCursorPos
 * @returns
 */

export function expressionLastWordAtPosition(expression, lastCursorPos) {
  return expression?.trim().substring(0, lastCursorPos).trim().split(' ').pop()?.toLowerCase();
}

/**
 * Get the last character from a expression by position
 * @param {string} expression
 * @param {number} lastCursorPos
 * @returns
 */

export function expressionLastCharAtPosition(expression, lastCursorPos) {
  return expression?.trim().substring(0, lastCursorPos).trim().split('').pop()?.toLowerCase();
}

/**
 * Get expression cursor position available options
 * @param {string} expression
 * @param {Array.<{key: Number, value: String}>} formattedLabels
 * @param {*} lastCursorPos
 * @returns
 */

//TODO:: Need to refactor
export function expressionNextOptions(expression, formattedLabels, lastCursorPos) {
  const lastWord = expressionLastWordAtPosition(expression, lastCursorPos);
  const lastChar = expressionLastCharAtPosition(expression, lastCursorPos);

  // check expression last word start with /m, if true return math functions
  if (lastWord.startsWith('/m')) {
    return TRIGGER_MATH_OPERATORS;
  }

  // check expression last word start with /t, if true return trigonometry functions
  if (lastWord.startsWith('/t')) {
    return TRIGGER_TRIGONOMETRY_OPERATORS;
  }

  //check if expression last word start with our defined (TRIGGER_FORMULAS) formulas
  if (
    lastWord.startsWith('/f') ||
    lastWord.startsWith('/b') ||
    lastWord.startsWith('/c') ||
    lastWord.startsWith('/s') ||
    lastWord.startsWith('/p')
  ) {
    return TRIGGER_FORMULAS;
  }

  //check last item is label or not
  const isLastItemLabel = formattedLabels?.some((item) =>
    lastWord.includes(item.value.toLowerCase())
  );

  const isLastDigitNumber = !isNaN(parseInt(lastChar));

  const isEmptyExpression = expression === '';

  const isBracketClosed = bracketClosingCheck(expression);

  if (isLastDigitNumber) {
    const arrays = [
      TRIGGER_OPERATION_OPERATORS,
      TRIGGER_ARITHMETIC_OPERATORS,
      TRIGGER_LOGICAL_OPERATORS,
      TRIGGER_FORMULAS,
      TRIGGER_MATH_OPERATORS,
      TRIGGER_TRIGONOMETRY_OPERATORS,
    ];
    return arrays.flat();
  }

  if (isLastItemLabel) {
    if (!isBracketClosed) {
      return [
        TRIGGER_CLOSE_BRACKETS,
        TRIGGER_OPERATION_OPERATORS,
        TRIGGER_ARITHMETIC_OPERATORS,
        TRIGGER_LOGICAL_OPERATORS,
      ].flat();
    }
    return [
      TRIGGER_OPERATION_OPERATORS,
      TRIGGER_ARITHMETIC_OPERATORS,
      TRIGGER_LOGICAL_OPERATORS,
    ].flat();
  }

  //check last item is operator or not
  const isLastItemOperator = [
    TRIGGER_OPERATION_OPERATORS,
    TRIGGER_ARITHMETIC_OPERATORS,
    TRIGGER_LOGICAL_OPERATORS,
  ]
    .flat()
    .some((item) => item.value.toLowerCase().includes(lastWord));

  if (isEmptyExpression || ['AND', 'OR'].includes(lastWord) || isLastItemOperator) {
    return [TRIGGER_OPEN_BRACKETS, TRIGGER_FORMULAS, formattedLabels].flat();
  }

  //check last item is open bracket or not
  const isLastCharOpenBracket = TRIGGER_OPEN_BRACKETS.some((item) => item.value === lastChar);
  if (isLastCharOpenBracket || lastChar === ',') {
    const arrays = [
      TRIGGER_FORMULAS,
      TRIGGER_MATH_OPERATORS,
      TRIGGER_TRIGONOMETRY_OPERATORS,
      formattedLabels,
    ];
    return arrays.flat();
  }

  //check bracket close or not
  const isLastItemCloseBracket = TRIGGER_CLOSE_BRACKETS.some((item) => item.value === lastChar);
  if (isLastItemCloseBracket) {
    return [
      TRIGGER_OPERATION_OPERATORS,
      TRIGGER_ARITHMETIC_OPERATORS,
      TRIGGER_LOGICAL_OPERATORS,
      TRIGGER_FORMULAS,
      TRIGGER_MATH_OPERATORS,
      TRIGGER_TRIGONOMETRY_OPERATORS,
      formattedLabels,
    ].flat();
  }

  // if no above condition matches, then return all items
  const arrays = [
    TRIGGER_OPERATION_OPERATORS,
    TRIGGER_ARITHMETIC_OPERATORS,
    TRIGGER_LOGICAL_OPERATORS,
    TRIGGER_BRACKETS,
    TRIGGER_FORMULAS,
    TRIGGER_MATH_OPERATORS,
    TRIGGER_TRIGONOMETRY_OPERATORS,
    formattedLabels,
  ].flat();

  return lastWord ? arrays.filter((item) => item.value?.toLowerCase().includes(lastWord)) : arrays;
}

/**
 * @param {AccountLoginResponse['teamList']} teamList
 * @param {string} tenantId
 * @returns {Array<string>}
 */
export function accessibleTriggerTypeByUser(teamList, tenantId) {
  const tenantAccess = DRAFT_TRIGGER_DEPLOYMENT_TENANTS.includes(tenantId);
  const userAccess = teamList.find(
    (x) => x === 'AI_TEAM' || x === 'PRODUCT_MANAGER' || x === 'FRONT_END_TEAM'
  );
  if (userAccess && tenantAccess) {
    return [STATUS_LIST.RELEASED, STATUS_LIST.DRAFT];
  } else {
    return [STATUS_LIST.RELEASED];
  }
}

export const getTriggerImageByName = (triggerName) => {
  const name = triggerName?.toLowerCase();
  if (!name) return EVENTS_N_OFF_ICON;

  if (name.includes('distraction')) return TRIGGER_DISTRACTION;
  else if (name.includes('phone')) return TRIGGER_DRIVER_ON_PHONE;
  else if (name.includes('harsh')) return TRIGGER_HARSH_ABC;
  else if (name.includes('collision')) return TRIGGER_COLLISIONS;
  else if (name.includes('rolling')) return TRIGGER_ROLLING_STOP;
  else if (name.includes('speeding')) return TRIGGER_SPEEDING;
  else if (name.includes('straddling')) return TRIGGER_STRADDLING;
  else if (name.includes('tailgating')) return TRIGGER_TAILGATING;
  else if (name.includes('collision')) return TRIGGER_COLLISIONS;
  else if (name.includes('eating')) return TRIGGER_DRIVER_EATING;
  else if (name.includes('smoking')) return TRIGGER_DRIVER_SMOKING;
  else if (name.includes('coolant')) return TRIGGER_ENGINE_COOLANT;
  else if (name.includes('seatbelt')) return TRIGGER_NO_SEATBELT;
  else if (name.includes('pedestrian')) return TRIGGER_PEDESTRIAN;
  else if (name.includes('red')) return TRIGGER_RED_LINE_VIOLATION;
  else if (name.includes('yellow')) return TRIGGER_YELLOW_LINE_VIOLATION;
  else if (name.includes('condition')) return TRIGGER_ROAD_CONDITION;
  else if (name.includes('stop')) return TRIGGER_STOP_SIGN;
  else return EVENTS_N_OFF_ICON;
};
