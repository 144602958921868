import { Box, Typography } from '@mui/material';
import { EntityWiseTriggersNotification } from './@components/EntityWiseTriggersNotification';
import { FilterContextProvider } from '@/web/@components/FilterContext';
import { CollapsibleFilterBox } from './@components/SearchBox';

export function TriggerConfiguration() {
  return (
    <Box display="flex" justifyContent="space-around">
      <Box p={2.5} width={{ xs: '100%', md: '800px' }}>
        <FilterContextProvider>
          <Box
            display="flex"
            justifyContent="space-between"
            gap={1}
            mb={1}
            flexDirection={{ xs: 'column', md: 'row' }}
          >
            <Typography
              variant="body2"
              fontSize="1rem"
              fontWeight={'medium'}
              mb={1}
              color="#596A82"
            >
              My Notifications
            </Typography>
            <CollapsibleFilterBox expandWidth={{ xs: '100%', md: '400px' }} />
          </Box>
          <Box mb={10}>
            <EntityWiseTriggersNotification />
          </Box>
        </FilterContextProvider>
      </Box>
    </Box>
  );
}
