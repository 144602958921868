import api from '@/api';
import { selectMainUserId, selectSecretToken } from '@/store/auth';
import { NoteAltOutlined } from '@mui/icons-material';
import { Box, Button } from '@mui/material';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { createPortal } from 'react-dom';
import { useSelector } from 'react-redux';
import { CoachingEndpointDetailsContext } from '../../@context/DriverDetailsContext';
import { UserNoteBox } from './UserNoteBox';
import { UserNoteCreateBox } from './UserNoteCreateBox';
import { toastWarning } from '@/utils/toaster';

/**
 * @typedef {object} CoachingCommentAreaProps
 * @property {HTMLElement} player
 * @property {import('react').MouseEventHandler<HTMLElement>} [onFinish]
 */
/** @param {CoachingCommentAreaProps} props */
export function CoachingCommentArea(props) {
  const { player, onFinish } = props;

  const secretToken = useSelector(selectSecretToken);
  const loggedUserId = useSelector(selectMainUserId);

  const {
    users,
    visitedCount,
    startIndex,
    totalCount,
    selectedEvent,
    currentSessionId,
    updateSelection,
  } = useContext(CoachingEndpointDetailsContext);

  const authUser = useMemo(
    () => users?.find((u) => u.userId === loggedUserId),
    [loggedUserId, users]
  );

  /** @type {StateVariable<Element>} */
  const [reportArea, setReportArea] = useState();
  /** @type {StateVariable<boolean>} */
  const [createNote, setCreateNote] = useState(false);
  /** @type {StateVariable<Array<CoachingNotesDto>>} */
  const [eventNotes, setEventNotes] = useState([]);
  /** @type {StateVariable<boolean>} */
  const [loading, setLoading] = useState(false);

  const handleNoteCreate = (note) => {
    setLoading(true);
    addNewEventNote(note);
  };

  const sortByTimestamp = (a, b) => b.postedTimestamp - a.postedTimestamp;

  const addNewEventNote = (note) => {
    try {
      const request = api.ac.v5.coaching.sessions
        .$sessionId(currentSessionId)
        // @ts-ignore
        .events.$eventId(selectedEvent?.id)
        .notes.$post({
          headers: {
            'Authorization': secretToken,
            'Content-Type': 'application/json',
          },
          params: {
            notesPosterType: 'COACH',
          },
          data: note?.toString(),
        });

      request
        ?.process()
        ?.then((suc) => {
          setTimeout(() => {
            getEventCoachingNotes();
          }, 500);
        })
        .catch((err) => console.log(err))
        .finally(() => {
          setCreateNote(false);
          setLoading(false);
        });
    } catch (ex) {
      setLoading(false);
    }
  };

  const handleDeleteItem = (item) => {
    try {
      setLoading(true);
      const request = api.ac.v5.coaching.sessions
        .$sessionId(item?.sessionId)
        .events.$eventId(item?.eventId)
        .notes.$noteId(item?.id)
        .$delete({
          headers: {
            Authorization: secretToken,
          },
        });
      request
        ?.process()
        ?.then((data) => {
          setTimeout(() => {
            getEventCoachingNotes();
          }, 500);
        })
        .catch((err) => {
          console.error(err);
          toastWarning('Failed to delete note!');
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (ex) {
      toastWarning('Failed to delete note!');
      setLoading(false);
    }
  };

  const getEventCoachingNotes = useCallback(() => {
    if (!selectedEvent?.id || !currentSessionId) return;
    const request = api.ac.v5.coaching.sessions
      .$sessionId(currentSessionId)
      // @ts-ignore
      .events.$eventId(selectedEvent?.id)
      .notes.$get({
        headers: {
          Authorization: secretToken,
        },
      });

    request
      ?.process()
      ?.then((data) => {
        setEventNotes(data?.notesList.sort(sortByTimestamp));
      })
      .catch((err) => console.error(err))
      .finally(() => {
        setLoading(false);
      });
  }, [selectedEvent?.id, currentSessionId, secretToken]);

  useEffect(() => {
    const update = () => {
      setReportArea(player.querySelector('#report-area'));
    };
    update();
    const iid = setInterval(update, 100);
    return () => clearInterval(iid);
  }, [player]);

  useEffect(() => {
    setEventNotes([]);
    getEventCoachingNotes();
  }, [selectedEvent?.id, currentSessionId, getEventCoachingNotes]);

  if (!reportArea) {
    return null;
  }

  const hasNext = startIndex + 1 < totalCount;
  const hasPrev = startIndex > 0;

  return createPortal(
    <Box>
      <Box display="flex" gap={1} alignItems="center">
        <Button variant="outlined" onClick={() => setCreateNote(true)}>
          <NoteAltOutlined /> Create Note
        </Button>
        <Box flex={1} textAlign="center" fontStyle="italic">
          Coachable Event {startIndex + 1} of {totalCount}
        </Box>
        <Button
          variant="contained"
          disabled={!hasPrev}
          onClick={() => updateSelection(startIndex - 1)}
        >
          Previous
        </Button>
        {visitedCount < totalCount || startIndex + 1 < totalCount ? (
          <Button
            variant="contained"
            disabled={!hasNext}
            onClick={() => updateSelection(startIndex + 1)}
          >
            Next
          </Button>
        ) : (
          <Button variant="contained" onClick={onFinish}>
            Finish Coaching
          </Button>
        )}
      </Box>
      <Box pt={1} sx={{ '&> *': { mb: 1 } }}>
        {createNote && (
          <UserNoteCreateBox
            onAdd={(note) => {
              handleNoteCreate(note);
            }}
            onClose={() => {
              setCreateNote(false);
            }}
            userName={authUser?.name || authUser?.email}
            loading={loading}
          />
        )}

        {eventNotes?.map((item, index) => {
          const user = users?.find((u) => u.userId === item?.posterUserId);
          return (
            <Box key={index}>
              <UserNoteBox
                loading={loading}
                avatar={null}
                username={user?.name || user?.email}
                note={item?.text}
                time={item?.postedDate}
                enableAction={loggedUserId === user?.userId}
                onDelete={() => handleDeleteItem(item)}
              />
            </Box>
          );
        })}
      </Box>
    </Box>,
    reportArea
  );
}
