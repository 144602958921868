import api from '@/api';
import { selectSecretToken, selectTenantId } from '@/store/auth';
import { generateUniqueKey } from '@/utils/table';
import { toastSuccess, toastWarning } from '@/utils/toaster';
import { InfiniteScrollView } from '@/web/@components/InfiniteScrollView';
import { InfiniteScrollTableView } from '@/web/@components/InfiniteScrollView/InfiniteScrollTableView';
import { ItemsListHeader } from '@/web/@components/ItemsListHeader';
import { PaginatedTableContextProvider } from '@/web/@components/PaginatedTableContext';
import { Box } from '@mui/material';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { ACTION_ITEMS } from './actions';
import { AI_ACCELERATOR_TABLE_COLUMNS } from './columns';

export function AiAcceleratorListPage() {
  const tenantId = useSelector(selectTenantId);
  const secretToken = useSelector(selectSecretToken);
  const [refresh, setRefresh] = useState(0);

  /** @param {AIAccelerator} item  */
  const deleteItem = async (item) => {
    try {
      const request = api.ac.v1.tenant.aiaccelerator.$delete({
        params: {
          tenantId,
          secretToken,
          codeName: item.codeName,
        },
      });
      await request.process();
      toastSuccess('Success', `Successfully deleted <b>${item.name}</b>`);
    } catch (e) {
      toastWarning('Error', `Failed to delete <b>${item.name}</b>`);
    }
  };

  /** @type {import('@/web/@components/ItemsListHeader').ItemsListHeaderProps<AIAccelerator>['onAction']} */
  const handleAction = async (type, selectedItems) => {
    if (type === 'DISCONTINUE') {
      await Promise.all(selectedItems.map(deleteItem));
      setRefresh(Date.now());
    }
  };

  return (
    <Box px={2.5} mt={2} mb={5}>
      <PaginatedTableContextProvider>
        <ItemsListHeader title="AI Accelerators" actions={ACTION_ITEMS} onAction={handleAction} />
        <InfiniteScrollView
          key={generateUniqueKey([tenantId, secretToken, refresh])}
          fetcher={async ({ limit, signal, token }) => {
            const request = api.ac.v1.tenant.aiaccelerator.list.$get({
              signal,
              params: {
                tenantId,
                secretToken,
                pageSize: limit,
                continuationToken: token,
              },
            });
            const result = await request.process();
            return {
              result: result.aiAccelerators,
              token: result.continuationToken,
            };
          }}
          renderList={(state) => (
            <InfiniteScrollTableView
              state={state}
              columns={AI_ACCELERATOR_TABLE_COLUMNS}
              sx={{ height: 'calc(100vh - 150px)' }}
            />
          )}
        />
      </PaginatedTableContextProvider>
    </Box>
  );
}
