import { SI_TO_IMPERIAL } from '@/assets/constants/unit-system';
import { isCameraInParkingMode } from '@/utils/cameras';
import { formatTimestamp } from '@/utils/datetime';
import { CircleBox } from '@/web/@components/CircleBox';
import { IconLabelItem } from '@/web/@components/IconLabelItem';
import { SnapshotImageSlider } from '@/web/@components/SnapshotImageSlider';
import { Box, IconButton, Typography } from '@mui/material';
import { median } from 'd3';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

/**
 * @typedef {object} ThumbnailPopperContentProps
 * @property {EndpointDetailsResponseV4} camera
 * @property {number} [timestamp]
 * @property {number} [speed]
 * @property {boolean} [imperial]
 * @property {boolean} [isInTripPage]
 * @property {{[key: string]: VideoThumbnailData}} thumbnails
 * @property {import('react').EventHandler<import('react').SyntheticEvent<HTMLElement>>} onClose
 */

/** @param {ThumbnailPopperContentProps & import('@mui/material').BoxProps} props */
export function ThumbnailPopperContent(props) {
  const { thumbnails, camera, imperial, speed, isInTripPage = false, onClose, ...boxProps } = props;

  const navigate = useNavigate();
  const images = useMemo(
    () =>
      Object.entries(thumbnails)
        .sort()
        .map((a) => a[1].src),
    [thumbnails]
  );

  const online = useMemo(() => {
    return camera.isOnline && !isCameraInParkingMode(camera.parkingModeStatus);
  }, [camera]);

  const timestamp = useMemo(
    () => Math.floor(median(Object.values(thumbnails).map((x) => x.ts))),
    [thumbnails]
  );

  /** @type {import('react').MouseEventHandler<HTMLElement>} */
  const handleClick = (e) => {
    onClose(e);
    const url = `/cameras/live?id=${camera.id}&time=${timestamp}`;
    if (e.ctrlKey || e.metaKey) {
      window.open(url, '_blank').focus();
    } else {
      navigate(url);
    }
  };

  const speedText = useMemo(() => {
    const unitData = SI_TO_IMPERIAL['km/h'];
    if (imperial) {
      if (!speed) return `-- ${unitData.to}`;
      return `${Math.round(unitData.factor * speed)} ${unitData.to}`;
    } else {
      if (!speed) return '-- km/h';
      return `${Math.round(speed)} km/h`;
    }
  }, [imperial, speed]);

  return (
    <Box
      p="1px"
      position="relative"
      boxShadow="0 0 3px rgba(0, 0, 0, 0.15)"
      onClick={online && timestamp ? handleClick : null}
      {...boxProps}
    >
      <SnapshotImageSlider
        perPageItem={1}
        images={images}
        width={isInTripPage ? { xs: 235, md: 300 } : { xs: 135, md: 200 }}
        style={{
          cursor: online ? 'pointer' : 'not-allowed',
          aspectRatio: (2 * 16) / 9,
        }}
      />
      <Box display="row" px={1} py={0.5} gap={1} justifyContent="space-between">
        {Boolean(speed) && (
          <Box display="flex" gap={1} alignItems="center">
            <Typography variant="subtitle2">Speed</Typography>
            <IconLabelItem
              iconTitle="Speed"
              textAlign="left"
              fontSize="0.875rem"
              fontWeight={500}
              title={speedText}
            />
          </Box>
        )}
        <Typography variant="subtitle2" fontWeight={400} fontSize={isInTripPage ? '13px' : '10px'}>
          {formatTimestamp(timestamp)}
        </Typography>
      </Box>

      <IconButton size="small" onClick={onClose} sx={{ position: 'absolute', top: 0, right: 0 }}>
        <CircleBox size="16px" fontSize="1.25rem" bgcolor="transparent">
          &times;
        </CircleBox>
      </IconButton>
    </Box>
  );
}
