import api from '@/api';
import { isDev } from '@/config';
import { selectSecretToken, selectTenantId } from '@/store/auth';
import { endOfTheDay, startOfTheDay } from '@/utils/datetime';
import { CenterBox } from '@/web/@components/CenterBox';
import { IconMessageBox } from '@/web/@components/IconMessageBox';
import { Box, CircularProgress, Tab, Tabs, useMediaQuery, useTheme } from '@mui/material';
import { Fragment, useCallback, useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { DriversByPersona } from './@components/DriversByPersona';
import { EndpointByPersona } from './@components/EndpointByPersona';
import ScoringLineChart from './@components/ScoringLineChart';
import { ScoringFilterContext } from './@context/ScoringFilterContext';
import { SafetyScoreLayoutContext } from './@layouts/SafetyScoreLayout';
import { debounce } from 'lodash';

export function SafetyScoring() {
  const tenantId = useSelector(selectTenantId);
  const secretToken = useSelector(selectSecretToken);

  const theme = useTheme();
  const xlAndUp = useMediaQuery(theme.breakpoints.up('xl'));

  const { personaList } = useContext(SafetyScoreLayoutContext);

  const {
    filteredData,
    setSelectedFilterTypes,
    selectedParentType,
    setSelectedParentType,
    setSubmitCount,
  } = useContext(ScoringFilterContext);

  /** @type {StateVariable<Array<{data: Array<ScoreForDate>, persona: PersonaDto}>>} */
  const [scores, setScores] = useState([]);
  /** @type {StateVariable<boolean>} */
  const [loading, setLoading] = useState(true);

  const handleChange = (event, newValue) => {
    setSelectedParentType(newValue);
    setSubmitCount(2);
  };

  const fetchDriverChartDataByPersona = useCallback(
    async (personaId, signal) => {
      try {
        const request = api.ac.v5.score.persona
          .$personaId(personaId)
          .tenant.$tenantId(tenantId)
          .driver.aggregated.$get({
            signal,
            headers: {
              Authorization: secretToken,
            },
            params: {
              driverIds: filteredData?.drivers?.map((driver) => driver?.driverId),
              fromScore: filteredData?.scoreRange?.from || 0,
              upToScore: filteredData?.scoreRange?.to || 100,
              fromTimestamp: startOfTheDay(filteredData?.dateRange?.from),
              toTimestamp: endOfTheDay(filteredData?.dateRange?.to),
            },
          });
        const result = await request.process();
        if (!result) return;
        const items = {
          persona: personaList?.find((p) => p.id === personaId),
          data: result?.list || [],
        };
        setScores((prev) => [...prev, items]);
        setLoading(false);
      } catch (ex) {
        console.error(ex);
      }
    },
    [secretToken, filteredData, tenantId, personaList]
  );

  const fetchEndpointChartDataByPersona = useCallback(
    async (personaId, signal) => {
      try {
        const request = api.ac.v5.score.persona
          .$personaId(personaId)
          .tenant.$tenantId(tenantId)
          .endpoint.aggregated.$get({
            signal,
            headers: {
              Authorization: secretToken,
            },
            params: {
              endpointIds: filteredData?.cameras?.map((camera) => camera?.endpointId),
              fromScore: filteredData?.scoreRange?.from || 0,
              upToScore: filteredData?.scoreRange?.to || 100,
              fromTimestamp: startOfTheDay(filteredData?.dateRange?.from),
              toTimestamp: endOfTheDay(filteredData?.dateRange?.to),
            },
          });
        const result = await request.process();
        if (!result) return;
        const items = {
          persona: personaList?.find((p) => p.id === personaId),
          data: result?.list || [],
        };
        setScores((prev) => [...prev, items]);
        setLoading(false);
      } catch (ex) {
        console.error(ex);
      }
    },
    [secretToken, filteredData, tenantId, personaList]
  );

  const fetchDriversScores = useCallback(
    async (signal) => {
      try {
        setScores([]);
        filteredData?.personas?.map((p) => {
          return fetchDriverChartDataByPersona(p?.id, signal);
        });
      } catch (ex) {}
    },
    [fetchDriverChartDataByPersona, filteredData]
  );

  const fetchEndpointScores = useCallback(
    async (signal) => {
      if (!filteredData) return;
      try {
        setScores([]);
        filteredData?.personas?.map((p) => {
          return fetchEndpointChartDataByPersona(p?.id, signal);
        });
      } catch (ex) {}
    },
    [fetchEndpointChartDataByPersona, filteredData]
  );

  useEffect(() => {
    setSelectedFilterTypes([selectedParentType]);
  }, [selectedParentType, setSelectedFilterTypes]);

  useEffect(() => {
    if (!selectedParentType) return;
    const aborter = new AbortController();
    setLoading(true);

    const debouncedLineChartAPI = debounce(() => {
      if (selectedParentType === 1) {
        fetchDriversScores(aborter.signal);
      } else if (selectedParentType === 2) {
        fetchEndpointScores(aborter.signal);
      }
    }, 500);

    debouncedLineChartAPI();

    return () => {
      setLoading(true);
      aborter.abort();
    };
  }, [tenantId, selectedParentType, fetchDriversScores, fetchEndpointScores]);

  return (
    <Box mx={2.5}>
      <Box>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          py={2}
          sx={{
            '& .MuiButtonBase-root': {
              textTransform: 'none',
            },
          }}
        >
          <Tabs
            value={selectedParentType}
            onChange={handleChange}
            aria-label="disabled tabs example"
            sx={{
              '.MuiTab-root.MuiTab-textColorPrimary.Mui-selected': {
                color: '#4177BF !important',
              },
              '.MuiTab-root.MuiTab-textColorPrimary.Mui-disabled': {
                color: '#A6AFBA !important',
              },
              '.MuiTabs-indicator': {
                backgroundColor: '#4177BF !important',
              },
              '.MuiTabs-scroller': {
                height: '40px',
              },
            }}
          >
            {isDev && <Tab key={1} value={1} label="By Driver" />}
            <Tab key={2} value={2} label="By Camera" />
          </Tabs>
        </Box>

        {!filteredData?.personas?.length ? (
          <CenterBox style={{ height: '400px' }}>
            <IconMessageBox
              size="150px"
              src="/images/empty/no-search-results.svg"
              message="No personas have been filtered!"
            />
          </CenterBox>
        ) : loading || scores?.length === 0 ? (
          <CenterBox style={{ height: xlAndUp ? 400 : 300 }}>
            <CircularProgress />
          </CenterBox>
        ) : (
          <ScoringLineChart scores={scores} />
        )}
      </Box>

      <Box mt={4} pb={'120px'}>
        {filteredData?.personas?.map((persona, index) => (
          <Fragment key={index}>
            {selectedParentType === 1 ? (
              <DriversByPersona persona={persona} />
            ) : (
              <EndpointByPersona persona={persona} />
            )}
          </Fragment>
        ))}
      </Box>
    </Box>
  );
}
