import { selectTeamList, selectTenantId, selectUserEmail, selectUserRole } from '@/store/auth';
import { filterByUserAccess } from '@/utils/user-access';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { SidebarTreeItem } from './SidebarTreeItem';

/**
 * @typedef {object} SidebarTreeProps
 * @property {Array<SidebarItem>} items
 * @property {number} [level]
 */

/** @param {SidebarTreeProps} props */
export function SidebarTree(props) {
  const { items, level = 0 } = props;

  const userRole = useSelector(selectUserRole);
  const userEmail = useSelector(selectUserEmail);
  const tenantId = useSelector(selectTenantId);
  const teamList = useSelector(selectTeamList);

  const visibleItems = useMemo(
    () => items?.filter(filterByUserAccess({ userRole, userEmail, tenantId, teamList })),
    [items, userRole, userEmail, tenantId, teamList]
  );

  return (
    <>
      {visibleItems.map((item) => (
        <SidebarTreeItem key={item.link} item={item} level={level} />
      ))}
    </>
  );
}
