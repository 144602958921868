import { T } from '@/assets/locales';
import { OrganizationPage } from '.';
import { OnboardingLogs } from './onboard-logs';
import { EDGE_TENSOR_REGEX } from '@/utils/user-access';

/** @type {Array<import('@/types').ExtendedRoute>} */
const routes = [
  {
    path: '',
    element: <OrganizationPage />,
  },
  {
    path: 'firmwares',
    children: require('./firmwares/routes').default,
    breadcrumb: T['navigation.firmwares'],
  },
  {
    path: 'device-batches',
    children: require('./device-batches/routes').default,
    breadcrumb: T['navigation.device.batches'],
  },
  {
    path: 'products',
    children: require('./products/routes').default,
    breadcrumb: T['navigation.products'],
  },
  {
    path: 'ai-accelerator',
    children: require('./ai-accelerator/routes').default,
    breadcrumb: T['navigation.ai.accelerator'],
  },
  {
    path: 'onboard-logs',
    element: <OnboardingLogs />,
    breadcrumb: 'Onboard Logs',
  },
  {
    path: 'triggers',
    children: require('../smarter-ai/triggers/routes').default,
    breadcrumb: T['navigation.triggers'],
    accessLevel: 'HIDDEN',
    emailAccess: [EDGE_TENSOR_REGEX],
    teamAccess: ['AI_TEAM', 'FRONT_END_TEAM', 'PRODUCT_MANAGER'],
  },
  {
    path: 'labels',
    children: require('./labels/routes').default,
    breadcrumb: T['navigation.operations.labels'],
  },
  {
    path: 'monitoring',
    children: require('./monitoring/routes').default,
    breadcrumb: T['navigation.monitoring'],
  },
  {
    path: 'scheme-management',
    children: require('./scheme-management/routes').default,
    breadcrumb: T['navigation.scheme.management'],
  },
];

export default routes;
