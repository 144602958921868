import { useRouteQuery } from '@/hooks/useRouteQuery';
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { TriggerConfigurationContext } from '../../@context/TriggerConfigurationContext';
import { TriggerForm } from '../TriggerForm';
import TriggerTable from '../TriggerTable';
import { AutoCompleteItemSelection } from '../AutoCompleteItemSelection';
import { CollapsibleSearch } from '@/web/@components/CollapsibleSearch';

export function TriggerConfigurationPage() {
  const { query, updateQuery } = useRouteQuery();
  const { triggers } = useContext(TriggerConfigurationContext);

  const theme = useTheme();
  const smAndUp = useMediaQuery(theme.breakpoints.up('sm'));

  /** @type {StateVariable<boolean>} */
  const [isEditMode, setIsEditMode] = useState(false);
  /** @type {StateVariable<any>} */
  const [selectedTrigger, setSelectedTrigger] = useState(null);

  const handleCancelEdit = () => {
    setIsEditMode(false);
    updateQuery({ id: null });
  };

  useEffect(() => {
    if (!query?.id) return;
    const selectedTrigger = triggers?.find((i) => i?.id === query?.id);
    if (selectedTrigger) {
      setSelectedTrigger(selectedTrigger);
      setIsEditMode(true);
    }
  }, [query?.id, triggers]);

  return (
    <Box py={2.5}>
      <Box pb={smAndUp ? 0 : 2}>
        <Typography variant="body2" fontSize="1rem" fontWeight={'medium'} mb={1} color="#596A82">
          {!isEditMode ? 'Select Entity' : selectedTrigger?.name}
        </Typography>

        <Box display={'flex'} gap={1} flexDirection={smAndUp ? 'row' : 'column'}>
          <Box width={{ xs: '100%', md: '60%' }}>
            <AutoCompleteItemSelection isEditMode={isEditMode} />
          </Box>
          {!isEditMode && (
            <Box width={{ xs: '100%', md: '40%' }}>
              <CollapsibleSearch collapsedText="Search Triggers" />
            </Box>
          )}
        </Box>
      </Box>

      {isEditMode ? (
        <TriggerForm onCancel={handleCancelEdit} triggerItem={selectedTrigger} />
      ) : (
        <TriggerTable />
      )}
    </Box>
  );
}
