import { captureVoltageOptions, periodicAwakeningFromParkingModeFreqOptions } from '../options';
import { FROM_TYPES, GROUP_SETTINGS_CATEGORIES } from '../constants';

/** @type {Array<GroupSettingsItems>} */
export const parkingSettings = [
  {
    title: 'Power Management',
    key: GROUP_SETTINGS_CATEGORIES.POWER,
    children: [
      {
        label: 'Parking Mode',
        key: 'parking',
        underline: true,
        children: [
          {
            label: 'Entering Parking Mode',
            key: 'entering-parking',
            secondaryUnderline: true,
            children: [
              {
                label: 'On ACC/Ignition Off',
                infoText:
                  'The camera will enter Parking Mode when the vehicle engine has been switched off. Please note that the ACC/Ignition wire has to be connected properly',
                key: 'parkingMode',
                type: FROM_TYPES.SWITCH,
              },
              {
                label: 'Parking.Delay.ACC',
                key: 'parkingDelay',
                type: FROM_TYPES.SLIDER,
                postText: 'min',
                min: 0,
                max: 30,
              },
              {
                label: 'Parking Mode With Vehicle Movement',
                infoText:
                  'The camera will enter Parking Mode when the vehicle has not been moving for the defined delay.',
                key: 'parkingOnVehicleMovement',
                type: FROM_TYPES.SWITCH,
              },
              {
                label: 'Parking.Delay.Movement',
                key: 'parkingDelayOnNoMovement',
                type: FROM_TYPES.SLIDER,
                postText: 'min',
                min: 0,
                max: 30,
              },
              {},
              {
                label: 'Movement Sensitivity',
                key: 'parkingSensitivityOnNoMovement',
                type: FROM_TYPES.SLIDER,
                postText: 'm/s²',
                min: 0.2,
                max: 5.0,
                step: 0.1,
              },
              {},
              {
                label: 'Distance Traveled',
                key: 'gpsSensitivityOnNoMovement',
                type: FROM_TYPES.SLIDER,
                postText: 'meter',
                min: 5,
                max: 500,
                step: 5,
              },
            ],
          },
          {
            label: 'Awakening from Parking Mode',
            key: 'camera-awakening',
            secondaryUnderline: true,
            children: [
              {
                label: 'On Vehicle Impact',
                key: 'exitParkingOnVehicleImpact',
                type: FROM_TYPES.SWITCH,
              },
              {
                label: 'Sensitivity',
                key: 'wakeUpThroughAccelerometer',
                type: FROM_TYPES.SLIDER,
                postText: 'g',
                min: 0,
                max: 3,
                step: 0.5,
              },
              {},
              {
                label: 'Impact Recording Length',
                key: 'wakeUpRecordingLength',
                type: FROM_TYPES.SLIDER,
                postText: 'sec',
                min: 0,
                max: 100,
              },

              {},
              {},
              {
                label: 'Cellular Awaking',
                key: 'exitParkingWithWakeUpSignal',
                type: FROM_TYPES.SWITCH,
              },
              {},
              {
                label: 'Periodic Awakening',
                key: 'periodicAwakeningFromParkingModeFreq',
                type: FROM_TYPES.SELECT,
                options: periodicAwakeningFromParkingModeFreqOptions,
              },
            ],
          },
        ],
      },

      {
        label: 'Vehicle & Battery Protection',
        key: 'protection',
        underline: true,
        children: [
          {
            label: '',
            key: 'voltage-sensitivity',
            children: [
              {
                label: 'Voltage Measurement Sensitivity',
                infoText:
                  'Defines how long the voltage should be below the threshold to trigger the Vehicle & Battery Protection mode.',
                key: 'parkingBatteryVoltSensitivity',
                type: FROM_TYPES.SLIDER,
                postText: 'ms',
                min: 0,
                max: 10000,
              },
            ],
          },
          {
            label: 'Capture Voltage Level',
            key: 'capture_voltage_hz',
            type: FROM_TYPES.SELECT,
            options: captureVoltageOptions,
          },
          {
            label: 'Parking mode on Low Voltage',
            infoText:
              'Vehicles might not start when its battery voltage is too low. The camera will go to Parking Mode to protect the vehicle capability to start on low battery voltage.',
            key: 'power-mode-on-low-voltage',
            children: [
              {
                label: 'Parking 12V Vehicle System',
                key: 'parkingAtBatteryVolt12VSystem',
                type: FROM_TYPES.SLIDER,
                postText: 'V',
                step: 0.1,
                min: 10,
                max: 14,
              },
              {
                label: 'Parking 24V Vehicle System',
                key: 'parkingAtBatteryVolt24VSystem',
                type: FROM_TYPES.SLIDER,
                postText: 'V',
                step: 0.1,
                min: 22,
                max: 25,
              },
            ],
          },
          {
            label: 'Power off on Very Low Voltage',
            infoText:
              'Vehicle batteries might get damaged if their voltage get very low. The camera will automatically power off when the battery voltage drops below a very low voltage threshold to protect the battery’s life.',
            key: 'power-off-on-very-low-voltage',
            children: [
              {
                label: 'Cut Off 12V Vehicle System',
                key: 'powerOffAtBatteryVolt12VSystem',
                type: FROM_TYPES.SLIDER,
                postText: 'V',
                step: 0.1,
                min: 6,
                max: 10,
              },
              {
                label: 'Cut Off 24V Vehicle System',
                key: 'powerOffAtBatteryVolt24VSystem',
                type: FROM_TYPES.SLIDER,
                postText: 'V',
                step: 0.1,
                min: 16,
                max: 20,
              },
            ],
          },
        ],
      },
    ],
  },
];
