import { ArrowDropDown } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary, Box } from '@mui/material';
import Typography from '@mui/material/Typography';
import { useState } from 'react';
import { SOFTWARE_TAB_ITEMS } from '../../@assets/softwares';

export function CameraSoftwarePage() {
  const [expanded, setExpanded] = useState('softwares');

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <Box
      p={2.5}
      px={3}
      mt={2}
      sx={{
        '& .MuiPaper-elevation': {
          border: '1px solid #c5d9f0',
          borderRadius: '5px',
        },
        'overflowY': 'auto',
      }}
      height="100%"
    >
      {SOFTWARE_TAB_ITEMS.map((item) => (
        <Accordion
          key={item.key}
          disableGutters
          elevation={0}
          square
          TransitionProps={{ unmountOnExit: false, mountOnEnter: true }}
          expanded={expanded === item.key}
          onChange={handleChange(item.key)}
        >
          <AccordionSummary
            expandIcon={<ArrowDropDown fontSize="large" />}
            aria-controls={`${item.key}-content`}
            id={`${item.key}-header`}
          >
            <Typography>{item.label}</Typography>
          </AccordionSummary>
          <AccordionDetails key={item.key}>
            <Box mt={2}>{item.component}</Box>
          </AccordionDetails>
        </Accordion>
      ))}
    </Box>
  );
}
