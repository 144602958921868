import api from '@/api';
import { store } from '@/store';
import { selectSecretToken, selectTenantId } from '@/store/auth';
import { formatTimestamp } from '@/utils/datetime';
import { formatTriggerName } from '@/utils/events';
import { buildSensorInfo, fetchSensorFile, sortObjectKeysByArray } from '@/utils/sensors';
import { CenterBox } from '@/web/@components/CenterBox';
import { EllipsisTextViewContainer } from '@/web/@components/EllipsisTextView';
import { EventGridListItem } from '@/web/events/@components/EventGridListItem';
import { Close } from '@mui/icons-material';
import { Box, CircularProgress, IconButton, Table, Typography } from '@mui/material';
import { isEmpty } from 'lodash';
import { useEffect, useMemo, useState } from 'react';

/**
 * @typedef {object} EventPopperContentProps
 * @property {TriggeredEvent} event
 * @property {boolean} imperial
 * @property {string} sensorUrl
 * @property {boolean} isInTripPage
 * @property {import('react').EventHandler<import('react').SyntheticEvent<HTMLElement>>} onClose
 */

/** @param {string} triggerId */
async function fetchTriggerLabels(triggerId) {
  const state = store.getState();
  const tenantId = selectTenantId(state);
  const secretToken = selectSecretToken(state);
  const request = api.ac.v5.trigger.composite.$triggerId(triggerId).$get({
    headers: {
      Authorization: secretToken,
    },
    params: {
      tenantId,
    },
  });
  const result = await request.process();
  const parsedData = result.body;
  return parsedData?.classifications;
}

/** @param {EventPopperContentProps} props */
export function TripEventPopperContent(props) {
  const { event, imperial, sensorUrl, isInTripPage = false, onClose } = props;

  /** @type {StateVariable<EventV2>} */
  const [eventInfo, setEventInfo] = useState();
  /** @type {StateVariable<Array<{label: string, value: string}>>} */
  const [info, setInfo] = useState([]);

  useEffect(() => {
    setEventInfo(null);
    if (!event?.id) return;
    const aborter = new AbortController();
    const signal = aborter.signal;
    const secretToken = selectSecretToken(store.getState());
    async function process() {
      // @ts-ignore
      const request = api.ac.v2['event-messaging'].events.$eventId(event.id).$get({
        signal,
        params: { secretToken },
      });
      const result = await request.process();
      result.event.snapshots = event.snapshots;
      return result.event;
    }
    process().then(setEventInfo).catch(console.error);
  }, [event]);

  const triggerId = useMemo(() => eventInfo?.eventTriggerId, [eventInfo]);

  useEffect(() => {
    setInfo([]);
    if (!sensorUrl || !triggerId) return;
    const aborter = new AbortController();
    const signal = aborter.signal;
    async function process() {
      const sensors = await fetchSensorFile(sensorUrl, signal);
      const info = buildSensorInfo(sensors, imperial);
      if (isEmpty(info)) return [];
      const triggerLabels = await fetchTriggerLabels(triggerId);
      const labelArray = triggerLabels.map((item) => item.displayName);
      const sortedObject = sortObjectKeysByArray(info, labelArray);
      return Object.entries(sortedObject)
        .map(([label, value]) => ({ label, value }))
        .slice(0, 2);
    }
    process().then(setInfo).catch(console.error);
    return () => aborter.abort();
  }, [sensorUrl, triggerId, imperial]);

  return (
    <Box
      zIndex={999999}
      position="relative"
      width={isInTripPage ? { xs: 235, md: 300 } : { xs: 135, md: 200 }}
      sx={{
        '& .event-grid-list-item-preview': {
          borderRadius: 0,
        },
        '& .event-grid-list-item-info': {
          p: '5px 8px',
        },
      }}
    >
      {!eventInfo || '' + eventInfo?.id !== '' + event.id ? (
        <CenterBox style={{ height: undefined, aspectRatio: 4 / 3 }}>
          <CircularProgress size={'16px'} />
        </CenterBox>
      ) : (
        <EventGridListItem item={eventInfo}>
          <EllipsisTextViewContainer>
            <Typography fontWeight={500} fontSize={isInTripPage ? '0.875rem' : '0.75rem'}>
              {formatTriggerName(event.triggerName)}
            </Typography>
          </EllipsisTextViewContainer>
          <Table
            style={{ fontSize: isInTripPage ? '0.75rem' : '0.60rem' }}
            sx={{
              '& td': {
                minWidth: '100px',
                whiteSpace: 'nowrap',
                lineHeight: '16px',
              },
              '& td:first-child': {
                color: '#5a6c83',
              },
            }}
          >
            {info?.map((item) => (
              <tr key={item.label}>
                <td>{item.label}</td>
                <td>{item.value}</td>
              </tr>
            ))}
          </Table>
          <EllipsisTextViewContainer>
            <Typography
              pl="1px"
              variant="subtitle2"
              fontWeight={400}
              lineHeight="16px"
              fontSize={isInTripPage ? '0.75rem' : '0.60rem'}
            >
              {formatTimestamp(event.eventTimestamp)}
            </Typography>
          </EllipsisTextViewContainer>
        </EventGridListItem>
      )}

      <IconButton
        size="small"
        onClick={onClose}
        sx={{
          position: 'absolute',
          p: 0.5,
          top: 0,
          right: 0,
          zIndex: 100,
          backdropFilter: 'blur(5px)',
        }}
      >
        <Close style={{ fontSize: '16px', color: 'white' }} />
      </IconButton>
    </Box>
  );
}
