import {
  isLoggedIn,
  isTenantLoggedIn,
  selectTeamList,
  selectTenantId,
  selectUserEmail,
  selectUserRole,
} from '@/store/auth';
import { filterByUserAccess } from '@/utils/user-access';
import { NotFoundPage } from '@/web/@components/NotFoundPage';
import { routes } from '@/web/routes';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

const emptyRoute = {
  path: '',
  element: <NotFoundPage />,
};

const notFoundRoute = {
  path: '*',
  element: <NotFoundPage />,
};

/** @returns {Array<import('@/types').ExtendedRoute>} */
export function useVisibleRoutes() {
  const loggedIn = useSelector(isLoggedIn);
  const tenantLoggedIn = useSelector(isTenantLoggedIn);
  const userRole = useSelector(selectUserRole);
  const userEmail = useSelector(selectUserEmail);
  const tenantId = useSelector(selectTenantId);
  const teamList = useSelector(selectTeamList);

  return useMemo(() => {
    /**
     * @param {Array<import('@/types').ExtendedRoute>} routeList
     * @returns {Array<import('@/types').ExtendedRoute>}
     */
    function _processRoutes(routeList) {
      const results = routeList
        .map((route) => {
          const result = { ...route };
          if (result?.children) {
            result.children = _processRoutes(route.children || []);
            if (!result.children) return null;
          }
          return result;
        })
        .filter((route) => {
          if (!route) {
            return false;
          }
          if (!(route.index ?? true)) {
            return true;
          }
          if (route.children) {
            return route.children.length > 0;
          }
          if (route.visibility === 'ALWAYS') {
            return true;
          }
          if (route.visibility === 'NO_AUTH') {
            return !loggedIn;
          }
          if (route.visibility === 'NO_TENANT_AUTH') {
            return loggedIn && !tenantLoggedIn;
          }
          if (!loggedIn || !tenantLoggedIn) {
            return false;
          }
          // filter by access rights
          return true;
        });
      if (!results.length) {
        return null;
      }
      if (!results.find((x) => !x.path)) {
        results.push(emptyRoute);
      }
      results.push(notFoundRoute);
      return results;
    }

    const hasAccess = filterByUserAccess({ userRole, userEmail, tenantId, teamList });
    const visible = (_processRoutes(routes) || []).filter(hasAccess).map((item) => {
      item.children = item.children?.filter(hasAccess);
      return item;
    });

    if (!visible.length) return [notFoundRoute];
    return visible;
  }, [loggedIn, tenantLoggedIn, userRole, userEmail, teamList, tenantId]);
}
