/**
 * Format bundle size
 * @param {string | number} size
 * @returns
 */

export function formatSize(size) {
  const suffix = ['B', 'KB', 'MB', 'GB', 'TB', 'PB'];

  size = Number(size);
  let index = 0;
  while (size > 1024) {
    size /= 1024;
    index++;
  }

  return Math.floor(size) + ' ' + suffix[index];
}

export function meanOf(arr, def) {
  if (!arr?.length) return def;
  let sum = arr.reduce((s, v) => s + v, 0);
  return sum / arr.length;
}

/**
 * Parse Property
 * @param {string} item
 * @param {string} separator
 * @returns
 */

export function parseProperty(item, separator = '=') {
  const items = item.split(separator);
  if (items.length < 2) {
    return [];
  }
  let key = items.shift();
  let value = items.join(separator);
  if (value.startsWith('"') && value.endsWith('"')) {
    value = value.substring(1, value.length - 1);
  }
  return [key, value];
}

/**
 * Parse string by key
 * @param {string} properties
 * @param {string} separator
 * @returns
 */

export function parseObject(properties, separator = '\n') {
  if (!properties) {
    return null;
  }
  const data = {};
  properties.split(separator).forEach((line) => {
    const [key, value] = parseProperty(line, '=');
    if (key) data[key] = value;
  });
  return data;
}

/**
 * Parse string by separators
 * @param {string} properties
 * @param {string} listSeparator
 * @param {string} itemSeparator
 * @returns
 */

export function parseList(properties, listSeparator = '\n', itemSeparator = ',') {
  if (!properties) {
    return null;
  }
  const arr = [];
  properties.split(listSeparator).forEach((line) => {
    const data = parseObject(line, itemSeparator);
    if (data) arr.push(data);
  });
  return arr;
}

/**
 * Processes cellular signal strength data and extracts it into an object.
 * @param {string} cellular_signal_strength
 * @returns {any}
 */
export function parseDeviceCellularData(cellular_signal_strength) {
  const data = {};
  cellular_signal_strength.split(',').forEach((line) => {
    const [key, value] = parseProperty(line, ':');
    if (key) data[key] = value;
  });
  return data;
}

/**
 * Recursively flattens a nested Network object.
 * @param {object} obj - The object to be flattened.
 * @param {string} [prefix] - The prefix for the keys in the flattened object.
 * @returns {object}
 */
export const flattenNetworkObject = (obj, prefix = '') => {
  return Object.keys(obj).reduce((acc, key) => {
    const newKey = key;
    if (typeof obj[key] === 'object' && obj[key] !== null) {
      Object.assign(acc, flattenNetworkObject(obj[key], newKey));
    } else {
      acc[newKey] = obj[key];
    }
    return acc;
  }, {});
};

/**
 * Converts Kbps to bytes per second.
 * @param {number} kbps - The speed in kilobits per second.
 * @returns {number} The speed in bytes per second.
 */
export const convertKbpsToBytesPerSecond = (kbps) => {
  return (kbps * 1000) / 8;
};
