import api from '@/api';
import { selectMainUserId, selectSecretToken } from '@/store/auth';
import { toastSuccess, toastWarning } from '@/utils/toaster';
import { Close } from '@mui/icons-material';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { useSelector } from 'react-redux';

export default function SelfCoachingModal(props) {
  const { onCancelSelfModal, endpointId } = props;
  const secretToken = useSelector(selectSecretToken);
  const authUserId = useSelector(selectMainUserId);

  const [isAttachingNotes, setIsAttachingNotes] = useState(false);
  const [loading, setLoading] = useState(false);
  const [note, setNote] = useState(
    'I have selected coachable events for you please proceed with self coaching.'
  );

  const handleClose = () => {
    onCancelSelfModal(null);
  };

  const handleSelfCoachingRequest = () => {
    handleSelfCoaching();
  };

  const handleSelfCoaching = () => {
    setLoading(true);
    const request = api.ac.v5.coaching.sessions
      .$coachingByTarget('ENDPOINT')
      .$targetId(endpointId)
      .$post({
        headers: {
          Authorization: secretToken,
        },
        params: {
          coachUserId: authUserId,
          coachingSessionType: 'SELF',
          notes: isAttachingNotes ? note : '',
          startTime: new Date().getTime(),
        },
      });
    request
      ?.process()
      ?.then((data) => {
        setLoading(false);
        toastSuccess('Success', 'Self coaching initiated successfully');
        onCancelSelfModal(null);
        setIsAttachingNotes(false);
      })
      .catch((error) => {
        setLoading(false);
        toastWarning('Error', 'Coaching sessions cannot be created without a coachable event.');
        onCancelSelfModal(null);
        setIsAttachingNotes(false);
      });
  };

  return (
    <>
      <Dialog
        open={true}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <Box display="flex" justifyContent="space-between">
            <Typography fontWeight={500}>Self Coaching</Typography>
            <IconButton
              size="small"
              aria-haspopup="true"
              onClick={handleClose}
              sx={{ backgroundColor: '#F0F4FA' }}
            >
              <Close style={{ fontSize: '20px' }} />
            </IconButton>
          </Box>
        </DialogTitle>
        <Divider />

        <DialogContent sx={{ width: '600px', mb: 4 }}>
          <DialogContentText id="alert-dialog-description">
            <Typography variant="body1">{isAttachingNotes ? 'Note' : 'Notes'}</Typography>
            {!isAttachingNotes ? (
              <Typography variant="subtitle1" color="black">
                Send a request to the driver for self-coaching, with or without a note.
              </Typography>
            ) : (
              <TextField
                id="outlined-basic"
                label=""
                variant="outlined"
                type="textarea"
                fullWidth
                value={note}
                multiline
                rows={2}
                onChange={(e) => {
                  setNote(e.target.value);
                }}
              />
            )}
          </DialogContentText>
        </DialogContent>

        <DialogActions sx={{ mb: 2 }}>
          {!isAttachingNotes && (
            <Button
              disabled={loading}
              onClick={() => {
                setIsAttachingNotes(true);
              }}
              variant="outlined"
            >
              Add a note
            </Button>
          )}

          <Button
            disabled={loading}
            onClick={handleSelfCoachingRequest}
            autoFocus
            variant={isAttachingNotes ? 'outlined' : 'contained'}
            startIcon={loading && <CircularProgress sx={{ color: 'white' }} size={16} />}
          >
            Send without a note
          </Button>

          {isAttachingNotes && (
            <Button
              disabled={loading}
              onClick={handleSelfCoachingRequest}
              autoFocus
              variant="contained"
              startIcon={loading && <CircularProgress sx={{ color: 'white' }} size={16} />}
            >
              Send
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
}
