import { NO_SEARCH_RESULT_IMG } from '@/assets/constants/images';
import { useRouteQuery } from '@/hooks/useRouteQuery';
import { CenterBox } from '@/web/@components/CenterBox';
import { IconMessageBox } from '@/web/@components/IconMessageBox';
import {
  Box,
  CircularProgress,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { isEmpty } from 'lodash';
import { useContext, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { TriggerConfigurationContext } from '../../@context/TriggerConfigurationContext';
import { BufferInfo } from '../BufferInfo';
import { CabinDataInfo } from '../CabinDataInfo';
import { CoolDownTimeBar } from '../CoolDownTimeBar';
import { EditButton } from '../EditButton';
import { RecordingInfo } from '../RecordingInfo';
import { TableSensitivityChart } from '../TableSensitivityChart';
import { TriggerActivateSwitch } from '../TriggerActivateSwitch';
import { TriggerNameWithImage } from '../TriggerNameWithImage';

const TriggerTable = () => {
  const { selectionType, parentLoading, triggers, error } = useContext(TriggerConfigurationContext);

  const { query, updateQuery } = useRouteQuery();

  /** @type {StateVariable<boolean>} */
  const [isHovered, setIsHovered] = useState(null);
  /** @type {StateVariable<object>} */
  const [filteredTriggers, setFilteredTriggers] = useState([]);

  const form = useForm({
    mode: 'all',
    defaultValues: {},
  });

  const columns = [
    {
      key: 'on-off-switch',
      label: 'Off/On',
      textAlign: 'left',
      width: '40px',
      component: (trigger) => (
        <Box maxWidth={10}>
          <TriggerActivateSwitch trigger={trigger} />
        </Box>
      ),
    },
    {
      key: 'trigger-name',
      label: 'Trigger',
      textAlign: 'center',
      width: '30px',
      component: (trigger) => (
        <Box ml={5} mr={{ xs: 0, sm: 0, md: 0, xl: -15 }}>
          <TriggerNameWithImage triggerName={trigger?.triggerCategoryName} />
        </Box>
      ),
    },
    {
      key: 'cabin',
      label: 'Cabin',
      textAlign: 'center',
      width: '100px',
      component: (trigger) => {
        const ttsEnabled = !!trigger?.enableTts?.value && trigger?.tts !== '';

        return (
          <CabinDataInfo
            isChimeEnabled={trigger?.chime?.value !== 'NONE'}
            isTtsEnabled={ttsEnabled}
            chime={trigger?.chime?.value}
            tts={trigger?.tts}
            defaultChime={trigger?.defaultValues?.chime?.value}
            defaultTts={trigger?.defaultValues?.tts}
          />
        );
      },
    },
    {
      key: 'recording',
      label: 'Recording',
      textAlign: 'center',
      width: '100px',
      component: (trigger) => (
        <RecordingInfo
          recodingType={trigger?.uploadStrategy?.value}
          defaultRecodingType={trigger?.defaultValues?.uploadStrategy?.value}
        />
      ),
    },
    {
      key: 'buffers',
      label: 'Buffers',
      textAlign: 'center',
      width: '100px',
      component: (trigger) => (
        <BufferInfo
          preBuffer={trigger?.preBuffer?.value}
          postBuffer={trigger?.postBuffer?.value}
          defaultPreBuffer={trigger?.defaultValues?.preBuffer?.value}
          defaultPostBuffer={trigger?.defaultValues?.postBuffer?.value}
          trigger={trigger}
        />
      ),
    },
    {
      key: 'sensitivity',
      label: 'Sensitivity',
      textAlign: 'center',
      width: '100px',
      component: (trigger, category) => (
        <TableSensitivityChart
          trigger={trigger}
          category={category}
          isHovered={isHovered === trigger?.id}
        />
      ),
    },
    {
      key: 'cooldown',
      label: 'Cool Down',
      textAlign: 'center',
      width: '100px',
      component: (trigger) => (
        <CoolDownTimeBar
          isEnable={trigger?.cooldown?.value}
          coolDownTimer={trigger?.cooldownTimer?.value / 1000}
          defaultCoolDownEnable={trigger?.defaultValues?.cooldown?.value}
          defaultCoolDownTimer={trigger?.defaultValues?.cooldownTimer?.value / 1000}
          isHovered={isHovered === trigger?.id}
        />
      ),
    },
    {
      key: 'action',
      label: '',
      textAlign: 'right',
      width: '30px',
      component: (trigger) => (
        <EditButton
          isVisible={isHovered === trigger?.id}
          onEdit={() => {
            updateQuery({ id: trigger?.id });
          }}
        />
      ),
    },
  ];

  const handleMouseEnterOnRow = async (trigger) => {
    setIsHovered(trigger?.id);
  };

  useEffect(() => {
    if (isEmpty(query?.search)) {
      setFilteredTriggers(triggers);
      return;
    }
    const filteredItems = triggers?.filter((item) =>
      item?.triggerCategoryName?.toLowerCase()?.includes((query?.search + '')?.toLowerCase())
    );
    setFilteredTriggers(filteredItems);
  }, [triggers, query?.search, setFilteredTriggers]);

  return (
    <Box>
      <Box>
        {parentLoading ? (
          <CenterBox sx={{ height: '80vh !important' }}>
            <CircularProgress />
          </CenterBox>
        ) : !selectionType ? (
          <CenterBox sx={{ height: '80vh !important' }}>
            <IconMessageBox
              size="256px"
              src={NO_SEARCH_RESULT_IMG}
              message="Sorry! No entity selected."
            />
          </CenterBox>
        ) : !parentLoading && error ? (
          <CenterBox sx={{ height: '80vh !important' }}>
            <IconMessageBox
              size="256px"
              src={NO_SEARCH_RESULT_IMG}
              message={
                error?.includes('Internal')
                  ? error
                  : 'The specified target tenant is not under the control of the initiator tenant.'
              }
            />
          </CenterBox>
        ) : filteredTriggers?.length === 0 ? (
          <CenterBox sx={{ height: '80vh !important' }}>
            <IconMessageBox
              size="256px"
              src={NO_SEARCH_RESULT_IMG}
              message="Sorry! No Trigger found."
            />
          </CenterBox>
        ) : filteredTriggers?.length > 0 ? (
          <Box maxHeight="80vh" sx={{ overflowY: 'auto' }}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TableContainer
                  pb={5}
                  component={Box}
                  sx={{
                    'maxHeight': '80vh',
                    '& .MuiTableCell-stickyHeader': {
                      opacity: 1,
                      background: '#f2f6fc',
                    },
                  }}
                >
                  <Table
                    stickyHeader
                    sx={{
                      '& .MuiTableCell-head': {
                        padding: '8px 0px !important',
                        borderBottom: 'none',
                      },
                      '& .MuiTableHead-root': {
                        border: '1px solid #d6e4f5 !important',
                        borderRadius: '100px',
                        background: '#d6e4f5',
                      },
                      '& .MuiTableCell-body': {
                        padding: '0px 4px !important',
                      },
                    }}
                  >
                    <TableHead>
                      <TableRow>
                        {columns.map((column, index) => (
                          <TableCell
                            sx={{
                              color: '#0B2547',
                              opacity: 0.68,
                              fontSize: '0.875rem',
                              textAlign: 'center',
                            }}
                            key={index}
                          >
                            {column?.label}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <FormProvider {...form}>
                      <TableBody>
                        {filteredTriggers?.map((trigger, index2) => (
                          <TableRow
                            key={index2}
                            sx={{
                              'px': 2,
                              'height': '80px',
                              '&:hover': {
                                backgroundColor: '#E3EDFD',
                              },
                              '&:hover .edit-button': {
                                visibility: 'visible',
                              },
                            }}
                            onMouseEnter={() => handleMouseEnterOnRow(trigger)}
                            onMouseLeave={() => setIsHovered(null)}
                          >
                            {columns?.map((item, index) => (
                              <TableCell
                                sx={{
                                  textAlign: item?.textAlign,
                                  width: item?.width,
                                }}
                                key={index}
                              >
                                {item?.component(trigger, trigger?.triggerCategoryName)}
                              </TableCell>
                            ))}
                          </TableRow>
                        ))}
                      </TableBody>
                    </FormProvider>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
          </Box>
        ) : (
          <CenterBox sx={{ height: '80vh !important' }}>
            <CircularProgress />
          </CenterBox>
        )}
      </Box>
    </Box>
  );
};

export default TriggerTable;
