import { T } from '@/assets/locales';
import { MICROSOFT_CLIENT_ID } from '@/config';
import { store } from '@/store';
import { Auth } from '@/store/auth';
import { toastWarning } from '@/utils/toaster';
import { BoxImage } from '@/web/@components/BoxImage';
import { PublicClientApplication } from '@azure/msal-browser';
import { Button, CircularProgress } from '@mui/material';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

/**
 * @typedef {object} MicrosoftLoginButtonPropsBase
 * @property {boolean} [disabled]
 * @property {(email: string, idToken: string) => void} [onLogin]
 */

/**
 * @typedef {import('@mui/material').ButtonProps & MicrosoftLoginButtonPropsBase} MicrosoftLoginButtonProps
 */

/**
 * @param {MicrosoftLoginButtonProps} props
 */
export function MicrosoftLoginButton(props) {
  const { disabled, onLogin, ...extraProps } = props;
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const msal = useMemo(() => {
    return new PublicClientApplication({
      auth: {
        clientId: MICROSOFT_CLIENT_ID,
        redirectUri: window.location.href,
      },
    });
  }, []);

  const handleLogin = async () => {
    try {
      setLoading(true);

      // prompt login popup
      const response = await msal.loginPopup({
        scopes: ['user.read', 'email', 'profile'],
        // prompt: 'consent',
      });
      console.debug('Login Response', response);
      const email = response.account.username;
      const idToken = response.idToken;
      onLogin && onLogin(email, idToken);

      // fetch and cache the user profile image
      fetch('https://graph.microsoft.com/v1.0/me/photo/$value', {
        headers: { Authorization: `Bearer ${response.accessToken}` },
      })
        .then((resp) => resp.arrayBuffer())
        .then((buffer) => new Blob([buffer], { type: 'image/png' }))
        .then((blob) => URL.createObjectURL(blob))
        .then((url) => store.dispatch(Auth.setAvatar(url)));
    } catch (err) {
      if (err.message.startsWith('user_cancelled:')) return;
      console.warn('Failed to login with Microsoft', err);
      toastWarning('Failed to login with Microsoft');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Button {...extraProps} disabled={Boolean(disabled || loading)} onClick={handleLogin}>
      {loading ? (
        <CircularProgress disableShrink size={16} />
      ) : (
        <BoxImage
          src="/images/pages/auth/microsoft-icon.svg"
          alt="microsoft-login"
          width={16}
          height={16}
        />
      )}
      &nbsp;&nbsp;
      <span>{t(T['login.button.microsoft'])}</span>
    </Button>
  );
}
