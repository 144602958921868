import { PageLink } from '@/web/@components/PageLink';
import { TableCell, Tooltip } from '@mui/material';
import { isFunction } from 'lodash';

/**
 * @template T
 * @param {{row: T, column: TableColumn<T>}} props
 */
export function ItemTableViewTableCell(props) {
  const { row, column } = props;

  const formattedText =
    row && column
      ? isFunction(column.format)
        ? column.format(row[column.id], row)
        : row[column.id]
      : undefined;
  const displayText = formattedText ?? column.fallback ?? '...';

  return (
    <TableCell
      align={column.align}
      padding={'normal'}
      variant={'body'}
      style={{
        minWidth: column.minWidth,
        width: column.width,
        fontSize: 'inherit',
        fontWeight: 'inherit',
      }}
    >
      <Tooltip
        arrow
        disableFocusListener
        enterDelay={500}
        leaveDelay={200}
        placement="bottom-start"
        title={isFunction(column.tooltip) ? column.tooltip(row) : column.tooltip}
      >
        <span>
          {isFunction(column.link) && column.link(row) ? (
            <PageLink to={column.link(row)}>{displayText}</PageLink>
          ) : (
            displayText
          )}
        </span>
      </Tooltip>
    </TableCell>
  );
}
