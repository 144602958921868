import { formatFirmwareName } from '@/utils/firmwares';
import { TableCheckboxHeader } from '@/web/@components/TableCheckboxHeader';
import { TableCheckboxItem } from '@/web/@components/TableItemCheckbox';

/** @type {Array<TableColumn<FirmwareV2>>} */
export const POOL_FIRMWARE_TABLE_COLUMNS = [
  {
    id: 'firmwareId',
    label: '',
    align: 'left',
    width: 50,
    isPrimary: true,
    headerFormat: (value, rows) => <TableCheckboxHeader rows={rows} itemKey={'firmwareId'} />,
    format: (value, row) => <TableCheckboxItem row={row} disabled />,
  },
  {
    id: 'name',
    label: 'Name',
    align: 'left',
    isPrimary: true,
  },
  {
    id: 'version',
    label: 'Version',
    align: 'left',
    fallback: 'No Product Found',
  },
  {
    id: 'type',
    label: 'Package Type',
    align: 'left',
    fallback: 'N/A',
    format: (value, row) => formatFirmwareName(value),
  },
  {
    id: 'description',
    label: 'Description',
    align: 'left',
  },
];
