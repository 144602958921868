module.exports = {
  'sort': 'Sort',
  'device-group.edit.success': 'Updated the Device Group {{groupName}}',
  'device-group.edit.title': 'Update Device Group',
  'device-group.form.description.placeholder': 'Enter description',
  'device-group.form.group.name.hint': 'Enter Device Group name',
  'device-group.form.group.name.label': 'Device Group Name *',
  'device-group.form.group.name.placeholder': 'Enter Device Group name',
  'device-group.form.select.product.hint': 'Please select a Product',
  'device-group.retry': 'Failed to update Device Group.',
  'device-group.form.text.secondary': 'Device Group Details',
  'device-batch.form.packageId.title': 'Package ID *',
  'device-batch.form.packageId.placeholder': 'Enter package ID',
  'device-batch.form.packageId.required': 'Package ID is required',
  'tenant.form.user.placeholder': 'Select a User',
  'ai.model.form.version.required': 'AI Model version is required',
  'ai.model.form.version.placeholder': 'Enter AI Model version',
  'ai.model.form.modelType.placeholder': 'Select AI Model type',
  'ai.model.form.modelType.required': 'AI Model type is required',
  'ai.model.form.inputLayer.placeholder': 'Multiple values by comma separation',
  'ai.model.form.outputLayer.placeholder': 'Multiple values by comma separation',
  'error.boundary.default.message': 'Sorry! Some unexpected error occurred.',
  'ai.containers.create.button': 'Create AI Container',
  'ai.containers.page.card.browse': 'Browse AI Container',
  'ai.containers.page.card.create': 'Create a new AI Container',
  'ai.containers.page.card.title': 'AI Containers',
  'ai.containers.page.title': 'AI Containers',
  'ai.containers.table.button.add': 'AI Container',
  'ai.containers.table.columns.name': 'Name',
  'ai.containers.table.columns.publication.status': 'Status',
  'ai.containers.table.columns.release.version': 'Release Version',
  'ai.containers.table.columns.version': 'Version',
  'ai.containers.table.search.placeholder': 'Search AI Container',
  'ai.scheme.table.search.placeholder': 'Search Schemes',
  'ai.models.create.button': 'Create AI Model',
  'ai.models.name': 'AI Models',
  'ai.models.page.card.browseItems': 'Browse AI Models',
  'ai.models.page.card.createNew': 'Create a new AI Model',
  'ai.models.page.card.name': 'AI Models',
  'ai.models.table.button.add': 'AI Model',
  'ai.models.table.columns.name': 'Name',
  'ai.models.table.columns.ai.name': ' AI Model Name',
  'ai.models.table.columns.publication.status': 'Status',
  'ai.models.table.columns.publication.description': 'Description',
  'ai.models.table.columns.version': 'Model/SDK Version',
  'ai.models.table.columns.release.version': 'Version',
  'ai.models.table.columns.type': 'Type',
  'ai.models.table.columns.vendor': 'Vendor',
  'ai.models.table.columns.ai.accelerator': 'AI Accelerator',
  'ai.models.table.search.placeholder': 'Search AI Models',
  'annotations.fetching.data.message': 'Fetching annotation categories',
  'annotations.no.categories.message': 'No annotation categories.',
  'annotations.page.card.browseItems': 'Browse Annotation',
  'annotations.page.card.createNew': 'Create Annotation',
  'annotations.page.card.name': 'Annotation Categories',
  'annotations.page.title': 'Annotations',
  'annotation.actions.create': 'Annotation',
  'annotations.search.placeholder': 'Search annotation to edit and discontinue',
  'button.agree': 'Yes',
  'button.cancel': 'Cancel',
  'button.details': 'Details',
  'button.disagree': 'Cancel',
  'button.delete': 'Delete',
  'button.discontinue': 'Discontinue',
  'button.deactivate': 'Deactivate',
  'button.edit': 'Edit',
  'button.modify': 'Modify',
  'button.release': 'Release',
  'button.report': 'Report',
  'button.retry': 'Retry',
  'button.save': 'Save',
  'button.apply': 'Apply',
  'button.search': 'Search',
  'button.reset': 'Reset',
  'button.update': 'Update',
  'button.settings': 'Settings',
  'cameras.no.data.message': 'No Cameras found!',
  'cameras.page.title': 'Cameras',
  'device.page.title': 'Devices',
  'cameras.search.placeholder': 'Search by Camera Name, Camera ID, Location & VIN',
  'change.password.title': 'Change Password',
  'data-residency.page.card.configurations': 'Configurations',
  'data-residency.page.card.name': 'Data Residency',
  'data-retention.page.card.configurations': 'Configurations',
  'data-retention.page.card.name': 'Data Retention',
  'device-batches.page.card.browse.items': 'Browse Device Batches',
  'device-batches.page.card.create.new': 'Create Device Batch',
  'device-batches.page.card.name': 'Device Batches',
  'device-group.columns.description': 'Description',
  'device-group.columns.name': 'Name',
  'device-group.columns.product': 'Product',
  'device-group.create.button': 'Device Group',
  'device-group.create.failed': 'Failed to update device group',
  'device-group.create.page.title': 'Create Camera Group',
  'device-group.create.retry': 'Could not add group.',
  'device-group.create.success': 'The group {{groupName}} has been added successfully.',
  'device-group.delete.failed': 'Failed to discontinue {{count}} Device Groups: {{groupName}}.',
  'device-group.delete.retry': 'Could not discontinue {{groupName}}.',
  'device-group.delete.success': 'Deleted {{count}} Device Groups: {{groupName}}',
  'device-group.edit.failed': 'Failed to update device group',
  'device-group.form.select.product.label': 'Product *',
  'device-group.form.select.product.placeholder': 'Select a product',
  'device-group.placeholder.search': 'Search Device Group',
  'device-group.title': 'Device Groups',
  'device.groups.page.card.browseItems': 'Browse Device Groups',
  'device.groups.page.card.createNew': 'Create Device Group',
  'device.groups.page.card.name': 'Device Groups',
  'triggers.configuration.page.card.name': 'Triggers',
  'triggers.configuration.page.card.browseItems': 'Browse Configurations',
  'device.pools.page.card.browseItems': 'Browse Device Pools',
  'device.pools.page.card.createNew': 'Create Device Pool',
  'device.pools.page.card.name': 'Device Pools',
  'events.no.data.message': 'No Events',
  'events.page.title': 'Events',
  'events.search.placeholder': 'Search Events',
  'trip.search.placeholder': 'Search Trips',
  'firmwares.page.card.browse.items': 'Browse Firmwares',
  'firmwares.page.card.browse.camera.items': 'Cameras',
  'firmwares.page.card.browse.tenant.items': 'Tenants',
  'page.card.tenant.deployment': 'Tenant Deployment',
  'firmwares.page.card.name': 'Firmwares',
  'monitoring.firmwares.page.card.name': 'Monitoring Firmwares',
  'firmwares.page.card.upload': 'Upload Firmware',
  'geofences.page.card.browseItems': 'Browse Geofences',
  'geofences.page.card.createNew': 'Create Geofence',
  'geofences.page.card.name': 'Geofences',
  'onboard.page.card.name': 'Onboard Logs',
  'onboard.page.card.browse.items': 'Browse Onboard Logs',
  'camera.reports.page.card.name': 'Camera Reports',
  'camera.health.page.card.name': 'Camera Health',
  'camera.health.browse.by.tenants': 'Browse By Tenant',
  'camera.health.browse.by.observations': 'Browse By Observations',
  'device.records.page.card.browse.items': 'Browse Device Records',
  'labels.page.card.browseItems': 'Browse Labels',
  'labels.page.card.createNew': 'Create Label',
  'labels.page.card.name': 'Labels',
  'login.alternate.text': 'Alternatively, you can log in using your',
  'login.banner': 'Precision AI for trusted data and decisions',
  'login.button.google': 'Google',
  'login.button.geotab': 'Geotab',
  'login.button.microsoft': 'Microsoft',
  'login.button': 'Login',
  'login.forget.password': 'Forgot Password?',
  'login.label.email.label': 'Username / Email',
  'login.geotab.label.database.label': 'Database Name',
  'login.geotab.label.url.label': 'MyGeotab URL',
  'login.label.password.label': 'Password',
  'login.label.remember.me': 'Remember me',
  'reset.password.email.label': 'Email',
  'reset.password.email.placeholder': 'Enter email',
  'reset.password.email.required': 'Email address is required',
  'reset.password.email.rules.regex': 'Email address is not valid',
  'change.password.current.password.label': 'Current Password',
  'change.password.new.password.label': 'New Password',
  'change.password.current.password.placeholder': 'Enter current password',
  'change.password.new.password.placeholder': 'Enter new password',
  'change.password.current.password.required': 'Current password is required',
  'change.password.new.password.required': 'New password is required',
  'change.password.confirm.password.label': 'Confirm Password',
  'change.password.confirm.password.placeholder': 'Enter new password again',
  'change.password.confirm.password.required': 'Password confirmation is required',
  'change.password.rules.min.length': 'Password must contain at least 8 characters',
  'change.password.rules.regex':
    'Password must contain at least 1 digit, 1 letter and 1 special character',
  'change.password.rules.password.match': 'Password did not match',
  'login.title': 'Please log in to your account',
  'navigation.ai.container': 'AI Containers',
  'navigation.ai.models': 'AI Models',
  'navigation.ai': 'AI',
  'navigation.reports.trips': 'Download Trips',
  'navigation.scheme': 'Schemes',
  'navigation.view.scheme': 'View Schemes',
  'navigation.scheme.management': 'Scheme Management',
  'navigation.annotations': 'Annotations',
  'navigation.review': 'Review',
  'navigation.coaching': 'Coaching',
  'navigation.scoring': 'Scoring',
  'navigation.cameras': 'Cameras',
  'navigation.configurations': 'Configurations',
  'navigation.data.residency': 'Data Residency',
  'navigation.data.retention': 'Data Retention',
  'navigation.device.batches': 'Device Batches',
  'navigation.device.groups': 'Device Groups',
  'navigation.device.pools': 'Device Pools',
  'navigation.ai.accelerator': 'AI Accelerators',
  'navigation.events': 'Events',
  'navigation.firmwares': 'Firmwares',
  'navigation.firmwares.tenants': 'Tenants',
  'navigation.firmwares.cameras': 'Cameras',
  'navigation.gallery': 'Gallery',
  'navigation.operations.geofences': 'Geofences',
  'navigation.operations.labels': 'Labels',
  'navigation.smarter.ai.scheme': 'Schemes',
  'navigation.operations': 'Operations',
  'navigation.safety': 'Safety',
  'navigation.administration': 'Administration',
  'navigation.privacy.and.compliance': 'Privacy & Compliance',
  'navigation.privacy.policies': 'Privacy Policies',
  'navigation.products': 'Products',
  'navigation.reports': 'Reports',
  'navigation.drivers': 'Drivers',
  'navigation.driver.groups': 'Driver Groups',
  'navigation.driver.tags': 'Driver Tags',
  'navigation.my.fleet': 'My Fleet',
  'navigation.smarter.ai': 'Smarter AI',
  'navigation.monitoring': 'Monitoring',
  'navigation.tenants': 'Tenants',
  'navigation.schemes': 'Schemes',
  'navigation.trigger-sensitivity': 'Triggers',
  'navigation.video-minutes': 'Video Minutes',
  'navigation.notification': 'My Notification',
  'navigation.my-notifications': 'My Notification',
  'navigation.videominutes.browse': 'Browse Video Minutes',
  'navigation.notification.browse': 'Browse notification settings',
  'navigation.triggers': 'Trigger Management',
  'navigation.triggers.report': 'Trigger Reports',
  'navigation.users': 'Users',
  'navigation.vin.groups': 'VIN Groups',
  'navigation.waste.management': 'Waste Management',
  'navigation.waste.fleets': 'Fleets',
  'navigation.winter.operations': 'Winter Operations',
  'navigation.support': 'Support',
  'navigation.trips': 'Trips',
  'navigation.fleets': 'Fleets',
  'no.data.found': 'No data found!',
  'pages.administration.deviceGroups.form.description.name': 'Description',
  'privacy-policies.page.card.biometric': 'Biometric',
  'privacy-policies.page.card.consent': 'Consent',
  'privacy-policies.page.card.name': 'Privacy Policies',
  'privacy-policies.page.card.tools': 'Tools',
  'products.page.card.manage-accelerator': 'Manage AI Accelerator',
  'products.page.card.manage.hardware.products': 'Manage Hardware Products',
  'products.page.card.name': 'Products',
  'products.page.card.create.product': 'Add Hardware Product',
  'reset.password.title': 'Reset Password',
  'table.per.page': '{{item}} per page',
  'tenants.label.select': 'Select Tenant',
  'tenants.page.card.browse.items': 'Browse Tenants',
  'tenants.page.card.create.new': 'Create Tenant',
  'tenants.page.card.name': 'Tenants',
  'tenants.placeholder.search': 'Search Tenant',
  'tenants.title': 'Tenants',
  'triggers.create.button': 'Create Trigger',
  'triggers.page.card.browseItems': 'Browse Triggers',
  'triggers.page.card.createNew': 'Create Trigger',
  'triggers.page.card.manageLabels': 'Manage Labels',
  'triggers.page.card.name': 'Trigger Management',
  'monitoring.triggers.page.card.name': 'Monitoring Triggers',
  'triggers.page.title': 'Triggers',
  'triggers.report.page.title': 'Trigger Reports',
  'triggers.table.button.add': 'Trigger',
  'triggers.table.button.clone': 'Clone',
  'triggers.table.columns.name': 'Name',
  'triggers.table.columns.publication.status': 'Status',
  'triggers.table.columns.type': 'Type',
  'triggers.table.columns.category': 'Category',
  'triggers.table.search.placeholder': 'Search Trigger',
  'users.page.card.browseItems': 'Browse Users',
  'users.page.card.createNew': 'Create User',
  'users.page.card.name': 'Users',
  'vin.groups.page.card.browseItems': 'Browse VIN Groups',
  'vin.groups.page.card.createNew': 'Create VIN Group',
  'vin.groups.page.card.name': 'VIN Groups',
  'waste-management.configurations.page.card.customer': 'Customers',
  'waste-management.configurations.page.card.name': 'Configurations',
  'waste-management.configurations.page.card.softwareConf': 'Software Configuration',
  'waste-management.operations.page.card.name': 'Operations',
  'waste-management.reports.page.card.browseItems': 'Browse Reports',
  'waste-management.reports.page.card.createNew': 'Create Report',
  'waste-management.reports.page.card.name': 'Reports',
  'winter-operations.configurations.page.card.customer': 'Customers',
  'winter-operations.configurations.page.card.name': 'Configurations',
  'winter-operations.configurations.page.card.softwareConf': 'Software Configuration',
  'winter-operations.gallery.page.card.name': 'Gallery',
  'winter-operations.reports.page.card.browseItems': 'Browse Reports',
  'winter-operations.reports.page.card.createNew': 'Create Report',
  'winter-operations.reports.page.card.name': 'Reports',
  'user.create.title': 'Create New User',
  'user.update.title': 'Update User Details',
  'user.form.email.label': 'Email *',
  'user.form.email.placeholder': 'Enter email address',
  'user.form.email.required': 'Please enter user email',
  'user.form.role.required': 'Please enter user role',
  'user.form.email.invalid': 'Invalid email address',
  'user.form.name.label': 'Name *',
  'user.form.name.placeholder': 'Enter full name',
  'user.form.name.required': 'Please enter full name',
  'user.form.password.label': 'Password',
  'user.form.password.placeholder': 'Enter Password',
  'user.form.password.required': 'Please enter a password',
  'user.form.password.minLength': 'Password min length is 8',
  'user.form.password.regex':
    'Password must contain at least one letter, and one special character',
  'user.form.confirm_password.label': 'Confirm Password',
  'user.form.confirm_password.placeholder': 'Enter password',
  'user.form.confirm_password.required': 'Please enter confirmation password',
  'user.form.confirm_password.match': 'Your passwords do no match',
  'user.form.role.label': 'Role *',
  'user.form.role.placeholder': 'Select user role',
  'user.form.tenantId.label': 'Tenant',
  'user.form.tenantId.placeholder': 'Select a Tenant',
  'user.form.tenantId.required': 'Please select a Tenant',
  'user.create.success': 'User Created Successfully',
  'user.create.retry': 'Failed to create user',
  'user.update.success': 'User updated successfully',
  'user.update.retry': 'Failed to update user',
  'device-batch.actions.modify': 'Modify',
  'device-batch.actions.create': 'Device Batch',
  'device-batch.actions.search.placeholder': 'Search Device Batch',
  'device-batch.columns.name': 'Name',
  'device-batch.columns.package': 'Package',
  'device-batch.title': 'Device Batches',
  'device-batch.create': 'Create Device Batch',
  'device-batch.form.details': 'Device Batch Details',
  'device-batch.form.create.title': 'Create Device Batch',
  'device-batch.form.update.title': 'Update Device Batch',
  'device-batch.create.form.response.success': 'Device Batch Created successfully',
  'device-batch.create.form.response.error': 'Failed to create, Please try later.',
  'device-batch.update.form.response.success': 'Device Batch Updated successfully',
  'device-batch.update.form.response.error': 'Failed to update, Please try later.',
  'device-batch.form.name.title': 'Batch Name *',
  'device-batch.form.name.placeholder': 'Enter batch name',
  'device-batch.form.name.required': 'Batch name is required',
  'device-batch.form.secret.title': 'Secret *',
  'device-batch.form.secret.placeholder': 'Enter Secret',
  'device-batch.form.secret.required': 'Secret is required',
  'device-pool.route.create': 'Create Device Pool',
  'device-pool.form.name.label': 'Device Pool Name *',
  'device-pool.form.name.placeholder': 'Enter device pool name',
  'device-pool.form.name.required': 'Device pool name is required',
  'device-pool.form.text.secondary': 'Pool Details',
  'device-pool.create.success': 'The device pool {{name}} has been added successfully.',
  'device-pool.create.retry': 'Failed to create device pool, Please try later. ',
  'device-pool.create.page.title': 'Create Device Pool',
  'device-pool.update.page.title': 'Update Device Pool',
  'device-pool.update.success': 'The device pool {{name}} has been updated successfully.',
  'device-pool.update.retry': 'Failed to update, Please try later.',
  'device-pool.delete.success': 'Device Pool {{name}} discontinued successfully.',
  'device-pool.delete.failed': 'Failed to discontinue device pool',
  'device-pool.table.title': 'Device Pools',
  'device-pool.actions.create': 'Device Pool',
  'device-pool.actions.search': 'Search Device Pool',
  'tenant.route.list.title': 'Tenants',
  'tenant.route.create.title': 'Create Tenant',
  'tenant.form.text.secondary': 'Tenant Details',
  'tenant.form.name.label': 'Tenant Name *',
  'tenant.form.admin.email': 'Admin Email *',
  'tenant.form.admin.name': 'Admin Name *',
  'tenant.form.name.placeholder': 'Enter a tenant name',
  'tenant.form.admin.name.placeholder': 'Enter admin name',
  'tenant.form.admin.email.placeholder': 'Enter admin email',
  'tenant.form.name.required': 'Tenant name is required',
  'tenant.form.admin.name.required': 'Admin name is required',
  'tenant.form.admin.email.required': 'Admin email is required',
  'tenant.form.admin.email.rules.regex': 'Email address is not valid',
  'tenant.form.code.label': 'Tenant Code *',
  'tenant.form.code.placeholder': 'Enter a tenant code',
  'tenant.form.code.required': 'Tenant code is required',
  'tenant.create.success': 'Tenant {{tenantName}} created successfully.',
  'tenant.create.retry': 'Failed to create, Please try later.',
  'tenant.create.page.title': 'Create new Tenant',
  'tenant.update.success': 'Tenant {{tenantName}} updated successfully.',
  'tenant.update.retry': 'Failed to update, Please try later.',
  'tenant.update.page.title': 'Update Tenant',
  'tenant.table.title': 'Child Tenants',
  'tenant.delete.success': 'Tenant {{name}} discontinued successfully.',
  'tenant.activate.success': 'Tenant {{name}} activated successfully.',
  'tenant.deactivate.success': 'Tenant {{name}} deactivated successfully.',
  'tenant.delete.failed': 'Failed to discontinue tenant',
  'tenant.activate.failed': 'Failed to activate tenant',
  'tenant.deactivate.failed': 'Failed to deactivate tenant',
  'tenant.form.user.label': 'User',
  'tenant.form.user.required': 'User is required',
  'tenant.details.page.title': 'Tenant Details',
  'ai.model.create.page.title': 'Create new AI Model',
  'ai.model.update.page.title': 'Update AI Model',
  'ai.model.form.text.secondary': 'Model Details',
  'ai.model.form.name.label': 'Name *',
  'ai.model.form.name.placeholder': 'Enter model name',
  'ai.model.form.name.required': 'Model name is required',
  'ai.model.form.version.label': 'Model/SDK Version *',
  'ai.model.form.version.regex': 'Please enter a valid AI Model version number, e.g. X.Y.Z',
  'ai.model.form.vendor.label': 'Vendor',
  'ai.model.form.vendor.placeholder': 'Enter model vendor',
  'ai.model.form.modelType.label': 'Type *',
  'ai.model.form.inputLayer.label': 'Input Layer',
  'ai.model.form.outputLayer.label': 'Output Layer',
  'ai.model.form.aiAccelerator.label': 'AI Accelerator',
  'ai.model.form.aiAccelerator.placeholder': 'Select AI Accelerator',
  'ai.model.form.description.label': 'Description',
  'ai.model.form.description.placeholder': 'Enter AI model description',
  'ai.model.form.text.upload': 'Upload File *',
  'ai.model.form.image.upload': 'Upload Image',
  'ai.model.form.text.input.source': 'Input Sources',
  'ai.model.form.text.input.source.add': 'Input Source',
  'ai.model.form.text.output.source': 'Output Sources',
  'ai.model.form.text.output.source.add': 'Output Source',
  'ai.model.form.text.additional.param': 'Additional Parameters',
  'ai.model.form.text.additional.param.add': 'Additional Parameter',
  'ai.accelerator.page.card.name': 'AI Accelerator',
  'ai.accelerator.page.card.browse.items': 'Browse Accelerator',
  'ai.accelerator.page.card.create.new': 'Create AI Accelerator',
  'label.route.create': 'Create Label',
  'label.actions.search': 'Search Labels',
  'label.actions.create': 'Label',
  'labels.table.title': 'Labels',
  'label.create.success': 'The label {{name}} has been added successfully.',
  'label.update.success': 'The label {{name}} has been updated successfully.',
  'label.create.retry': 'Failed to create label, Please try later. ',
  'label.update.retry': 'Failed to update label, Please try later. ',
  'label.create.page.title': 'Create Label',
  'label.form.text.secondary': 'Label Details',
  'label.delete.success': 'Label {{name}} discontinued successfully.',
  'label.delete.failed': 'Failed to discontinue label',
  'product.assign.tenant': 'Assign to Tenants',
  'ai.container.undeploy.confirmation': 'Are you sure you want to undeploy this AI Container?',
  'firmware.undeploy.confirmation': 'Are you sure you want to undeploy this Firmware?',
  'trigger.undeploy.confirmation': 'Are you sure you want to undeploy this Trigger?',
  'group.settings.reset.confirmation':
    'Are you sure you want to reset the settings to their default values for this group ?',
  'confirmations.release': 'Are you sure you want to release the selected items?',
  'ai.container.confirmations.release':
    'Are you sure you want to release the selected AI Containers?',
  'ai.model.confirmations.release': 'Are you sure you want to release the selected AI Models?',
  'geofence.confirmations.release': 'Are you sure you want to release the selected Geofences?',
  'triggers.confirmations.clone': 'Are you sure you want to clone the selected Trigger?',
  'triggers.confirmations.release': 'Are you sure you want to release the selected Triggers?',
  'confirmations.delete': 'Are you sure you want to discontinue the selected items?',
  'confirmations.discontinue': 'Are you sure you want to discontinue the selected items?',
  'device-group.confirmations.delete': 'Are you sure you want to discontinue the selected Groups?',
  'ai.container.confirmations.discontinue':
    'Are you sure you want to discontinue the selected AI Containers?',
  'ai.model.confirmations.discontinue':
    'Are you sure you want to discontinue the selected AI Models?',
  'geofence.confirmations.discontinue':
    'Are you sure you want to discontinue the selected Geofences?',
  'labels.confirmations.discontinue': 'Are you sure you want to discontinue the selected Labels?',
  'users.confirmations.discontinue': 'Are you sure you want to discontinue the selected Users?',
  'triggers.confirmations.discontinue':
    'Are you sure you want to discontinue the selected Triggers?',
  'device-pool.confirmations.discontinue':
    'Are you sure you want to discontinue the selected Device Pools?',
  'vin-groups.confirmations.discontinue':
    'Are you sure you want to discontinue the selected VIN Groups?',
  'vins.confirmations.discontinue': 'Are you sure you want to discontinue the selected VINs?',
  'firmware.confirmations.discontinue':
    'Are you sure you want to discontinue the selected Firmwares?',
  'product.confirmations.discontinue':
    'Are you sure you want to discontinue the selected Products?',
  'device.batch.confirmations.discontinue':
    'Are you sure you want to delete the selected Device Batches?',
  'user.delete.success': 'User removed.',
  'user.delete.failed': 'Failed to remove user',
  'apn.confirmations.discontinue':
    'Are you sure you want to discontinue the selected APN Settings?',
  'apn.actions.search.placeholder': 'Search APN Setting',
  'apn.actions.create': 'APN Settings',
  'device-pool.device.confirmations.discontinue':
    'Are you sure you want to discontinue the selected devices from the pool?',
  'annotation.confirmations.discontinue':
    'Are you sure you want to discontinue the selected Annotations?',
  'annotation.actions.search': 'Search Annotation',
  'firmware.update.page.title': 'Update Firmware',
  'firmware.create.page.title': 'Create a new Firmware',
  'navigation.device.drivers': 'Drivers',
  'navigation.device.my.fleet': 'My Fleet',
  'navigation.fleet.live.tracking': 'Live Tracking',
  'navigation.device.drivers.group': 'Drivers Group',
  'drivers.confirmations.discontinue': 'Are you sure you want to discontinue the selected Drivers?',
  'driver.create.page.title': 'Create a new Driver',
  'nfc.confirmations.discontinue':
    'Are you sure you want to discontinue the selected Access cards?',
  'driver.tags.page.title': 'Driver Tags',
  'driver.tags.actions.search': 'Search Driver Tags',
  'driver.tags.actions.create': 'Driver Tag',
  'driver.tags.confirmations.discontinue':
    'Are you sure you want to discontinue the selected Driver Tags?',
  'group.settings.connectivity.add': 'Add',
  'group.settings.connectivity.ssid.label': 'Wi-Fi SSID *',
  'group.settings.connectivity.ssid.required': 'Please enter a valid Wi-Fi ssid',
  'group.settings.connectivity.ssid.placeholder': 'Enter a Wi-Fi ssid',
  'group.settings.connectivity.ssid.pattern':
    'Alphanumeric characters and certain special characters.',
  'group.settings.connectivity.password.label': 'Wi-Fi Password',
  'group.settings.connectivity.password.placeholder': 'Enter Wi-Fi Password',
  'group.settings.connectivity.password.pattern':
    'At least 8 characters long, or empty for open networks.',
  'monitoring.camera.health.page.title': 'Camera Health Monitoring',
  'scheme.groups.page.card.browseItems': 'Browse Schemes',
  'scheme.groups.page.card.createNew': 'Create Scheme',
  'scheme.groups.page.card.name': 'Schemes',
  'scheme.create.page.title': 'Create Scheme',
  'scheme.title': 'Schemes',
  'scheme.confirmations.delete': 'Are you sure you want to discontinue the selected Schemes?',
  'scheme.placeholder.search': 'Search Scheme',
  'scheme.create.button': 'Scheme',
  'scheme.columns.description': 'Description',
  'scheme.columns.name': 'Name',
  'scheme.columns.product': 'Product',
  'scheme.create.failed': 'Failed to update Scheme',
  'scheme.create.retry': 'Could not add Scheme.',
  'scheme.create.success': 'The scheme {{schemeName}} has been added successfully.',
  'scheme.delete.failed': 'Failed to discontinue {{count}} scheme: {{schemeName}}.',
  'scheme.delete.retry': 'Could not discontinue {{schemeName}}.',
  'scheme.delete.success': 'Deleted {{count}} Scheme: {{schemeName}}',
  'scheme.edit.failed': 'Failed to update scheme',
  'scheme.form.select.product.label': 'Product *',
  'scheme.form.select.product.placeholder': 'Select a product',
  'schemes.page.card.browseItems': 'Browse Schemes',
  'schemes.page.card.createNew': 'Create Scheme',
  'schemes.page.card.name': 'Scheme',
  'scheme.edit.title': 'Update Scheme',
  'scheme.retry': 'Failed to update Scheme.',
  'scheme.edit.success': 'Scheme {{schemeName}} updated successfully',
  'scheme.form.description.placeholder': 'Enter description',
  'scheme.form.group.name.hint': 'Enter Device Scheme',
  'scheme.form.group.name.label': 'Scheme Name *',
  'scheme.form.group.name.placeholder': 'Enter Scheme name',
  'scheme.form.select.product.hint': 'Please select a Product',
  'scheme.form.text.secondary': 'Scheme Details',
  'scheme.page.card.title': 'Schemes',
  'scheme.page.card.browse': 'Browse Schemes',
  'scheme.page.card.apply': 'Apply Schemes',
  'scheme.page.card.active': 'Active Schemes',
  'scheme.page.card.view': 'View Schemes',
  'scheme.page.card.apply.subtext': 'Apply Scheme to Tenant, Camera group or Camera.',
  'scoring.page.card.browseItems': 'Browse Scoring',
  'scoring.page.title': 'Scoring',
  'review.page.card.browseItems': 'Browse Reviews',
  'review.page.title': 'Reviews',
  'coaching.page.card.browseItems': 'Browse Coaching',
  'coaching.page.title': 'Coaching',
  'coaching.events.page.title': 'Coachable Events',
  'coaching.guided.page.title': 'Guided Coaching',
  'coaching.self.page.title': 'Self Coaching',
};
