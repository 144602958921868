import { T } from '@/assets/locales';
import { SafetyPage } from '.';
import { SafetyScoreLayoutLayout } from './scoring/@layouts/SafetyScoreLayout';

/** @type {Array<import('@/types').ExtendedRoute>} */
const routes = [
  {
    path: '',
    element: <SafetyPage />,
  },
  {
    path: 'scoring',
    element: <SafetyScoreLayoutLayout />,
    children: require('./scoring/routes').default,
    breadcrumb: T['navigation.scoring'],
  },
  {
    path: 'reviews',
    children: require('./review/routes').default,
    breadcrumb: T['navigation.review'],
  },
  {
    path: 'coaching',
    children: require('./coaching/routes').default,
    breadcrumb: T['navigation.coaching'],
  },
];

export default routes;
