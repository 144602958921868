import { GROUP_SETTINGS_CATEGORIES } from '@/assets/schemes/constants';
import { VIDEO_RECORDING_DEFAULT_RESOLUTIONS } from '@/assets/schemes/options';
import {
  AUDIO_PIPELINE_SETTINGS,
  BOOLEAN_ON_OFF,
  BOOLEAN_OPTIONS,
  FIRST_LEVEL_ITEMS,
  IMAGER_STATE_SETTINGS,
  PARKING_MODE_ON_OFF,
  SCHEME_FIRMWARE_CATEGORIES,
  SCHEME_PARENT_ITEMS,
  SCHEME_SETTINGS_SUBCATEGORIES,
  VIDEO_PIPELINE_SETTINGS,
} from '@/web/smarter-ai/scheme-management/schemes/assets';
import { trimSpaceFromString } from '@/web/smarter-ai/scheme-management/schemes/utils';
import { groupSettingsByCategory } from '@/web/smarter-ai/scheme-management/schemes/utils/settings';
import { isEmpty, isUndefined } from 'lodash';

/**
 * Transforms and formats data from various sources, ensuring uniqueness.
 * @param {Array<CompositeTriggerDto>} triggers - Data related to triggers.
 * @param {Array<FirmwareDeployedInfo>} firmwares - Data related to triggers.
 * @param {Array} settings - Data related to settings.
 * @param {Array<AiContainerDeployedDto>} schemeAiContainers
 * @param {Array} inputs - Data related to settings.
 */
export const transformAndFormatEffectiveSchemeData = async (
  triggers,
  firmwares,
  settings,
  schemeAiContainers,
  inputs
) => {
  const transformedTriggers = transformTriggers(triggers);
  const transformedFirmwares = transformFirmwares(firmwares);
  const transformedAiContainers = transformAiContainer(schemeAiContainers);
  const transformedSettings = transformSettings(settings, inputs);
  // Combine all transformed arrays into a single array
  const allTransformedItems = [
    ...transformedAiContainers,
    ...transformedTriggers,
    ...transformedFirmwares,
    ...transformedSettings,
  ];

  //Use a Set to remove duplicate items based on JSON.stringify
  const uniqueItems = new Set(allTransformedItems?.map((item) => JSON?.stringify(item)));
  // Convert Set back to an array
  const result = Array.from(uniqueItems, (item) => JSON?.parse(item));
  return result;
};

/**
 * Transforms a deployed AI container into a standardized format.
 * @param {Array<AiContainerDeployedDto>} deployedContainers - The deployed AI container to be transformed.
 * @returns {Array} - An array containing the transformed AI container.
 */
export function transformAiContainer(deployedContainers) {
  if (!deployedContainers?.length) return [];

  const selectedItem = FIRST_LEVEL_ITEMS.find(
    (item) => item.value === SCHEME_PARENT_ITEMS.AI_CONTAINERS
  );

  return deployedContainers.map((container) => ({
    item: [selectedItem],
    value: {
      label: `${container?.name} ${container?.versionNumber ?? 'v' + container?.versionNumber}`,
      value: container.containerId,
      id: container.containerId,
    },
    effective: container,
  }));
}

/**
 * Transforms an array of deployed triggers into a standardized format.
 * @param {Array<CompositeTriggerInfoDto>} deployedTriggers - The array of deployed triggers to be transformed.
 * @returns {Array} - An array containing the transformed triggers.
 */
export function transformTriggers(deployedTriggers) {
  if (!deployedTriggers?.length) return [];

  const selectedItem = FIRST_LEVEL_ITEMS?.find(
    (item) => item.value === SCHEME_PARENT_ITEMS.TRIGGERS
  );

  return deployedTriggers.map((trigger) => ({
    item: [selectedItem],
    value: {
      label: trigger.triggerName,
      value: trigger.triggerId,
      id: trigger.triggerId,
    },
    effective: trigger,
  }));
}

/**
 * Transforms an array of deployed firmwares into a standardized format.
 * @param {Array<FirmwareDeployedInfo>} deployedFirmwares - The array of deployed firmwares to be transformed.
 * @returns {Array} - An array containing the transformed firmwares.
 */
export function transformFirmwares(deployedFirmwares) {
  if (!deployedFirmwares?.length) return [];

  const selectedItem = FIRST_LEVEL_ITEMS.find(
    (item) => item.value === SCHEME_PARENT_ITEMS.FIRMWARES
  );

  return deployedFirmwares.map((firmware) => {
    const firmwareSubType = SCHEME_FIRMWARE_CATEGORIES.find((item) => item.value === firmware.type);
    return {
      item: [selectedItem, firmwareSubType],
      value: {
        label: `${firmware.name} v${firmware.version}`,
        value: firmware.firmwareId,
        id: firmware.firmwareId,
        ...firmware,
      },
      effective: firmware,
    };
  });
}

/**
 * Finds an item in an object by a specified key.
 * @param {object} obj - The object to search for the key.
 * @param {string} keyToFind - The key to find in the object.
 * @returns {object} - An object containing the parent key and the found item, or null if not found.
 */
function findItemsByKey(obj, keyToFind) {
  for (const [parentKey, items] of Object.entries(obj)) {
    if (Array.isArray(items)) {
      for (const item of items) {
        if (item.value === keyToFind) {
          return { parentKey, item };
        }
      }
    }
  }
  return null;
}

/**
 * Transforms group settings into a standardized format.
 * NEED REAFCTOR
 * @param {object} groupSettings - The group settings to be transformed.
 * @param {Array} inputs - The group settings to be transformed.
 * @returns {Array} - An array containing the transformed settings.
 */
export function transformSettings(groupSettings, inputs) {
  const ignoredKeys = [
    'imagerState', // processing another way below
    'videoPipeline', // processing another way below
    'audioPipeline', // processing another way below
    'wifiNetworks',
    'highways',
    'loggedObd2',
    'lastUpdatedTime',
  ];

  const defaultSettings = groupSettingsByCategory();

  if (isEmpty(groupSettings)) return [];

  const selectedItem = FIRST_LEVEL_ITEMS.find(
    (item) => item.value === SCHEME_PARENT_ITEMS.SETTINGS
  );

  const transformedSettings = [];

  groupSettings.forEach((element) => {
    const { key, value } = element;

    const result = findItemsByKey(defaultSettings, key);

    const subType = SCHEME_SETTINGS_SUBCATEGORIES?.find(
      (item) => item?.value === result?.parentKey
    );

    if (key === 'imagerState') {
      if (!value) return;
      const imagers = JSON?.parse(value);
      const secondItem = SCHEME_SETTINGS_SUBCATEGORIES?.find(
        (item) => item.value === GROUP_SETTINGS_CATEGORIES.DEVICE
      );
      imagers?.forEach((elem) => {
        const label = (inputs || [])?.find((i) => i.inputCode === elem.inputCode)?.inputName;
        const thirdItem = {
          label: trimSpaceFromString(label),
          value: elem.inputCode,
          inputType: elem.inputType,
          customEnable: true,
          isEnable: elem.isEnable,
        };
        const fourthItem = IMAGER_STATE_SETTINGS;
        const transformedData = {
          item: [selectedItem, secondItem, thirdItem, fourthItem],
          value: BOOLEAN_OPTIONS.find((i) => i.value === elem.isEnable),
          effective: element,
        };
        transformedSettings.push(transformedData);
      });
    } else if (['videoPipeline', 'audioPipeline'].includes(key)) {
      if (!value) return;
      const pipelineSettings = JSON.parse(value);

      pipelineSettings.forEach((elem) => {
        const secondItem = SCHEME_SETTINGS_SUBCATEGORIES.find(
          (item) => item.value === elem.pipeline
        );
        const input = inputs.find((i) => i.inputCode === elem.src);

        if (!input) return;

        const thirdItem = {
          label: trimSpaceFromString(input?.inputName),
          value: input?.inputCode,
          inputType: input?.inputType,
          customEnable: true,
        };

        for (const [key, value] of Object.entries(elem)) {
          if (['src', 'pipeline'].includes(key)) continue;
          const pipelineDefaultSettings = (
            input?.inputType === 'VIDEO' ? VIDEO_PIPELINE_SETTINGS : AUDIO_PIPELINE_SETTINGS
          ).map((i) => ({
            ...i,
            value: i.key,
          }));
          const fourthItem = pipelineDefaultSettings.find((i) => i.key === key);
          const itemVal = fourthItem?.options?.find((i) => i?.value === value) || value;
          const transformedData = {
            item: [selectedItem, secondItem, thirdItem, fourthItem],
            value: itemVal,
            effective: element,
          };
          transformedSettings.push(transformedData);
        }
      });
    } else {
      if (ignoredKeys.includes(key) || !result?.parentKey || !subType) return;

      if (['usbFileTransfer'].includes(key)) {
        result.item.options = BOOLEAN_ON_OFF;
      }

      if (['parkingMode'].includes(key)) {
        result.item.options = PARKING_MODE_ON_OFF;
      }

      if (['recordingResolution', 'streamingResolution'].includes(key)) {
        result.item.options = VIDEO_RECORDING_DEFAULT_RESOLUTIONS;
      }

      const itemVal = !isUndefined(result?.item?.options)
        ? result?.item?.options?.find((i) => i?.value === value)
        : value;

      if (isUndefined(itemVal)) return;

      const transformedData = {
        item: [selectedItem, subType, result.item],
        value: itemVal,
        effective: element,
      };

      transformedSettings.push(transformedData);
    }
  });

  return transformedSettings;
}
