import { formatTimestamp } from '@/utils/datetime';
import InfoIcon from '@mui/icons-material/Info';
import { Box, Button, Divider, Typography } from '@mui/material';
import { useState } from 'react';
import { ResetDefaultModal } from '../ResetSettings';

export function SettingsActionButtons(props) {
  const {
    isEditing,
    lastUpdateTime,
    setIsEditing,
    onCancelEdit,
    onHandleReset,
    handleSettingsSubmit,
  } = props;

  const [openResetModal, setOpenResetModal] = useState(false);

  const onCompleteHandle = () => {
    setOpenResetModal(false);
    onHandleReset(true);
  };

  const onHideModal = () => {
    setOpenResetModal(false);
  };

  return (
    <Box mb={1}>
      {openResetModal && <ResetDefaultModal onHide={onHideModal} onComplete={onCompleteHandle} />}
      <Box sx={{ position: 'sticky', top: 0, zIndex: 1, background: 'white' }}>
        <Box
          px={3}
          py={1}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          flexWrap="wrap"
          rowGap={2}
          columnGap={2}
        >
          <Box gap="20px" display="flex" flexWrap="wrap" alignItems="center">
            <Typography variant="body2" fontSize="0.875rem">
              Last Updated : {lastUpdateTime && formatTimestamp(lastUpdateTime * 1000)}
            </Typography>
            {isEditing && (
              <Typography variant="body2" fontSize="0.875rem">
                <InfoIcon sx={{ color: '#608AC3' }} /> Update the settings page after edits
              </Typography>
            )}
          </Box>

          <Box gap="10px" display="flex" flexWrap="wrap">
            {isEditing ? (
              <>
                <Button onClick={onCancelEdit}>Cancel</Button>
                <Button variant="contained" onClick={handleSettingsSubmit}>
                  Update
                </Button>
              </>
            ) : (
              <>
                <Button
                  color="error"
                  onClick={(e) => {
                    e.preventDefault();
                    setOpenResetModal(true);
                  }}
                >
                  Restore Default Settings
                </Button>
                <Button
                  variant="contained"
                  onClick={(e) => {
                    e.preventDefault();
                    setIsEditing(true);
                  }}
                >
                  Edit
                </Button>
              </>
            )}
          </Box>
        </Box>
        <Divider />
      </Box>
    </Box>
  );
}
