import { MAP_ICON, USER_FALLBACK_ICON } from '@/assets/constants/images';
import { BoxImage } from '@/web/@components/BoxImage';
import { LongTextShortener } from '@/web/@components/LongTextShortener';
import { TableCheckboxHeader } from '@/web/@components/TableCheckboxHeader';
import { TableCheckboxItem } from '@/web/@components/TableItemCheckbox';
import { Box } from '@mui/material';
import { startCase } from 'lodash';

/** @type {Array<TableColumn<UserWithAccountDetails>>} */
export const USER_TABLE_COLUMNS = [
  {
    id: 'userId',
    label: '',
    align: 'left',
    width: 50,
    isPrimary: true,
    headerFormat: (value, rows) => <TableCheckboxHeader rows={rows} itemKey={'userId'} />,
    format: (value, row) => <TableCheckboxItem row={row} disabled={!row.active} />,
  },
  {
    id: 'imageUrl',
    label: '',
    align: 'left',
    isPrimary: false,
    format: (value) => (
      <BoxImage
        src={value || USER_FALLBACK_ICON}
        size="30px"
        borderRadius="50%"
        crossOrigin="anonymous"
        referrerPolicy="no-referrer"
      />
    ),
    width: '1%',
  },
  {
    id: 'name',
    label: 'Name',
    align: 'left',
    format: (value) => <LongTextShortener text={value} length={45} />,
    link: (row) => `/administration/users/${row.userId}`,
  },
  {
    id: 'email',
    label: 'Email',
    align: 'left',
    isPrimary: true,
    link: (row) => `/administration/users/${row.userId}`,
  },
  {
    id: 'role',
    label: 'Role',
    align: 'left',
    format: (value) => (value ? startCase(value.toLowerCase()) : '...'),
  },
  {
    id: 'country',
    label: 'Country',
    align: 'left',
    format: (value) =>
      value ? (
        <Box display={'inline-flex'} gap={1}>
          <BoxImage src={MAP_ICON} size={25} alt={value} fallback={<></>}></BoxImage>
          {value}
        </Box>
      ) : (
        '...'
      ),
  },
  {
    id: 'active',
    label: 'Status',
    align: 'left',
    format: (value) => (value ? 'Active' : 'Inactive'),
  },
];
