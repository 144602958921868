import {
  Aborter,
  AnonymousCredential,
  DirectoryURL,
  FileURL,
  StorageURL,
  uploadBrowserDataToAzureFile,
} from '@azure/storage-file';

/**
 * Upload file to azure
 * @param {File} file
 * @param {string} storedFileName
 * @param {string} url
 * @param {(percent: number) => any} [onProgress]
 * @param {Aborter} [aborter]
 */

export async function uploadFile(file, storedFileName, url, onProgress, aborter = Aborter.none) {
  if (aborter.aborted) return;
  const directoryURL = new DirectoryURL(url, StorageURL.newPipeline(new AnonymousCredential()));
  const fileURL = FileURL.fromDirectoryURL(directoryURL, storedFileName);
  await fileURL.create(aborter, file.size);
  await uploadBrowserDataToAzureFile(aborter, file, fileURL, {
    rangeSize: 4 * 1024 * 1024, // 4MB range size
    parallelism: 20, // concurrency
    progress: (ev) => {
      const percent = (100 * ev.loadedBytes) / file.size;
      onProgress && onProgress(percent);
    },
  });
  return fileURL.url;
}
