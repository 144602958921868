import { Typography } from '@mui/material';
import { truncate } from 'lodash';

/**
 * @typedef {object} LongTextShortenerProps
 * @property {string} text
 * @property {number} [length]
 * @property {import('@mui/material').SxProps} [sx]
 */

/**
 * @param {LongTextShortenerProps} props
 */

export function LongTextShortener(props) {
  const { text, length, sx } = props;

  return (
    <Typography title={text} sx={{ ...sx }}>
      {truncate(text, { length: length || 45 })}
    </Typography>
  );
}
