import { GO_DEVICE_OFF, GO_DEVICE_ON } from '@/assets/constants/images';
import { BoxImage } from '@/web/@components/BoxImage';
import { CameraDetailsContext } from '@/web/@layouts/CameraDetailsLayout';
import { Box, IconButton, Popover, Typography } from '@mui/material';
import { useContext, useMemo, useState } from 'react';

const GO_DEVICE_TEXT = {
  CONNECTED: 'GO Device Connected',
  DISCONNECTED: 'GO Device Connection Failed',
};

const NETWORK_COLORS = {
  BACKGROUND_INACTIVE: '#F8FAFF',
  BACKGROUND_ACTIVE: '#487CC1',
  BACKGROUND_ACTIVE_HOVER_ACTIVE: '#487DD1',
  ITEM_INACTIVE: '#C8CFE5',
  ITEM_ACTIVE: '#FFFFFF',
};

export function GoDeviceInfo() {
  const { camera } = useContext(CameraDetailsContext);

  const isGoDevice = useMemo(
    () => camera?.pluggedDevice !== null && camera?.pluggedDevice !== '<NONE>',
    [camera]
  );

  const [anchorEl, setAnchorEl] = useState(null);
  const [openedPopoverId, setOpenedPopoverId] = useState(false);

  const handlePopoverClose = () => {
    setAnchorEl(null);
    setOpenedPopoverId(false);
  };

  if (!isGoDevice) return null;

  return (
    <>
      <IconButton
        disableRipple
        sx={{
          'backgroundColor': isGoDevice
            ? NETWORK_COLORS.BACKGROUND_ACTIVE
            : NETWORK_COLORS.BACKGROUND_INACTIVE,
          'height': '40px',
          'width': '40px',
          ':hover': {
            'backgroundColor': isGoDevice
              ? NETWORK_COLORS.BACKGROUND_ACTIVE_HOVER_ACTIVE
              : NETWORK_COLORS.BACKGROUND_INACTIVE,
            '& .MuiSvgIcon-root': {
              color: NETWORK_COLORS.ITEM_INACTIVE,
            },
          },
        }}
        onMouseEnter={(e) => {
          setAnchorEl(e.currentTarget);
          setOpenedPopoverId(true);
        }}
        onMouseLeave={handlePopoverClose}
      >
        <BoxImage src={isGoDevice ? GO_DEVICE_ON : GO_DEVICE_OFF} height="16px" width="17px" />
      </IconButton>

      <Popover
        sx={{
          'pointerEvents': 'none',
          'boxShadow': 'none',
          '& .MuiPopover-paper': {
            boxShadow: '1px',
          },
        }}
        open={openedPopoverId}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Box display="row" p={1}>
          <Typography variant="subtitle1" fontWeight={500} fontSize="12px" pb={0.5}>
            {isGoDevice ? GO_DEVICE_TEXT.CONNECTED : GO_DEVICE_TEXT.DISCONNECTED}
          </Typography>

          <Typography variant="subtitle2" fontWeight={500} fontSize="12px">
            Serial No.
          </Typography>
          <Typography variant="subtitle1" fontWeight={500} fontSize="12px">
            {camera?.pluggedDevice}
          </Typography>
        </Box>
      </Popover>
    </>
  );
}
