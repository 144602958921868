import { Box, Button, TextField } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { ScoringFilterContext } from '../../@context/ScoringFilterContext';
import { formatDate1 } from '../../utils';

/**
 * @typedef {object} ScoreDateRangePickerProps
 * @property {() => any} [onApply]
 * @property {() => any} [onCancel]
 */

/**
 * @param {ScoreDateRangePickerProps} props
 */
export function ScoreDateRangePicker(props) {
  const { onApply, onCancel } = props;

  const { selectedFromDate, setSelectedFromDate, selectedToDate, setSelectedToDate } =
    useContext(ScoringFilterContext);

  /** @type {StateVariable<string>} */
  const [tempFromState, setTempFormState] = useState(null);
  /** @type {StateVariable<string>} */
  const [tempToState, setTempToState] = useState(null);

  // eslint-disable-next-line no-unused-vars
  const customDateSelection = (date) => {
    const currentDate = new Date();
    setTempToState(formatDate1(currentDate));
    const sevenDaysAgo = new Date(currentDate);
    sevenDaysAgo.setDate(currentDate.getDate() - date + 1);
    setTempFormState(formatDate1(sevenDaysAgo));
  };

  const onClickApply = () => {
    setSelectedFromDate(tempFromState);
    setSelectedToDate(tempToState);
    onApply();
  };

  const onClickCancel = () => {
    setTempFormState(selectedFromDate);
    setTempToState(selectedToDate);
    onCancel();
  };

  useEffect(() => {
    setTempFormState(selectedFromDate);
    setTempToState(selectedToDate);
  }, [selectedFromDate, selectedToDate]);

  return (
    <Box
      px={2}
      pb={2}
      sx={{ maxHeight: '300px', minWidth: '400px', overflowY: 'auto', marginTop: '2px' }}
    >
      <>
        <Box display={'flex'} flexDirection={'column'} gap={2} mt={1}>
          <>
            <TextField
              fullWidth
              type="date"
              size="small"
              placeholder="Input a text"
              value={tempFromState}
              inputProps={{ max: new Date(tempToState)?.toISOString()?.split('T')?.at(0) }}
              onChange={(event) => {
                setTempFormState(event.target.value);
              }}
            />

            <TextField
              fullWidth
              size="small"
              type="date"
              placeholder="Input a text"
              value={tempToState}
              inputProps={{ min: new Date(tempFromState)?.toISOString()?.split('T')?.at(0) }}
              onChange={(event) => {
                setTempToState(event?.target?.value);
              }}
            />
          </>
        </Box>
        <Box
          sx={{ p: 1, pt: 2, display: 'flex', justifyContent: 'flex-end' }}
          onClick={onClickCancel}
        >
          <Button size="small">Cancel</Button>
          <Button onClick={() => onClickApply()} variant="contained" size="small">
            Apply
          </Button>
        </Box>
      </>
    </Box>
  );
}
