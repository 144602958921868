import { store } from '@/store';
import { selectTeamList, selectTenantId, selectUserEmail, selectUserRole } from '@/store/auth';
import { PageView, selectSidebarMode } from '@/store/page-view';
import { isExactMatch, isPartialMatch } from '@/utils/routing';
import { filterByUserAccess } from '@/utils/user-access';
import { BoxImage } from '@/web/@components/BoxImage';
import { PageLink } from '@/web/@components/PageLink';
import {
  ArrowDropDown as ArrowDropDownIcon,
  ArrowRight as ArrowRightIcon,
} from '@mui/icons-material';
import { Box, Collapse, IconButton, useTheme } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { SidebarTree } from './SidebarTree';

/** @param {{item: SidebarItem, level: number}} props */
export function SidebarTreeItem(props) {
  const { item, level } = props;

  const theme = useTheme();
  const location = useLocation();
  const { t } = useTranslation();
  const isCollapsed = useSelector(selectSidebarMode);
  const userRole = useSelector(selectUserRole);
  const userEmail = useSelector(selectUserEmail);
  const tenantId = useSelector(selectTenantId);
  const teamList = useSelector(selectTeamList);

  const [expanded, setExpanded] = useState(false);

  const visibleChildItems = useMemo(
    () => item.children?.filter(filterByUserAccess({ userRole, userEmail, tenantId, teamList })),
    [item.children, userRole, userEmail, tenantId, teamList]
  );

  const hasMore = useMemo(() => Boolean(visibleChildItems?.length), [visibleChildItems]);

  const isActive = useMemo(
    () =>
      item.exact
        ? isExactMatch(item.link, location.pathname)
        : isPartialMatch(item.link, location.pathname),
    [location.pathname, item.exact, item.link]
  );

  useEffect(() => {
    setExpanded(isPartialMatch(item.link, location.pathname));
  }, [item.link, location.pathname]);

  const toggleSidebar = () => {
    store.dispatch(PageView.toggleSidebarVisibility());
  };

  return (
    <>
      <Box
        component={PageLink}
        to={item.link}
        underline="none"
        display="flex"
        alignItems="center"
        px={2}
        py={1}
        mt={0.5}
        pl={isCollapsed ? 1 : level * 1.75}
        bgcolor={isActive ? theme.palette.action.selected : null}
        onClick={!item?.children ? toggleSidebar : null}
      >
        <IconButton
          size="small"
          onClick={hasMore ? () => setExpanded(!expanded) : null}
          sx={{
            opacity: hasMore ? 1 : 0,
            height: '26px',
            width: '26px',
            ml: '4px',
            p: 0,
          }}
        >
          {expanded ? <ArrowDropDownIcon /> : <ArrowRightIcon />}
        </IconButton>
        <BoxImage
          src={isActive ? item.activeImage : item.image}
          display="block"
          size={item.imageSize ?? '24px'}
          alt={item.name}
          title={t(item.name)}
          mr={1}
        />
        {!isCollapsed && (
          <Box
            flexGrow={1}
            fontWeight={500}
            whiteSpace="nowrap"
            overflow="hidden"
            textOverflow="ellipsis"
            fontSize={`${0.875 - level * 0.025}rem`}
            color={isActive ? '#0B2547' : theme.palette.text.secondary}
          >
            {t(item.name)}
          </Box>
        )}
      </Box>

      {hasMore && (
        <Collapse in={expanded} collapsedSize={0}>
          <SidebarTree items={item.children} level={level + 1} />
        </Collapse>
      )}
    </>
  );
}
