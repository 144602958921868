import { getCountryNameByCode } from '@/utils/country-flags';
import { TableCheckboxHeader } from '@/web/@components/TableCheckboxHeader';
import { TableCheckboxItem } from '@/web/@components/TableItemCheckbox';

/** @type {Array<TableColumn<TenantInfo>>} */
export const TENANT_TABLE_COLUMNS = [
  {
    id: 'tenantId',
    label: '',
    align: 'left',
    isPrimary: true,
    width: '1%',
    searchable: true,
    headerFormat: (value, rows) => <TableCheckboxHeader rows={rows} itemKey={'tenantId'} />,
    format: (value, row) => <TableCheckboxItem row={row} />,
  },
  {
    id: 'tenantName',
    label: 'Name',
    align: 'left',
    width: '40%',
    isPrimary: true,
    searchable: true,
  },
  {
    id: 'tenantRegion',
    label: 'Region',
    align: 'left',
    width: '20%',
    searchable: true,
    format: (value) => {
      return getCountryNameByCode(value?.toUpperCase());
    },
  },
];
