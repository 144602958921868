import { Outlet } from 'react-router-dom';
import { CoachingEndpointDetailsContextProvider } from '../@context/DriverDetailsContext';

export function CoachingEndpointPage() {
  return (
    <CoachingEndpointDetailsContextProvider>
      <Outlet />
    </CoachingEndpointDetailsContextProvider>
  );
}
