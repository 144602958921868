import { formatFirmwareName } from '@/utils/firmwares';
import { LongTextShortener } from '@/web/@components/LongTextShortener';
import { TableCheckboxHeader } from '@/web/@components/TableCheckboxHeader';
import { TableCheckboxItem } from '@/web/@components/TableItemCheckbox';

/** @type {Array<TableColumn<FirmwareDto>>} */
export const FIRMWARE_TABLE_COLUMNS = [
  {
    id: 'firmwareId',
    label: '',
    align: 'left',
    width: 50,
    isPrimary: true,
    headerFormat: (value, rows) => <TableCheckboxHeader rows={rows} itemKey={'firmwareId'} />,
    format: (value, row) => <TableCheckboxItem row={row} />,
  },
  {
    id: 'name',
    label: 'Name',
    align: 'left',
    isPrimary: true,
    searchable: true,
    format: (value) => <LongTextShortener text={value} length={45} />,
  },
  {
    id: 'version',
    label: 'Version',
    align: 'left',
    searchable: true,
    format: (value) => <LongTextShortener text={value} length={25} />,
  },
  {
    id: 'type',
    label: 'Type',
    align: 'left',
    searchable: true,
    format: (value) => formatFirmwareName(value),
  },
];
