// @ts-nocheck
import { FROM_TYPES } from '@/assets/schemes/constants';
import { DEFAULT_GROUP_SETTINGS } from '@/assets/schemes/defaultValues';
import { CustomInput, CustomSelect } from '@/web/@components/CustomForm';
import { SchemeFormContext } from '@/web/@layouts/SchemeLayout';
import { Box, Grid, InputAdornment, Typography } from '@mui/material';
import { isFunction } from 'lodash';
import { memo, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import {
  AUDIO_PIPELINE_SETTINGS,
  BOOLEAN_ON_OFF,
  BOOLEAN_OPTIONS,
  FIRST_LEVEL_ITEMS,
  IMAGER_STATE_SETTINGS,
  PARKING_MODE_ON_OFF,
  SCHEME_FIRMWARE_CATEGORIES,
  SCHEME_PARENT_ITEMS,
  SCHEME_SETTINGS_SUBCATEGORIES,
  VIDEO_PIPELINE_SETTINGS,
  typeMapping,
} from '../../assets';
import { uniqueArrayByKey } from '../../utils';
import { getInputSelections } from '../../utils/settings';
import { SchemeFormItemContext } from '../SchemeForm';
import { DateTimePickerForm } from '../TimeSelection';

/** @param {{item: object, fieldIndex: number, isReadOnly:boolean}} props */
function SchemePropertyFormControl(props) {
  const { item, fieldIndex, isReadOnly } = props;

  const { control, register, setValue } = useFormContext();

  const { fields } = useFieldArray({ control, name: 'properties' });

  const { settings, productInputs } = useContext(SchemeFormItemContext);
  const { aiContainers, firmwares, triggers } = useContext(SchemeFormContext);

  const [selectionKey] = useState('value');
  const [selectionLabel] = useState('label');
  const [itemType, setItemType] = useState(item?.type);
  const [lastItem, setLastItem] = useState(null);

  const valueKey = `properties[${fieldIndex}].value`;
  const labelsKey = `properties[${fieldIndex}].item`;

  const commonProps = {
    disabled: Boolean(item.disabled || isReadOnly),
    name: `properties[${fieldIndex}].${item.name}`,
    placeholder: item.placeholder,
    rules: { required: item?.mandatory ? 'This is required' : false },
  };

  const selectedValue = useWatch({ control, name: valueKey });
  const selectedLabels = useWatch({ control, name: labelsKey });

  const lastSelectedLabels = useMemo(
    () => selectedLabels?.at(selectedLabels?.length - 1),
    [selectedLabels]
  );

  /****************************************
   * SELECT BOX OPTIONS
   ****************************************/
  const getSettingsLabelOptions = useCallback(() => {
    const selectedLabelsLength = selectedLabels?.length || 0;
    const secondProperty = selectedLabels?.at(1)?.value;
    const thirdProperty = selectedLabels?.at(2)?.value;

    const options =
      thirdProperty && selectedLabelsLength === 3
        ? []
        : secondProperty && selectedLabelsLength === 2
          ? settings[secondProperty]
          : selectedLabelsLength <= 2
            ? SCHEME_SETTINGS_SUBCATEGORIES
            : [];

    if (['device_settings', 'streaming', 'recording'].includes(secondProperty) && !thirdProperty) {
      options.push(...getInputSelections(productInputs));
    }

    const customEnable = selectedLabels?.at(selectedLabelsLength - 1).customEnable || false;

    if (['streaming', 'recording'].includes(secondProperty) && thirdProperty && customEnable) {
      const isVideo = selectedLabels?.at(2)?.inputType === 'VIDEO';
      const items = (isVideo ? VIDEO_PIPELINE_SETTINGS : AUDIO_PIPELINE_SETTINGS).map((i) => ({
        ...i,
        value: i.key,
      }));
      options.push(...items);
    }

    if (secondProperty === 'device_settings' && thirdProperty && customEnable) {
      options.push(IMAGER_STATE_SETTINGS);
    }

    const items = uniqueArrayByKey(options, 'value');

    const selectedItems = fields
      ?.filter((i) => {
        return i?.item?.at(0)?.value === 'SETTINGS' && thirdProperty
          ? i?.item?.at(1)?.value === secondProperty && i?.item?.at(2)?.value === thirdProperty
          : i?.item?.at(1)?.value === secondProperty;
      })
      ?.map((i) => i?.item);

    const selectedValues = selectedItems?.map((item) => item?.at(item.length - 1)?.value);
    const availableItems = items?.filter((item) => !selectedValues?.includes(item?.value));
    return availableItems;
  }, [productInputs, selectedLabels, settings, fields]);

  // Label selection
  const labelSelectionOptions = useCallback(() => {
    const selectedLabelsLength = selectedLabels?.length || 0;
    const firstProperty = selectedLabels?.at(0)?.value ?? null;
    if (firstProperty === SCHEME_PARENT_ITEMS.SETTINGS) {
      return getSettingsLabelOptions();
    }
    if (firstProperty === SCHEME_PARENT_ITEMS.FIRMWARES && selectedLabelsLength === 1) {
      return SCHEME_FIRMWARE_CATEGORIES;
    }
    return selectedLabelsLength === 0 ? FIRST_LEVEL_ITEMS : [];
  }, [selectedLabels, getSettingsLabelOptions]);

  const getSettingsOptions = useCallback(() => {
    const lastItem = selectedLabels?.at(selectedLabels.length - 1);
    if (lastItem) {
      if (lastItem.type === FROM_TYPES.SWITCH) {
        if (lastItem?.value === 'parkingMode') {
          return PARKING_MODE_ON_OFF;
        } else if (lastItem?.value === 'usbFileTransfer') {
          return BOOLEAN_ON_OFF;
        } else {
          return BOOLEAN_OPTIONS;
        }
      } else if (isFunction(lastItem.options)) {
        return lastItem.originalOptions;
      } else {
        return lastItem.options || [];
      }
    }
    return [];
  }, [selectedLabels]);

  // Value selection
  const valueSelectionOptions = useCallback(() => {
    const firstProperty = selectedLabels?.at(0)?.value ?? null;
    switch (firstProperty) {
      case SCHEME_PARENT_ITEMS.AI_CONTAINERS:
        return aiContainers;
      case SCHEME_PARENT_ITEMS.FIRMWARES:
        const secondProperty = selectedLabels?.at(1)?.value ?? null;
        return secondProperty ? firmwares.filter((item) => item.type === secondProperty) : [];
      case SCHEME_PARENT_ITEMS.TRIGGERS:
        return triggers;
      case SCHEME_PARENT_ITEMS.SETTINGS:
        return getSettingsOptions();
      default:
        return [];
    }
  }, [aiContainers, selectedLabels, firmwares, triggers, getSettingsOptions]);

  const itemOptions = useMemo(() => {
    if (item.name === 'item') {
      return labelSelectionOptions();
    }
    return valueSelectionOptions();
  }, [item, labelSelectionOptions, valueSelectionOptions]);

  /****************************************
   * Default Value and On Dirty Update
   ****************************************/
  const handleSettingsDefaultValues = useCallback(() => {
    const lastSelectedLabel = selectedLabels?.at(selectedLabels?.length - 1);
    const defaultVal = DEFAULT_GROUP_SETTINGS[lastSelectedLabel?.value];

    if (defaultVal && lastSelectedLabel) {
      const setValueBasedOnType = (options) => {
        const selectedOption = options?.find((item) => item.value === defaultVal);
        if (selectedOption) {
          setValue(valueKey, selectedOption);
        } else {
          setValue(valueKey, options?.at(0));
        }
      };

      if (lastSelectedLabel.type === FROM_TYPES.SELECT) {
        setValueBasedOnType(itemOptions);
        return;
      }

      if (lastSelectedLabel.type === FROM_TYPES.SWITCH) {
        switch (lastSelectedLabel.value) {
          case 'parkingMode':
            setValueBasedOnType(PARKING_MODE_ON_OFF);
            break;
          case 'usbFileTransfer':
            setValueBasedOnType(BOOLEAN_ON_OFF);
            break;
          default:
            setValueBasedOnType(BOOLEAN_OPTIONS);
            break;
        }
        return;
      }
      setValue(valueKey, defaultVal);
    }
  }, [setValue, selectedLabels, itemOptions, valueKey]);

  useEffect(() => {
    const firstItem = selectedLabels?.at(0);
    //Remove the data if label is dirty
    const { value: firstItemValue } = firstItem || {};
    if (selectedValue && firstItemValue) {
      let maxSelectedLabels;
      switch (firstItemValue) {
        case SCHEME_PARENT_ITEMS.SETTINGS:
          maxSelectedLabels = 3;
          break;
        case SCHEME_PARENT_ITEMS.FIRMWARES:
          maxSelectedLabels = 2;
          break;
        case SCHEME_PARENT_ITEMS.AI_CONTAINERS:
        case SCHEME_PARENT_ITEMS.TRIGGERS:
          maxSelectedLabels = 1;
          break;
        default:
          maxSelectedLabels = 0;
          break;
      }
      if (selectedLabels.length < maxSelectedLabels) {
        setValue(valueKey, '');
      }
    }

    const isShowDefaultSettings =
      firstItem?.value === SCHEME_PARENT_ITEMS.SETTINGS &&
      !selectedValue &&
      selectedLabels?.length === 3;
    //Settings default Values set
    if (isShowDefaultSettings) {
      handleSettingsDefaultValues();
    }
  }, [selectedValue, selectedLabels, itemOptions, valueKey, setValue, handleSettingsDefaultValues]);

  /****************************************
   * END OF SELECT BOX OPTIONS
   ****************************************/

  const autocompleteProps = useMemo(() => {
    return item.multiple
      ? {
          multiple: true,
          renderTags: (value) => (
            <Box
              sx={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {value
                .map((x) => x?.label)
                .filter(Boolean)
                .join('.')}
            </Box>
          ),
          open: itemOptions?.length > 0 ? true : false,
        }
      : null;
  }, [item, itemOptions]);

  useEffect(() => {
    if (item.name === 'item') return;
    const lastItem = selectedLabels?.[selectedLabels?.length - 1];
    const itemType = (lastItem?.type && typeMapping[lastItem?.type]) || FROM_TYPES.SELECT;
    setItemType(itemType);
    setLastItem(lastItem);
  }, [item, selectedLabels]);

  if (item.visibility && !item.visibility(selectedValue)) {
    return null;
  }

  const renderMultiSelectBox = () => (
    <Grid item xs={12} md={6} lg={6}>
      <CustomSelect
        {...commonProps}
        options={itemOptions || []}
        getKey={selectionKey || 'value'}
        getLabel={selectionLabel || 'label'}
        AutocompleteProps={autocompleteProps}
        {...register(`properties[${fieldIndex}].${item.name}`)}
      />
    </Grid>
  );

  const renderSelectBox = () => (
    <Grid item xs={10} md={5} lg={4}>
      <Box sx={{ display: 'flex', width: '100%', gap: '10px', alignItems: 'center' }}>
        <CustomSelect
          {...commonProps}
          options={itemOptions}
          getKey={selectionKey || 'value'}
          getLabel={selectionLabel || 'label'}
        />
      </Box>
    </Grid>
  );

  const renderDateTimePicker = () => (
    <Grid item xs={10} md={5} lg={4}>
      <DateTimePickerForm
        item={lastItem}
        name={valueKey}
        isDisabled={Boolean(item.disabled || isReadOnly)}
      />
    </Grid>
  );

  const renderInputBox = () => (
    <Grid item xs={10} md={5} lg={4}>
      <Box sx={{ display: 'flex', width: '100%', gap: '10px', alignItems: 'center' }}>
        <CustomInput
          {...commonProps}
          label={item.label}
          type={itemType === 'number' ? 'number' : 'input'}
          InputProps={{
            ...(lastSelectedLabels?.postText && {
              endAdornment: (
                <InputAdornment position="end">
                  <Typography fontSize="14px">{lastSelectedLabels?.postText}</Typography>
                </InputAdornment>
              ),
            }),
          }}
        />
      </Box>
    </Grid>
  );

  return (
    <>
      {(() => {
        switch (itemType) {
          case FROM_TYPES.MULTISELECT:
            return renderMultiSelectBox();
          case FROM_TYPES.SELECT:
            return renderSelectBox();
          case FROM_TYPES.DATE_TIME:
            return renderDateTimePicker();
          case FROM_TYPES.INPUT:
          case FROM_TYPES.NUMBER:
            return renderInputBox();
          default:
            return null;
        }
      })()}
    </>
  );
}

export const MemoizedSchemePropertyFormControl = memo(SchemePropertyFormControl);
