import api from '@/api';
import { parseJwtToken } from '@/utils/crypto';
import { Auth, selectTenantList } from '..';

/**
 * @typedef {object} SelectTenantParams
 * @property {string} tenantId
 * @property {string} [token]
 * @property {string} [email]
 * @property {string[]} [support]
 */

/**
 * Attempt login using a tenant id
 * @param {SelectTenantParams} params
 * @returns {StoreAction<Promise>}
 */
export const selectTenant = (params) => async (dispatch, getState) => {
  let { tenantId, token, email, support } = params;
  if (!tenantId) return;

  let state = getState();
  if (support?.length > 1 && support[0] !== state.auth.tenantId) {
    await dispatch(selectTenant({ tenantId: support[0] }));
  }

  state = getState();
  email ||= state.auth.email;
  if (!token) {
    if (support?.length > 1 && state.auth.accessTokenExpiry - Date.now() > 5000) {
      token = state.auth.accessToken;
    } else if (state.auth.refreshTokenExpiry - Date.now() > 5000) {
      token = state.auth.refreshToken;
    } else if (state.auth.accountTokenExpiry - Date.now() > 5000) {
      token = state.auth.accountToken;
    }
  }
  if (!token) {
    return dispatch(Auth.logout());
  }
  const request = api.ac.v5.auth['tenant-access'].$post({
    data: {
      token,
      email,
      tenantId,
      asSupport: support?.length > 1,
    },
  });
  const result = await request.process();
  if (support?.length > 1) {
    dispatch(Auth.setSupportTenant({ result, support }));
  } else {
    dispatch(Auth.setTenant(result));
    dispatch(updateTenantTree()).catch(console.error);
  }
};

/**
 * @returns {StoreAction<Promise>}
 */
export const updateTenantTree = () => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.auth.tenantId;
  if (!['SUPPORT', 'SUPER_ADMIN'].includes(state.auth.role)) return;
  const request = api.ac.v5.tenant.descendants.$get({
    headers: {
      Authorization: state.auth.accessToken,
    },
  });
  const tree = await request.process();
  const tenantList = selectTenantList(state);
  const tenantName = tenantList.find((x) => x.tenantId === tenantId)?.tenantName;
  /** @type {DescendantTenant} */
  const root = {
    tenantId,
    tenantName,
    descendantTenantList: tree?.descendantTenantList,
  };
  dispatch(Auth.setTenantTree(root));
};

/**
 * Select GeoTab Account Tenanats
 * @param {*} params
 */
export const selectGeoTabTenant = (params) => async (dispatch, getState) => {
  let { tokenData } = params;
  const parsedData = parseJwtToken(tokenData?.accessToken);

  let userToken = tokenData?.accessToken;

  const state = getState();

  if (!userToken) {
    /** @type {Array<[string, number]>} */
    const tokensToCheck = [
      [state.auth.accessToken, state.auth.accessTokenExpiry],
      [state.auth.refreshToken, state.auth.refreshTokenExpiry],
      [state.auth.accountToken, state.auth.accountTokenExpiry],
    ];
    for (const [token, expiry] of tokensToCheck) {
      if (expiry - Date.now() > 5000) {
        userToken = token;
        break;
      }
    }
  }

  if (!userToken) {
    dispatch(Auth.logout());
    return;
  }

  const data = {
    isGeoTabLogin: true,
    accessToken: tokenData?.accessToken,
    accessTokenExpiry: parsedData?.exp * 1000,
    refreshToken: tokenData?.accessToken,
    refreshTokenExpiry: parsedData?.exp * 1000,
    userId: parsedData?.user_id,
    sessionId: parsedData?.session_id,
    tenantId: parsedData?.tenant_id,
  };
  dispatch(Auth.setTenant(data));
};
