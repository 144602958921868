import { FROM_TYPES, GROUP_SETTINGS_CATEGORIES } from '../constants';
import {
  deploymentPolicyOptions,
  dlProgressReportingIntervalOptions,
  eventPollingIntervalOptions,
  firmwareDownloadDurationOptions,
  networkToDownloadOptions,
  periodicCheckPolicyOptions,
  retryPolicyOptions,
  startPolicyOptions,
  triggerPolicyOptions,
} from '../options';

/** @type {Array<GroupSettingsItems>} */
export const rebootSettings = [
  {
    title: 'Firmware Update',
    key: GROUP_SETTINGS_CATEGORIES.REBOOT,
    children: [
      {
        label: 'Download',
        key: 'download',
        type: 'group',
        children: [
          {
            label: 'Download Start Policy',
            key: 'downloadStartPolicy',
            type: FROM_TYPES.SELECT,
            options: startPolicyOptions,
          },
          {},
          {
            label: 'Download Start Geo Fence',
            key: 'geoFenceDownloadStart',
            type: FROM_TYPES.GEO_FENCE,
            isHidden: (watch) => watch('downloadStartPolicy') !== 'GEO_FENCE',
          },
          {
            label: 'Download Start Schedule',
            key: 'downloadStartSchedule',
            type: FROM_TYPES.DATE_TIME,
            dependentKey: 'downloadStartPolicy',
            isHidden: (watch) => watch('downloadStartPolicy') !== 'SCHEDULED',
          },
          {
            label: 'Download Retry Policy',
            key: 'downloadRetryPolicy',
            type: FROM_TYPES.SELECT,
            options: retryPolicyOptions,
          },
          {
            label: 'Download Retry Schedule',
            key: 'downloadRetrySchedule',
            type: FROM_TYPES.DATE_TIME,
            dependentKey: 'downloadRetryPolicy',
            isHidden: (watch) => watch('downloadRetryPolicy') !== 'SCHEDULED',
          },
          {
            label: 'Max Retry On Download Failure',
            key: 'maxRetryOnDownloadFailure',
            type: FROM_TYPES.SLIDER,
            postText: 'times',
            min: 0,
            max: 10,
          },
          {
            label: 'Network To Download',
            key: 'networkToDownload',
            type: FROM_TYPES.SELECT,
            options: networkToDownloadOptions,
          },
          {
            label: 'Max Package Size For Metered Connections',
            key: 'maxPackageSizeForLTEDownload',
            type: FROM_TYPES.INPUT,
            postText: 'MB',
            min: 0,
            max: 4096,
            inputType: 'number',
            isHidden: (watch) => watch('networkToDownload') !== 'ANY',
          },
        ],
      },
      {
        label: 'Installation',
        key: 'installation',
        children: [
          {
            label: 'Installation Start Policy',
            key: 'installStartPolicy',
            type: FROM_TYPES.SELECT,
            options: startPolicyOptions,
          },
          {
            label: 'Installation Start Geo Fence',
            key: 'geoFenceInstallationStart',
            type: FROM_TYPES.GEO_FENCE,
            isHidden: (watch) => watch('installStartPolicy') !== 'GEO_FENCE',
          },
          {
            label: 'Installation Start Schedule',
            key: 'installationStartSchedule',
            type: FROM_TYPES.DATE_TIME,
            dependentKey: 'installStartPolicy',
            isHidden: (watch) => watch('installStartPolicy') !== 'SCHEDULED',
          },
          {
            label: 'Installation Retry Policy',
            key: 'installationRetryPolicy',
            type: FROM_TYPES.SELECT,
            options: retryPolicyOptions,
          },
          {
            label: 'Installation Retry Schedule',
            key: 'installationRetrySchedule',
            type: FROM_TYPES.DATE_TIME,
            dependentKey: 'installationRetryPolicy',
            isHidden: (watch) => watch('installationRetryPolicy') !== 'SCHEDULED',
          },
          {
            label: 'Max Retry On Installation Failure',
            key: 'maxRetryOnInstallationFailure',
            type: FROM_TYPES.SLIDER,
            postText: 'times',
            min: 0,
            max: 10,
          },
        ],
      },
      {
        label: 'Periodic',
        key: 'periodic',
        children: [
          {
            label: 'Periodic Check Policy',
            key: 'periodicCheckPolicy',
            type: FROM_TYPES.SELECT,
            options: periodicCheckPolicyOptions,
          },
          {
            label: 'Periodic Check Time',
            key: 'periodicCheckTime',
            type: FROM_TYPES.DATE_TIME,
            dependentKey: 'periodicCheckPolicy',
            isHidden: (watch) =>
              !['DAILY', 'WEEKLY', 'MONTHLY'].includes(watch('periodicCheckPolicy')),
          },
          {
            label: 'Periodic Check Interval', // divide with 60 when submit
            key: 'periodicCheckInterval',
            type: FROM_TYPES.SLIDER,
            postText: 'hour',
            min: 1, //60 , divide with 60
            max: 24, // 1440 , divide with 60
            isHidden: (watch) => watch('periodicCheckPolicy') !== 'INTERVAL',
          },
        ],
      },
      {
        label: 'OTA Trigger Policy',
        key: 'otaTriggerPolicy',
        children: [
          {
            label: 'OTA on Deployment',
            key: 'onDeploymentPolicy',
            type: FROM_TYPES.SELECT,
            options: deploymentPolicyOptions,
          },
          {
            label: 'OTA on User Action',
            key: 'onUserTriggerPolicy',
            type: FROM_TYPES.SELECT,
            options: triggerPolicyOptions,
          },
        ],
      },
      {
        label: 'Advanced Updater Settings',
        key: 'advancedUpdatedSettings',
        secondaryUnderline: true,
        children: [
          {
            label: 'Firmware Blacklisting',
            key: 'firmwareBlacklistingEnabled',
            type: FROM_TYPES.SWITCH,
          },
          {
            label: 'Event Polling Interval',
            key: 'inboxPollingInterval',
            type: FROM_TYPES.SELECT,
            options: eventPollingIntervalOptions,
          },
          {
            label: 'Maximum Firmware Download Duration',
            key: 'maxDownloadDuration',
            type: FROM_TYPES.SELECT,
            options: firmwareDownloadDurationOptions,
          },
          {
            label: 'Firmware Download Progress Reporting Interval Frequency',
            key: 'dlProgressReportingInterval',
            type: FROM_TYPES.SELECT,
            options: dlProgressReportingIntervalOptions,
          },
        ],
      },
    ],
  },
];
