import { CameraDetailsContext } from '@/web/@layouts/CameraDetailsLayout';
import { Settings as SettingsIcon, ViewInAr as ViewInArIcon } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { useContext, useMemo } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';

export function CameraDetailsToSettingsButton() {
  const location = useLocation();
  const { cameraId, camera } = useContext(CameraDetailsContext);

  const isManageCameraPage = useMemo(
    () => location.pathname.includes('device-groups'),
    [location.pathname]
  );

  return (
    <IconButton
      sx={{
        backgroundColor: '#F8FAFF',
        height: '40px',
        width: '40px',
      }}
      size="large"
      title={isManageCameraPage ? 'Camera Details' : 'Manage Camera'}
      {...{
        LinkComponent: RouterLink,
        to: isManageCameraPage
          ? `/cameras/${cameraId}`
          : `/administration/device-groups/${camera?.groupId}/cameras/${cameraId}`,
      }}
    >
      {isManageCameraPage ? (
        <ViewInArIcon htmlColor="#abb4c0" />
      ) : (
        <SettingsIcon htmlColor="#abb4c0" />
      )}
    </IconButton>
  );
}
